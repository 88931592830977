/**
 * Constants
 */

const reactPrefix = 'REACT_APP'
const projectPrefix = 'RS'

// Get ENV Variable
const getEnvVar = (varName: string, isNumeric = false) => {
	const projectKey = `${projectPrefix}_${varName}`
	const reactKey = `${reactPrefix}_${projectKey}`
	const value =
		process.env[reactKey] || process.env[projectKey] || (isNumeric ? '0' : '')
	return isNumeric ? Number(value) : value
}

/**
 * Get Variable Methods
 */

// Get String Variable
export const getStringVar = (varName: string) =>
	getEnvVar(varName, false) as string

// Get Numeric Variable
export const getNumericVar = (varName: string) =>
	getEnvVar(varName, true) as number

// Get Is Prod
export const getIsProd = () =>
	process.env.NODE_ENV == 'production' ||
	process.env.REACT_APP_NODE_ENV == 'production'

// Get Web Prot
export const getWebPort = () => {
	const isProd = getIsProd()
	return isProd ? getNumericVar('WEB_PROD_PORT') : getNumericVar('WEB_DEV_PORT')
}

// Get API Port
export const getApiPort = () => {
	const isProd = getIsProd()
	return isProd ? getNumericVar('API_PROD_PORT') : getNumericVar('API_DEV_PORT')
}

// Get Recurly Key
export const getRecurlyKey = () => getStringVar('RECURLY_KEY')

export const getRecurlyProdKey = () => getStringVar('RECURLY_PROD_KEY')

// Get Auth Cookie
export const getAuthCookie = () => getStringVar('AUTH_COOKIE')

// Get Public Directory
export const getPublicDirectory = () => getStringVar('PUBLIC_DIR')

// Get Require Recurly
export const getRequireRecurly = () =>
	getNumericVar('REQUIRE_RECURLY') ? true : false

/**
 * Variables
 */

// Is Production
export const IS_PROD = getIsProd()

// Production URL
export const BASE_PROD_URL = getStringVar('BASE_PROD_URL')

// Development URL
export const BASE_DEV_URL = getStringVar('BASE_DEV_URL')

// Check if local machine
export const LOCAL_MACHINE = getNumericVar('LOCAL_MACHINE') ? true : false

// Web Port
export const WEB_PORT = getWebPort()

// API Port
export const API_PORT = getApiPort()

// Recurly API Key
export const RECURLY_KEY = String(process.env.RECURLY_KEY || '')

// Recurly Public Key
export const RECURLY_PUBLIC_KEY = String(process.env.RECURLY_PUBKEY || '')

// Auth Cookie
export const AUTH_COOKIE = getAuthCookie()

// Public Directory
export const PUBLIC_DIR = getPublicDirectory()

// Show Chromecast
export const SHOW_CHROMECAST = getNumericVar('SHOW_CHROMECAST') ? true : false

// Auth Redirect
export const AUTH_REDIRECT = getNumericVar('AUTH_REDIRECT') ? true : false

// Change Account
export const CHANGE_ACCOUNT = getNumericVar('CHANGE_ACCOUNT') ? true : false

// Lock All
export const LOCK_ALL = getNumericVar('LOCK_ALL') ? true : false

// Update Date
export const UPDATE_DATE = getStringVar('UPDATE_DATE')

// Require Recurly
export const REQUIRE_RECURLY = getRequireRecurly()

// CSS Dev Mode
export const CSS_DEV_MODE = getNumericVar('CSS_DEV_MODE') ? true : false

// Has Sale
export const HAS_SALE = getNumericVar('HAS_SALE') ? true : false

//Has Offer for existing users
export const HAS_OFFER = getNumericVar('HAS_OFFER') ? true : false

// GOOGLE SIGNIN
export const GOOGLE_CLIENT_ID = String(process.env.GOOGLE_CLIENT_ID || '')

export const GOOGLE_CLIENT_SECRET = String(process.env.GOOGLE_CLIENT_SECRET || '')

export const GOOGLE_CLIENT_AUTH = String(process.env.GOOGLE_CLIENT_AUTH || '')

// APPLE SIGNIN
export const APPLE_CLIENT_ID = String(process.env.APPLE_CLIENT_ID || '')

export const APPLE_REDIRECT_URI = String(process.env.APPLE_REDIRECT_URI || '')

//IMPACT VALUES
export const CAMPAIGN_ID = getNumericVar('CAMPAIGN_ID')

export const IMPACT_TRIAL_ID = getNumericVar('IMPACT_TRIAL_ID')

export const IMPACT_RECURRING_ID = getNumericVar('IMPACT_RECURRING_ID')

export const IMPACT_APP_RECURRING_ID = getNumericVar('IMPACT_APP_RECURRING_ID')

export const IMPACT_CAMPAIGN_ID = getNumericVar('IMPACT_CAMPAIGN_ID')

export const IMPACT_URL = getStringVar('IMPACT_URL')

export const WS_URL = getStringVar('WS_URL')

export const RECAPTCHA_SITE_KEY = String(process.env.RECAPTCHA_SITE_KEY || '')

export const WEATHER_API_KEYS = String(process.env.WEATHER_API_KEYS || '')

export const API_URL = getStringVar('API_URL')

export const SHARE_REWARD_AMOUNT = getNumericVar('SHARE_REWARD_AMOUNT')

export const SHARE_REWARD_END = getStringVar('SHARE_REWARD_END')

export const COROS_URL = getStringVar('COROS_URL')

export const COROS_CLIENT_ID = String(process.env.COROS_CLIENT_ID || '')

