import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FC } from 'react'
import { RSButton1 } from '../buttons';

// Props
export type GenericModalProps = {
	show: boolean
	setShow: (x: boolean) => any
	title: string
	text: string
	subtext?: string
	button1Text?: string
	button1Action?: () => any
	button2Text?: string
	button2Action?: () => any
	button3Text?: string
	button3Action?: () => any
	centered?: boolean,
	backdrop?: "static" | boolean,
	keyboard?: boolean
	setEmail?: (email: string) => any
	email?: string
	type?: string
	onboarding?: boolean,
	b1Secondary?: boolean
	btnFW?: boolean
}

/**
 * GenericModal Component
 */

const GenericModal: FC<GenericModalProps> = ({
	show,
	setShow,
	title,
	text,
	onboarding,
	button1Text,
	button1Action,
	button2Text,
	button2Action,
	button3Text,
	button3Action,
	centered = true,
	backdrop = true,
	keyboard = true,
	setEmail,
	email,
	type,
	subtext,
	b1Secondary = false,
	btnFW
}) => {
	// Properties
	const hasButtons = button1Text || button2Text || button3Text ? true : false
	const buttonClass = type === "delete-account" ? 'rounded-pill color-primary-bg mt-2 button-delete' : type === "recover" ? 'bottom-button-modal' : 'rounded-pill color-primary-bg'

	// Handle Close
	const handleClose = () => setShow(false)

	// Render
	return (
		<Modal show={show} onHide={handleClose} backdrop={backdrop} centered={centered} keyboard={keyboard} backdropClassName='backdrop-mui' className='mr-3 ml-3'>
			<Modal.Header closeButton={type === "recover" ? true : false} className='bg-main'>
				{/* Title */}
				<h3 className='text-center w-100 mb-n2'>{title}</h3>
			</Modal.Header>

			{!onboarding ?
				<Modal.Body className='bg-main'>


					{/* Content */}
					<Row className="mb-0 text-center">
						<Col>
							<p>{text}</p>
							{type === "recover" ? (
								<p className='py-3'>{subtext}</p>
							) : undefined}
						</Col>
					</Row>
					{type === "download" ? (
						<Row className="mb-4 text-center">
							<Col>
								<span>File will be sent to:</span>
								<input className='email-input' placeholder='Email' type="text" value={email} onChange={(e) => setEmail ? setEmail(e.target.value) : undefined} />
							</Col>
						</Row>
					) : undefined}

					{/* Buttons */}
					{hasButtons && (
						<Row>
							<Col xs={12} className="text-center">
								{/* Button 1 */}
								{button1Text && (
									<>
										<span className={`d-inline${btnFW ? "" : "-block"} mx-2`}>
											<RSButton1
												secondary={b1Secondary}
												className={buttonClass}
												onClick={button1Action}
											>
												{button1Text}
											</RSButton1>
										</span>
									</>
								)}

								{/* Button 2 */}
								{button2Text && (
									<span className="d-inline-block mx-2">
										<RSButton1
											secondary
											className={buttonClass}
											onClick={button2Action}
										>
											{button2Text}
										</RSButton1>
									</span>
								)}

								{/* Button 3 */}
								{button3Text && (
									<span className="d-inline-block mx-2">
										<RSButton1
											className={buttonClass}
											onClick={button3Action}
										>
											{button3Text}
										</RSButton1>
									</span>
								)}
							</Col>
						</Row>
					)}
				</Modal.Body>
				: <Modal.Body className='bg-main'>

					<Row className="mb-0 text-center">
						<Col>
							<p>Kindly complete onboarding<br /> to get access to these files</p>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="text-center">
							<span className="d-inline-block px-4">
								<RSButton1
									className={buttonClass}
									onClick={handleClose}
								>
									<p style={{ margin: '0 8px 0' }}>Back</p>
								</RSButton1>
							</span>
						</Col>
					</Row>

				</Modal.Body>}
		</Modal >
	)
}

export default GenericModal
