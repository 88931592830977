/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from "react";

type WakeLockType = "screen";

interface WakeLockSentinel {
  release: () => Promise<void>;
  addEventListener: (type: "release", listener: () => void) => void;
}

const useWakeLock = (loading: boolean): void => {
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

  useEffect(() => {

    // Prevent memory leaks
    let isActive = true

    const requestWakeLock = async () => {

      // Use type assertion to tell TypeScript about wakeLock
      const nav = navigator as any;

      if ("wakeLock" in nav) {
        try {
          const lock: WakeLockSentinel = await nav.wakeLock.request("screen");
          if (isActive) {
            setWakeLock(lock);
            console.log("Wake Lock enabled");

            lock.addEventListener("release", () => {
              console.log("Wake Lock was released");
              setWakeLock(null);
            });
          }
        } catch (err) {
          console.error("Failed to acquire wake lock:", err);
        }
      } else {
        console.warn("Wake Lock API is not supported in this browser.");
      }
    };

    if (loading) {
      requestWakeLock();
    } else if (wakeLock) {
      wakeLock.release().then(() => {
        console.log("Wake Lock manually released");
        setWakeLock(null);
      });
    }

    return () => {
      isActive = false;
      if (wakeLock) {
        wakeLock.release().then(() => setWakeLock(null));
      }
    };
  }, [loading]);

  return;
};

export default useWakeLock;