import React, { FC } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { P2 } from "../text/RSTypography";

export type CustomAccordionProps = {
	title: string,
	children: any,
	reverse?: boolean
}

const CustomAccordion: FC<CustomAccordionProps> = ({title, children, reverse}) => {

	 return (
		<Box sx={{width: '100%', height: '100%', minHeight: '40px'}}>
			<Accordion
					key={title}
					sx={{ pb: 0, borderRadius: '0 !important', transform: reverse ? 'rotate(180deg)' : 'none' }}
					className='gray-dark-bg'
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className='white-text' />}
					aria-controls={`title${title}-content`}
					id={`title${title}-header`}
					sx={{ height: "40px !important", minHeight: "40px !important", transform: reverse ? 'rotate(180deg)' : 'none', '& .MuiAccordionSummary-content': { m: '0 !important' }}}
					className='white-text'
				>
					<P2 desc size={-1} className='white-text'>
							{title}
					</P2>
				</AccordionSummary>

				<AccordionDetails sx={{ padding: 2, transform: reverse ? 'rotate(180deg)' : 'none' }}>
						{children}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default CustomAccordion;
