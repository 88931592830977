/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import {RSButton1, RSButton2} from '../../components/buttons';
import { SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'

import { DAYS_OF_WEEK, DAYS_OF_WEEK_ABBV_SHORT }  from '../training-plan/build/DaysOptions';

export type LongRunDayProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const LongRunDay: FC<LongRunDayProps> = ({ data, handleUpdateProfile }) => {

	const daysOfWeek = DAYS_OF_WEEK
	const daysOfWeekABBV = DAYS_OF_WEEK_ABBV_SHORT

	const { run_day } = data
	const [runDay, setRunDay] = useState<any>(run_day || '');

	const dayList = ['Sunday', 'Saturday']

	const handleSave = async () => {
		handleUpdateProfile({ run_day: runDay })
	}

	useEffect(() => {
		setRunDay(run_day)
	}, [run_day])

	return (
		<Box>
			<SH1 size={1} sx={{width: '100%', mb: 2}}>What is your preferred long run day?</SH1>

			{/* old sunday or saturday */}
			{/* <Grid container xs={12} justifyContent='space-between'>
				{dayList.map((activity: any, ai: number) => {
					const isActive = activity === runDay
					return <Grid item container xs={5.6} key={'activity-' + ai} sx={{ minHeight: '100px', width: '100px' }} justifyContent='center' alignItems='center'>
						<RSButton2 secondary={!isActive} sx={{ height: '100%', flexDirection: 'column' }} onClick={() => setRunDay(activity)}>
							<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px' }}>{activity}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid> */}

			<Grid container xs={12} sx={{ pt: 1 }}>
				{daysOfWeek?.map((day: string, index: number) => {
					const slicedDay = daysOfWeekABBV[index]
					const isActive = day === runDay
					return <Grid container key={index + day + 'lr'} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
						<RSButton2 ghost={!isActive} onClick={() => setRunDay(day)} sx={{ position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%', color: '#010101', px: 0 }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px !important', color: '#010101' }}>{slicedDay}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid>

			<RSButton1 onClick={handleSave} disabled={!runDay || run_day === runDay} sx={{ mt: 3 }}>Save</RSButton1>
		</Box>
	)
}

export default LongRunDay;
