import React, { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import RSUpgrade from "./RSUpgrade";
import RSDrawer from "../layout/RSDrawer";
import RSIcon from "../icons/RSIcon";
import { H2, P1, P2 } from "../text/RSTypography";

export type RSUnlockProps = {
	userInfo: any
}

const RSUnlock: FC<RSUnlockProps> = ({userInfo}) => {
		const [openMore, setOpenMore] = useState<boolean>(false)
		const [imgLoading, setImgLoading] = useState(true)

		const increase = window.innerWidth > 400 ? true : false
		const title = 'Train smarter with a plan designed for you.'
		const desc = 'Upgrade to RunSmart Pro and take full control of your training, plus premium features that keep you on track and training smart.'
		const list = [
			'Strength, yoga, and stretching workouts seamlessly added to your running plan.',
      'Full library of runner-specific workouts and exercises designed by Physical Therapists.',
      'Access to run form training to improve your stride, save energy, and be more efficient.',
      'Access to nutrition program to dial in your fueling for training and life.'
		]

    return <>
    	<Button className='white-text' onClick={() => setOpenMore(true)} sx={{p: 0, width: '100%'}}>
				<Grid container xs={12} className='gray-dark-bg white-text' sx={{height: '50px'}} justifyContent='center' alignItems='center'>
					<i className="fa-light fa-arrow-up-from-square" style={{fontSize: increase ? '18px' : '16px'}}/>
					<P2 size={increase ? 0 : -1} sx={{px: increase ? 2 : 1}}>Unlock more with an upgrade to</P2>
					<img src='/images/icons/pro-icon-word.png' style={{height: increase ? '18px' : '16px', width: 'auto'}} />
				</Grid>
			</Button>

			<RSDrawer bottom popUpHeight='90%' popUpWidth='600px' open={openMore} onClose={()=>setOpenMore(false)}>
				<Grid container xs={12}>
					<RSUpgrade userInfo={userInfo} title={title} description={desc}/>
					<Grid container xs={12} sx={{p: 3}}>
						<Box sx={{width: '100%', aspectRatio: 1 / 1, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
							{imgLoading && <Box className='loading-days toRight' sx={{border: 'none !important', position: 'absolute', height: '100%', width: '100%', top: 0, left: 0}} />}
							<img src='/img-new/misc/pro_preview.png' onLoad={()=> setImgLoading(false)} style={{height: 'auto', width: '100%', maxWidth: '400px'}} />
						</Box>
						<H2 sx={{py: 2, width: '100%'}}>Also included with RunSmart Pro:</H2>
						{list.map((l: any, lIndex: number) => {
							return <Grid key={lIndex+'ytu-li'} container xs={12} sx={{ pl: 2, pb: '2px' }} justifyContent='flex-start' alignItems='flex-start'>
								<RSIcon type='circle' height={5} width={5} sx={{marginTop: '7px'}}/>
								<P1 card sx={{ pl: 1, width: 'calc(100% - 10px)' }}>
									{l}
								</P1>
							</Grid>
						})}
					</Grid>
				</Grid>
			</RSDrawer>
    </>
};

export default RSUnlock;
