/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box } from '@mui/material'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import React, { FC, useState } from 'react'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import useRNBridge from '../../../hooks/useRNBridge'
import { signOut } from '../../../modules/accountActions'
import { host } from '../../../modules/apiConsume'
import { RECURLY_PUBLIC_KEY } from '../../../modules/cliEnvValues'
import DowngradePlan from '../../../pages/account/cancel/DowngradePlan'
import UpgradePlan from '../../../pages/account/UpgradePlan'
import { RSButton1 } from '../../buttons'
import RSDrawer from '../../layout/RSDrawer'
import RSHeaderCont from '../../layout/RSHeaderCont'
import RSNavCont from '../../layout/RSNavCont'
import { P1, SH1, P3 } from '../../text/RSTypography'

interface PastDueProps {
    userInfo: any
}

const PastDue: FC<PastDueProps> = ({ userInfo }) => {

    const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false
    const { mobileType } = useDeviceDetect()
    const isMobile = useIsMobileScreen()
    const { sendDataToReactNative } = useRNBridge()
    const [openPaymentDetails, setOpenPaymentDetails] = useState(false)
    const [openDowngradePlan, setOpenDowngradePlan] = useState(false)

    const isFemale = userInfo?.gender === "F" ? true : false

    const handleOpenUrl = (url: string) => {
        if (isNativeApp && mobileType.toLowerCase() === "ios") {
            const dataToSend = {
                requestType: 'open-rs-shop',
                payload: { url: url },
                timestamp: new Date().getTime(),
            }
            sendDataToReactNative(dataToSend);
        } else
            window.open(url, '_blank')
    }

    const onSubmit = () => {
        if (isNativeApp) {
            handleOpenUrl(`${host}/#/?auto_login=true&user_id=${userInfo?.account_id}`)
        }
        else setOpenPaymentDetails(true)
    }

    return (
        <>
            <RSNavCont loaderProps={{ active: false, isBehindHeader: false }} hideHeader hideFooter contClass='background-bottom' contSX={{ backgroundImage: `url(${isFemale ? '/img-new/accounts/runner-checking-watch-female.png' : '/img-new/accounts/runner_checking_watch.png'})` }}>
                <RSHeaderCont logout={signOut} hasCenteredLogo isStatic={!isMobile} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px' }} />
                <Box sx={{ display: 'flex', flexDirection: "column", mx: 2, mb: 0, borderRadius: '4px', p: 2, textAlign: "center" }}>
                    <P3 sx={{ width: "100%", mt: 3 }} card size={4}>Your Account is Past Due</P3>

                    <P1 sx={{ width: "100%", mt: 3 }} desc size={1}>
                        {isNativeApp ? `Please take a minute to update your billing via the web to continue using RunSmart.` : `Please take a minute to update your billing information.`}
                    </P1>

                    <Box sx={{ width: "100%", mt: 4 }}>
                        <RSButton1 onClick={onSubmit} impose sx={{ width: "100%" }}>
                            Update Billing
                        </RSButton1>
                    </Box>
                    <Box sx={{ width: "100%", mt: 2 }}>
                        <RSButton1 tertiary onClick={() => setOpenDowngradePlan(true)} impose sx={{ width: "100%" }}>
                            Downgrade to free account.
                        </RSButton1>
                    </Box>
                </Box >
            </RSNavCont>

            <RSDrawer sx={{ maxWidth: isMobile ? "100%" : "400px" }} fitContent open={openDowngradePlan} noClose onClose={() => { null }}>
                <DowngradePlan setOpenDowngradePlan={setOpenDowngradePlan} />
            </RSDrawer>

            <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
                <Elements>
                    <UpgradePlan userInfo={userInfo} setOpenPaymentDetails={setOpenPaymentDetails} openPaymentDetails={openPaymentDetails} isUpdatePaymentInfo={true} />
                </Elements>
            </RecurlyProvider>
        </>
    )
}

export default PastDue