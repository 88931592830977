/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import lodash from 'lodash'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useFavorites from '../../favorites/useFavorites'
import MidfootData from '../../midfoot-project/midfoot.db.json'
import AudioPlayerComponent from '../../midfoot-project/AudioPlayer'
import midfootActions from '../../midfoot-project/midfootActions'
import allNewExercises from '../../../modules/videos/allNewExercises.json'
import RSButton from '../../../components/buttons/RSButton'
import RSVideoCard from '../../../components/cards/RSVideoCard'


import dailyVideos from '../../reset/reset-daily-videos.json'
import carryVideoInfo from '../../reset/video-carry-info.json'
import { Video } from '../../../modules/videos/allVideos'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'

const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

interface ICurrentVideos {
	id: number,
	title: string
}


const ProgramReset = (props: any) => {

	const { userInfo, phase, initPhase, prevActivePhase, renderPhaseSelect, renderResetTraining, handlePlayVideo, activeMidfoot, loadMidfootData, setIsLoading, weekVideosWithDuration, setWeekVideosWithDuration, onboarding } = props
	const { favorites, newLoading: isLoading } = useFavorites(userInfo?.account_id as number, 'Training', "exercises", true)
	const [weekVideos, setWeekVideos] = useState<any>({})
	const videoDurationList = useRef<any>({})
	const isMobile = useIsMobileScreen();
	const { loading, updateMidfootData, createMidfootData } = midfootActions()
	const workoutVideoDisplay: any = MidfootData
	const width = window.innerWidth
	const isLargeScreen = width > 1500
	const initVWidth = width - (isLargeScreen ? 48 : 0)
	const nW = initVWidth / (isLargeScreen ? 2 : 1)
	const videoPerRow = Math.floor(nW / 360)
	const colSpace = 12 / videoPerRow

	const videoDurationCall = async (vid: any, day: any) => {
		const videoArray = []
		for (const v of vid) {
			const response = await fetch(
				`https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${v.id}`
			);
			const data = await response.json();
			const duration = data?.duration ? data.duration : 0
			videoArray.push({ ...v, duration })
		}
		videoDurationList.current[day] = videoArray
		videoDurationList?.current && Object.keys(videoDurationList?.current).length === 30 && !Object.keys(weekVideosWithDuration).length && setWeekVideosWithDuration(videoDurationList?.current)
	}

	const getVideoDuration = (videoList: any) => {
		const newWeekVideos: any = {}
		if (videoList?.[1]) {
			setWeekVideos(videoList)
			const newVL: any = {}
			for (const vl in videoList) {
				videoDurationCall(videoList[vl], vl)
			}
			setIsLoading?.(false)
		}
	}

	const getCarryVideos = (currentDay: any) => {
		const allVideos: any = [];
		const carriedFavorites: Video[] = []
		const currentVideos: ICurrentVideos[] = []
		const days = carryVideoInfo.find((x: any) => x.day == currentDay)
		days?.lookUpDays.forEach((day: any) => {
			const videos = dailyVideos.filter(x => x.day === String(day))
			videos.forEach(video => {
				allVideos.push(video?.link.split('/')[3])
			})
		});
		const uniqVideos = lodash.uniq(allVideos) as string[];

		uniqVideos.forEach((videoId: string) => {
			const fav = favorites.find((x: any) => x.id === videoId)
			if (fav)
				carriedFavorites.push(fav as Video)
		})

		carriedFavorites.forEach(fav => {
			const data: ICurrentVideos = {
				id: Number(fav.id),
				title: fav.headingTitle
			}
			currentVideos.push(data)
		})
		const dv = dailyVideos.filter((x: any) => x.day === String(currentDay))?.map((dvx: any) => {
			const tempId = Number(dvx.link) ? Number(dvx.link) : Number(dvx.link.split('/')?.[3])
			return ({ id: tempId, title: dvx.title })
		})
		return [...dv, ...currentVideos]
	}

	useEffect(() => {
		if (!isLoading) {
			const allVideos: any = {};
			[...Array(30)].map((_, arrayIndex: number) => {
				allVideos[arrayIndex + 1] = getCarryVideos(arrayIndex + 1)
			})
			getVideoDuration(allVideos)
		}
	}, [isLoading, favorites])

	const renderVideos = (videoList: Array<any>) => {
		const list = videoList.map((vd: any) => {
			const { id, title, duration } = vd
			return {
				headingTitle: title,
				duration,
				id,
				src: `https://vumbnail.com/${id}.jpg`,
				onClick: () => handlePlayVideo(id, title),
				program: "reset"
			}
		})
		return <Grid container sx={{ px: 3 }}>
			<RSVideoCard videoList={list} sx={{ pt: 0 }} onboarding={onboarding} />
		</Grid>
	}


	const renderTraining = () => {
		const videos = weekVideosWithDuration?.[phase + 1] ? weekVideosWithDuration[phase + 1] : weekVideos?.[phase + 1]
		return videos ? <>
			<Grid container xs={12}>
				<Grid container xs={6}>
					{renderPhaseSelect?.()}
				</Grid>
				<Grid container xs={6}>
					{renderResetTraining?.()}
				</Grid>
			</Grid>
			<AnimatePresence mode='wait' initial={false}>
				<motion.div
					key={phase}
					custom={initPhase - prevActivePhase?.current}
					variants={isMobile ? variants : undefined}
					initial="enter"
					animate="center"
					exit='exit'
					transition={{ duration: 0.2 }}
				>
					{renderVideos(videos)}
				</motion.div>
			</AnimatePresence>
		</>
			:
			<></>
	}

	return renderTraining()

}

export default ProgramReset;
