/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect, useRef } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT, DAYS_OF_WEEK_ABBV_SHORT, DAYS_OF_WEEK_ALT_ABBV_SHORT } from '../training-plan/build/DaysOptions';

import RSInputAdornment2 from '../../components/input/RSInputAdornment2';
import { RSButton2 } from '../../components/buttons'
import { SH2, P1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'
import useRSTabs from '../../hooks/useRSTabs'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';

import RSDateInput from '../../components/input/RSDateInput';
import useGPTTrainingPlan from './useGPTTrainingPlan';
import useRSRunAIsuggestions from '../../hooks/useRSRunAIsuggestions';

export type EstablishBasePlanProps = {
	accountProfile: AccountProfile,
	allStageDetails: any,
	addDetails: any,
	resetData?: any,
	handleIsStepComplete?: any
	raceDetails: any,
	subStep: number,
	setUpdatedBaseDetails: any
	startDate?: string
	endDate?: string
}

const stageNumber = '1-0'

const EstablishBasePlan: FC<EstablishBasePlanProps> = ({ accountProfile, allStageDetails, addDetails, handleIsStepComplete, raceDetails, subStep, setUpdatedBaseDetails, startDate, endDate }) => {
	const { currentWeeklyMileage, userInputCurrentWeeklyMileage, lastLongestRun, userInputRecentLongRun, dob: birthDate, raceType } = allStageDetails?.['1-0'] || {}
	const { getMarathonMiles } = useTrainingPlanUtils()
	const { getSuggestedPlanStartDistance } = useRSRunAIsuggestions()

	const { dob, measurement_system, running_activity, gender, run_day } = accountProfile
	const [runningActivity, setRunningActivity] = useState(userInputCurrentWeeklyMileage || '');
	const [longestRecentRun, setLongestRecentRun] = useState(userInputRecentLongRun || '');
	const [dateValue, setDateValue] = useState<any>(birthDate || dob || null);
	const isMetric = measurement_system === 'metric' ? true : false
	const metricArial = isMetric ? 'Kilometers' : 'Miles'

	const isMaintenancePlan = raceType?.toLowerCase() === 'maintenance' ? true : false
	const { preferredLongRun, runDaysPerWeek } = allStageDetails?.['1-1'] || {}
	const [longRunDay, setLongRunDay] = useState(preferredLongRun || run_day || '')
	const [daysPerWeek, setDaysPerWeek] = useState(runDaysPerWeek || 3)
	const [fetchedSuggestedPlanStartDistance, setFetchedSuggestedPlanStartDistance] = useState(false)
	const [showErrorAboveCap, setShowErrorAboveCap] = useState(false)

	const { availableRunDays } = allStageDetails?.['1-2'] || {}
	const [availableDays, setAvailableDays] = useState<Array<string>>(availableRunDays || [preferredLongRun || run_day])
	const daysOfWeek = DAYS_OF_WEEK
	const daysOfWeekABBV = DAYS_OF_WEEK_ABBV_SHORT
	const initialRef = useRef<any>(true)

	const runningAct = running_activity?.toLowerCase()?.includes('more than') ? running_activity?.split(' ')?.[2] : running_activity?.split(' ')?.[0]

	const { calculatePlanWeeks, calculateStartDate, calculateStartWeek, thisSunday } = useGPTTrainingPlan()
	const handleRunningActivityInput = (e: any) => {
		// (/^\d*\.?\d*$/.test(e.target.value)) && e.target.value <= 70 && setRunningActivity(e.target.value)
		console.log(e.target.value)
	}

	const calcWeeksDiff = (startDate: any, endDate: any) => {
		const dateRangeDaysDiff = moment(endDate).diff(moment(startDate).subtract(1, 'd'), 'days')
		const weeksCount = Math.floor(dateRangeDaysDiff / 7)
		return weeksCount
	}


	useEffect(() => {
		
		let raceDate = (raceDetails?.datePerRaceType && raceDetails?.datePerRaceType.length > 0) ?
			raceDetails?.datePerRaceType?.find((rd: any) => raceDetails.selectedRaceDistance?.toUpperCase()?.includes(rd?.type?.toUpperCase()))?.date : raceDetails?.date || 'invalid date'

		isMaintenancePlan && (raceDate = endDate)
		let raceName = raceDetails?.name || 'Marathon'
		if (isMaintenancePlan) {
			const dateRangeDaysDiff = moment(raceDate).diff(moment(startDate).subtract(1, 'd'), 'days')
			const weeksCount = Math.floor(dateRangeDaysDiff / 7)
			raceName = `${weeksCount} Week${weeksCount > 1 ? 's' : ''} Maintenance Plan`
		}


		// Getting and setting the suggested plan start distance
		if(runningActivity > 0 && longestRecentRun > 0 && !fetchedSuggestedPlanStartDistance && subStep >= 1){

			getSuggestedPlanStartDistance(allStageDetails).then((response: any) => {
				// console.log('>>> getSuggestedPlanStartDistance', response)

				if(response && response > 0) {
					setFetchedSuggestedPlanStartDistance(true)
					// console.log('[setting running activity]')
					// setRunningActivity(response)

					addDetails(stageNumber,  {
						planWeeks: isMaintenancePlan ? calcWeeksDiff(startDate, raceDate) : calculatePlanWeeks(raceDate, calculateStartWeek(startDate)),
						raceName,
						raceDate: isMaintenancePlan ? moment(raceDate).format('MM/DD/YYYY') : raceDate,
						startDate: isMaintenancePlan ? moment(startDate).format('MM/DD/YYYY') : calculateStartWeek(startDate) || calculateStartDate(raceDate),
						dob: dateValue,
						gender: gender || 'Male',
						currentWeeklyMileage: runningActivity,
						userInputCurrentWeeklyMileage: runningActivity,
						suggestedPlanStartDistance: response,
						unit: metricArial,
						userInputRecentLongRun: longestRecentRun,
						lastLongestRun: response || longestRecentRun,
						raceType: raceDetails?.selectedRaceDistance,
						raceDistance: getMarathonMiles(raceDetails?.selectedRaceDistance)
					})
				}
			})

		} else if(runningActivity > 0 && longestRecentRun > 0 && subStep < 1) {

			// console.log('resetting suggested plan start date')
			setFetchedSuggestedPlanStartDistance(false)
		}


		if (`1-${subStep}` === stageNumber) {
			const newDetails = {
				planWeeks: isMaintenancePlan ? calcWeeksDiff(startDate, raceDate) : calculatePlanWeeks(raceDate, calculateStartWeek(startDate)),
				raceName,
				raceDate: isMaintenancePlan ? moment(raceDate).format('MM/DD/YYYY') : raceDate,
				startDate: isMaintenancePlan ? moment(startDate).format('MM/DD/YYYY') : calculateStartWeek(startDate) || calculateStartDate(raceDate),
				dob: dateValue,
				gender: gender || 'Male',
				currentWeeklyMileage: runningActivity,
				suggestedPlanStartDistance: runningActivity,
				unit: metricArial,
				userInputRecentLongRun: longestRecentRun,
				lastLongestRun: longestRecentRun,
				raceType: raceDetails?.selectedRaceDistance,
				raceDistance: getMarathonMiles(raceDetails?.selectedRaceDistance)
			}
			addDetails(stageNumber, newDetails)
			handleIsStepComplete(runningActivity && longestRecentRun ? true : false, stageNumber)
			
		}

	}, [dateValue, runningActivity, longestRecentRun, subStep, isMaintenancePlan, endDate])

	useEffect(() => {

		const stageNumber = '1-1'
		if (`1-${subStep}` === stageNumber) {
			addDetails(stageNumber, {
				preferredLongRun: longRunDay,
				runDaysPerWeek: daysPerWeek
			})
			handleIsStepComplete(longRunDay && daysPerWeek ? true : false, stageNumber)
		}
	}, [longRunDay, daysPerWeek, subStep])

	useEffect(() => {
		const stageNumber = '1-2'
		if (`1-${subStep}` === stageNumber) {
			addDetails(stageNumber, {
				availableRunDays: availableDays
			})
			handleIsStepComplete(availableDays?.length >= daysPerWeek ? true : false, stageNumber)
		}

	}, [availableDays, subStep])

	useEffect(() => {
		if (allStageDetails?.['1-2']?.availableRunDays != availableDays) {
			setUpdatedBaseDetails(true)
			// console.log('updated some details in EstablishBasePlan')
		}
	}, [dateValue, runningActivity, longestRecentRun, longRunDay, daysPerWeek, availableDays])

	useEffect(() => {
		!initialRef.current && setAvailableDays(daysPerWeek === daysOfWeek.length ? daysOfWeek : [longRunDay])
		initialRef.current = false
	}, [daysPerWeek, longRunDay])

	useEffect(() => {
		if(runningActivity == '') return
		if (Number(longestRecentRun) > Number(runningActivity)) {
			setLongestRecentRun(runningActivity)
		} else {
			setShowErrorAboveCap(false)
		}
	}, [longestRecentRun])

	useEffect(() => {
		if(runningActivity == '') return
		if (Number(longestRecentRun) > Number(runningActivity)) {
			handleIsStepComplete(false, stageNumber)
			setShowErrorAboveCap(true)
		} else {
			handleIsStepComplete(runningActivity && longestRecentRun ? true : false, stageNumber)
			setShowErrorAboveCap(false)
		}
	}, [runningActivity])

	

	useEffect(() => {

		const stageNumber = '1-1'
		addDetails(stageNumber, {
			preferredLongRun: longRunDay,
			runDaysPerWeek: daysPerWeek
		})

	}, [])


	const renderBasePlan = () => {
		return <Grid container>
			{/* <Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				Date of birth
			</Grid>
			<Grid container sx={{ maxWidth: '150px' }}>
				<RSDateInput date={dateValue} setDate={setDateValue} maxDate={moment()} outlineSX={{ border: '1px solid #9FCD2B' }} />
			</Grid> */}
			<Grid container>
				<Grid container xs={12} sx={{ pb: 1 }} alignItems='flex-end'>
					<p style={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1.3em', marginBottom: '0px' }}>
						{moment(thisSunday()).format('MMM D, YYYY') != moment(startDate).format('MMM D, YYYY') ?
							<>What do you anticipate averaging per week when the plan starts on <span style={{ lineHeight: '1em', fontFamily: 'Poppins-Bold' }}>{moment(startDate).format('MMM D, YYYY')}</span>?</>
							:
							<>In the past <span style={{ lineHeight: '1em', fontFamily: 'Poppins-Bold' }}>four weeks</span>, what’s your weekly average per week?</>
						}
					</p>
				</Grid>
				<Grid container>
					<RSInputAdornment2 sx={{ maxWidth: '80px' }} value={runningActivity} setValue={setRunningActivity} wholeNumber min={0} max={70} label={metricArial} placeholder={'20'} />
				</Grid>
				<Grid container xs={12} sx={{ pt: 2, pb: 1 }} alignItems='flex-end'>
					<p style={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1.3em', marginBottom: '0px' }}>
						{moment(thisSunday()).format('MMM D, YYYY') != moment(startDate).format('MMM D, YYYY') ?
							<>What do you anticipate your longest run to be around this time?</>
							:
							<>What is your longest run in the last <span style={{ lineHeight: '1em', fontFamily: 'Poppins-Bold' }}>two weeks</span>?</>}
					</p>
				</Grid>
				<Grid container>
					<RSInputAdornment2 sx={{ maxWidth: '80px' }} value={longestRecentRun} setValue={setLongestRecentRun} wholeNumber min={0} max={ ((runningActivity == '' || runningActivity) < lastLongestRun && 99) || runningActivity || 22} label={metricArial} placeholder={"6"} />
				</Grid>
				{ showErrorAboveCap && <Grid container xs={12} sx={{ pt: 2, pb: 1 }} alignItems='flex-end'>
					<p style={{ color: 'red', fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.3em', marginBottom: '0px' }}>
						{`*Your longest run can't be greater than your recent weekly average.`}
					</p>
				</Grid> }
			</Grid>
		</Grid >
	}

	const renderPreferredDays = () => {
		return <Grid container>
			<Grid container xs={12} alignItems='flex-end'>
				<p style={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em', marginBottom: '0px' }}>
					How many  {<span style={{ fontFamily: 'Poppins-Bold' }}>days per week</span>} do you prefer to run?
				</p>
			</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.3em', pt: '2px', fontStyle: 'italic' }} alignItems='flex-end'>
				Minimum 3 days
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }}>
				{[...Array(5)].map((_, d: any) => {
					const day = d + 3
					const isActive = daysPerWeek === day
					return <Box key={d + '-dpw'} sx={{ p: '0px 1px', height: '50px', width: '45px', mr: 1 }}>
						<RSButton2 ghost={!isActive} onClick={() => setDaysPerWeek(day)} sx={{ position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%' }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px', color: '#010101' }}>{day}</Box>
						</RSButton2>
					</Box>
				})}
			</Grid>
			<Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px !important', lineHeight: '1em' }} alignItems='flex-end'>
				What day is best for your long run?
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }}>
				{daysOfWeek?.map((day: string, index: number) => {
					const slicedDay = daysOfWeekABBV[index]
					const isActive = longRunDay === day
					return <Grid container key={index + day + 'lr'} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
						<RSButton2 ghost={!isActive} onClick={() => setLongRunDay(day)} sx={{ position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%', color: '#010101', px: 0 }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px !important', color: '#010101' }}>{slicedDay}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid>
		</Grid>
	}

	const renderAvailableDays = () => {
		return <Grid container>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				{`Select your preferred days to run`}
			</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.3em', pt: '2px', fontStyle: 'italic' }} alignItems='flex-end'>
				Minimum {daysPerWeek} days
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }}>
				{daysOfWeek?.map((day: string, index: number) => {
					const slicedDay = daysOfWeekABBV[index]
					const isActive = availableDays?.includes(day)
					const isDayLongRun = longRunDay === day || daysPerWeek === daysOfWeek.length
					return <Grid container key={index + day} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
						<RSButton2 ghost={!isActive} selected={isDayLongRun} disabled={isDayLongRun} onClick={() => !isDayLongRun && (isActive ? setAvailableDays(availableDays.filter((ad: string) => ad !== day)) : setAvailableDays([...availableDays, day]))}
							sx={{ textTransform: 'none !important', opacity: isDayLongRun ? 0.5 : 1, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', minWidth: '100%', px: 0 }}
						>
							<Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light !important', fontSize: '14px !important', color: '#010101' }}>{slicedDay}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid>
		</Grid>
	}

	const stepData = [
		{ body: renderBasePlan() },
		{ body: renderPreferredDays() },
		{ body: renderAvailableDays() }
	]

	const { tabsUI, handleChangeActive } = useRSTabs({ data: stepData, animateDesktop: true })

	useEffect(() => {
		handleChangeActive(subStep)
	}, [subStep])

	return <Box sx={{ px: 3 }}>
		<SH2 size={3} sx={{ pb: '10px', width: '100%' }}>
			Tell us about your running
		</SH2>
		<P1 sx={{ pb: 3, width: '100%' }}>
			We’ll use this information to personalize your plan to meet you where you are currently.
		</P1>
		{tabsUI}
	</Box>
}

export default EstablishBasePlan;
