/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from "moment"
import raceSelection from "../training-plan/build/RaceSelection.json"
import progressionWorkouts from "../../modules/training-plan/progression-workouts.json"
import tempoWorkouts from "../../modules/training-plan/tempo-workouts.json"
import intervalWorkouts from "../../modules/training-plan/interval-workouts.json"
import lastWeekRuns from "../../modules/training-plan/last-week-runs.json"
import exercises from "../../modules/training-plan/exercises.json"
import exerciseTypes from "../../modules/training-plan/exercises-type-map.json"
import programVideos from "../../modules/videos/programVideos.json"

const defaultMaxPlanWeeks = 20

const useGPTTrainingPlan = () => {

    const buildLastWeekRuns = (distance: number, paces: any[], type: number, isKm?: string) => {
        const mar_id = paces[0]?.mar_id
        const paceId = paces[0]?.paceId
        const runs: any = []
        const lastWeekRunsValues = lastWeekRuns.find(x => x.raceType == type)

        if (isKm == 'Y') {
            distance = Math.round(distance * 0.621371)
        }

        lastWeekRunsValues?.runs.forEach((run, i) => {
            runs.push({
                "mar_id": mar_id,
                "paceId": paceId + i,
                "pace": run.pace,
                "notes": run.notes.toLowerCase().includes('repeat') ? `Repeat ${distance} times` : run.notes,
                "orderid": i,
                "type": "race_week"
            })
        })

        return runs

    }

    const buildIntervalWorkouts = (distance: number, paces: any[], isKm?: string) => {
        const mar_id = paces[0]?.mar_id
        const paceId = paces[0]?.paceId
        const workouts: any = []

        if (isKm == 'Y') {
            distance = Math.round(distance * 0.621371)
        }
        let intervalWorkout = intervalWorkouts.find(x => x.totalDistance === Math.floor(distance))
        if (distance < intervalWorkouts[0].totalDistance)
            intervalWorkout = intervalWorkouts[0]
        else if (distance > intervalWorkouts[intervalWorkouts.length - 1].totalDistance)
            intervalWorkout = intervalWorkouts[intervalWorkouts.length - 1]

        intervalWorkout?.runs.forEach((run, i) => {
            workouts.push({
                "mar_id": mar_id,
                "paceId": paceId + i,
                "pace": run.pace,
                "notes": run.type === "run" ? run?.distance + run?.unit : run?.type === "recovery" ? `Recovery time is ${run?.percent} of Interval` : run.type === "repeat" ? `Repeat ${run?.repeat} times` : "",
                "orderid": i,
                "type": "interval"
            })
        })
        return workouts
    }

    const buildTempoWorkouts = (distance: number, paces: any[], isKm?: string) => {
        const mar_id = paces[0]?.mar_id
        const paceId = paces[0]?.paceId
        const workouts: any = []

        if (isKm == 'Y') {
            distance = Math.round(distance * 0.621371)
        }

        let tempoWorkout = tempoWorkouts.find(x => x.totalDistance === Math.floor(distance))

        if (!tempoWorkout) {
            tempoWorkout = tempoWorkouts[tempoWorkouts.length - 1]
        }

        tempoWorkout?.runs.forEach((run: any, i) => {
            workouts.push({
                "mar_id": mar_id,
                "paceId": paceId + i,
                "pace": run.pace === "Repeat" || run.pace === "Recovery" ? '' : run.pace,
                "notes": run.distance + `${run.pace === "Recovery" || run.pace === "Repeat" ? '' : ' miles'}`,
                "orderid": i,
                "type": "tempo"
            })
        })
        return workouts
    }

    const getProgressionOverMax = (distance: number, partValue: number) => {

        const numberOfParts = Math.floor(distance / partValue);
        const remainder = distance % partValue;
        const results = new Array(numberOfParts).fill(partValue);

        if (remainder !== 0) {
            results.push(remainder);
        }

        return results;
    }

    const buildProgressionWorkouts = (distance: number, paces: any[], isKm?: string) => {
        const mar_id = paces[0]?.mar_id
        const paceId = paces[0]?.paceId
        const workouts: any = []

        if (isKm == 'Y') {
            distance = Math.round(distance * 0.621371)
        }

        // let diff = 0
        let progressionWorkout = progressionWorkouts.find(x => x.totalDistance === Math.floor(distance))

        if (distance < progressionWorkouts[0].totalDistance)
            progressionWorkout = progressionWorkouts[0]

        else if (distance > progressionWorkouts[progressionWorkouts.length - 1].totalDistance)
            progressionWorkout = progressionWorkouts[progressionWorkouts.length - 1]

        // if (progressionWorkout)
        //     diff = distance - progressionWorkout.totalDistance

        // const distanceOver = getProgressionOverMax(diff, 5)
        // console.log('>>> distanceOver', distanceOver)
        // if (distanceOver.length && progressionWorkout) {
        //     const lastRun = progressionWorkout.runs[progressionWorkout.runs.length - 1]
        //     distanceOver.forEach((part: number, i) => {
        //         progressionWorkout?.runs.push({ ...lastRun, distance: part })
        //     })
        // }

        // if (!progressionWorkout)
        //     progressionWorkout = progressionWorkouts[progressionWorkouts.length - 1]

        progressionWorkout?.runs.forEach((run: any, i) => {
            workouts.push({
                "mar_id": mar_id,
                "paceId": paceId + i,
                "pace": run.pace,
                "notes": run.distance + " miles",
                "orderid": i,
                "multiplier": run.multiplier,
                "type": "progression"
            })
        })
        return workouts
    }

    const convertToLegacyTrainingInfo = (data: any) => {
        if (!data) return null

        let conversion = { toMiles: false, toKm: false }

        if (data?.unit === 'km' && !data?.is_km) {
            conversion = { toMiles: true, toKm: false }
        }
        else if (data?.unit === 'mi' && data?.is_km) {
            conversion = { toMiles: false, toKm: true }
        }

        const trainingPlanInfo = {
            "uid": data.plan_id,
            "internal_id": data.user_id,
            "start": data.start_date,
            "race_date": data.race_date,
            "signup_date": moment().format('YYYY-MM-DD'),
            "location": null,
            "late_start": 1,
            "monday_start": 0,
            "aggressiveness": 0,
            "training_level": data.training_level,
            "status": data.status,
            "type": raceSelection.find(x => x.title.toLowerCase() === data.race_type.toLowerCase() || x.subtitle.toLowerCase() === data.race_type.toLowerCase())?.raceValue,
            "weeks": data.weeks,
            // "distance": conversion.toKm ? Math.round(data.max_distance * 1.60934) : conversion.toMiles ? Math.round(data.max_distance / 1.60934) : data.max_distance,
            "distance": data.max_distance,
            "km": data.is_km ? 'Y' : 'N',
            "vdot": data.vdot || 0,
            "targetm": data.target_time || 0,
            "plan_type": "GPT",
            "training_plan": morphTrainingPlan(data, data.training || [], conversion),
            "is_active_plan": data.isActivePlan,
            "event_name": data.event_name,
            "race_link": data.race_link,
            "race_logo": data.race_logo,
            "race_origin": data.race_origin,
            "unit": data?.unit,
        }
        return trainingPlanInfo
    }

    const formatDateForCalendar = (dateStr: any) => {

        const date = moment(dateStr);
        const year = date.format('YYYY');
        const month = date.format('MM');
        let day = date.format('DD');

        // Remove starting zero from days
        if (day.startsWith('0')) {
            day = day.substring(1);
        }

        const formattedDate = `${year}-${month}-${day}`
        return formattedDate;
    }

    const incrementDate = (dateStr: any, times: number) => {
        return moment(dateStr).add(times, 'days').format('YYYY-MM-DD');
    }

    const checkCrossTraining = (crossTraining: any) => {

        const trainingTypes = ['strength', 'yoga', 'stretch'];
        const regexPattern = new RegExp('\\b(' + trainingTypes.join('|') + ')\\b', 'i');

        return regexPattern.test(crossTraining.toLowerCase());
    }

    const generateStretchingVideos = (areas: string, mar_id: number) => {
        const exerciseVideoLink = "https://app.runsmartonline.com/#/exercises/"
        const stretchingIds: any = []
        const stretching: any = []
        const areasArray = areas.toLowerCase().split(',')

        if (areasArray.length) {
            if (areasArray.length >= 5) {
                exercises.find(x => x.part.toLowerCase() === "all")?.exercises.forEach((exercise: number) => {
                    stretchingIds.push(exercise)
                })
            }
            else
                areasArray.forEach((area: any, i) => {
                    exercises.find(x => x.part.toLowerCase() === area.toLowerCase())?.exercises.forEach((exercise: number) => {
                        stretchingIds.push(exercise)
                    })
                })
        }


        if (stretchingIds.length) {
            stretchingIds.forEach((id: number, i: number) => {
                const workoutType = exerciseTypes.find(x => id?.toString()?.includes(x.id.toString()))?.type
                stretching.push({
                    "mid": mar_id,
                    "workoutId": i,
                    "title": "RunSmart Exercise",
                    "description": "RunSmart Exercise",
                    "link": exerciseVideoLink + id,
                    "workout_type": workoutType || 'Stretch',
                })
            })
        }

        return stretching
    }

    const generateYogaVideos = (mar_id: number, i: number) => {
        const yogaVideoLink = "https://app.runsmartonline.com/#/programs/yoga/"
        const introVideos = programVideos.filter(x => x?.item === "Yoga" && x.type.toLowerCase().includes('intro'))
        const yogaVideos = programVideos.filter(x => x?.item === "Yoga" && x.type.toLowerCase().includes('day'))
        const allVideos = [...introVideos, ...yogaVideos]

        const video: any = [{
            "mid": mar_id,
            "workoutId": i,
            "title": allVideos[i]?.main_headline,
            "description": 'Yoga',
            "link": yogaVideoLink + allVideos[i]?.id,
            "orderid": 0,
            "workout_type": 'Yoga'
        }]

        return video
    }

    const generateStrengthVideos = (mar_id: number, i: number) => {

        const base3Videos = programVideos.filter(x => x?.item === "Base3")
        const base6Videos = programVideos.filter(x => x?.item === "Base6")

        const allVideos = [...base3Videos, ...base6Videos]

        const strengthVideoLink = `https://app.runsmartonline.com/#/programs/${allVideos[i]?.item}/${allVideos[i]?.id}`

        // console.log('>>> allVideos', allVideos)

        const video: any = [{
            "mid": mar_id,
            "workoutId": i,
            "title": allVideos[i]?.item + " " + allVideos[i]?.main_headline,
            "description": 'Strength',
            "link": strengthVideoLink,
            "orderid": 0,
            "workout_type": 'Strength'
        }]

        return video
    }

    const generateWorkoutVideos = (runData: any, weeks: number, level: number, cross_training: string, areas: string, type: string) => {
        const yoga_days = runData.filter((x: any) => x.activitiesValue.workouts[0].workout_type?.toLowerCase().includes("yoga"))
        const strength_days = runData.filter((x: any) => x.activitiesValue.workouts[0].workout_type?.toLowerCase().includes("strength"))

        let stretching_days: any;
        if (type.toLowerCase() === "5k" || type.toLowerCase() === "10k" || type.toLowerCase() === "8k") {
            stretching_days = runData.filter((x: any) => (x.activitiesValue.workouts[0].workout_type?.toLowerCase().includes("stretching") || x.activitiesValue.workouts[0].workout_type?.toLowerCase().includes("stretch")))
        }
        else
            stretching_days = runData.filter((x: any) => (x.activitiesValue.workouts[0].workout_type?.toLowerCase().includes("stretching") || x.activitiesValue.workouts[0].workout_type?.toLowerCase().includes("stretch")) && (x.activitiesValue.marathon.week != weeks || x.activitiesValue.marathon.week < weeks))


        const hasYoga = cross_training && cross_training.toLowerCase().includes('yoga')
        const hasStrength = cross_training && cross_training.toLowerCase().includes('strength')

        let yogaIndex = 0
        let strengthIndex = 0

        if (hasYoga)
            yoga_days.forEach((day: any, i: number) => {
                yogaIndex++
                const yogaWorkout = generateYogaVideos(day.activitiesValue.marathon.id, (yogaIndex - 1)) || []
                if (yogaWorkout.length && !yogaWorkout[0]?.title?.includes('undefined'))
                    day.activitiesValue.workouts = yogaWorkout
            })

        if (hasStrength)
            strength_days.forEach((day: any, i: number) => {
                strengthIndex++
                const strengthWorkout = generateStrengthVideos(day.activitiesValue.marathon.id, (strengthIndex - 1)) || []
                if (strengthWorkout.length && !strengthWorkout[0]?.title?.includes('undefined'))
                    day.activitiesValue.workouts = strengthWorkout
            })

        if (areas) {
            stretching_days.forEach((day: any, i: number) => {
                const stretchings = generateStretchingVideos(areas, day.activitiesValue.marathon.id)
                day.activitiesValue.workouts = stretchings
            })
        }
    }

    const removeFinalWeekCrossTraining = (data: any, raceType: number, raceDayName: string) => {
        const isTaperPrev = (raceDayName.toLowerCase() === "monday" || raceDayName.toLowerCase() === "sunday") && (raceType === 2 || raceType === 3 || raceType === 5)
        const lastWeek = data[data.length - (isTaperPrev ? 2 : 1)];
        let dailyRuns = JSON.parse(lastWeek.daily_runs);
        dailyRuns = dailyRuns.map((run: any) => {
            if (run.cross_training?.toLowerCase()?.includes('yoga') || run.cross_training?.toLowerCase()?.includes('strength')) {
                run.cross_training = "None";
            }
            return run;
        });

        lastWeek.daily_runs = JSON.stringify(dailyRuns);
        return data;
    }

    const removeSecondDecimal = (floatNum: number) => {

        if (!floatNum) return 0;

        const str = floatNum.toString();
        const decimalIndex = str.indexOf('.');

        if (decimalIndex !== -1 && str.length > decimalIndex + 2) {
            return parseFloat(str.substring(0, decimalIndex + 2));
        }

        return floatNum;
    }

    const morphTrainingPlan = (planInfo: any, gptTableData: any, conversion?: any) => {

        const lastMarId = 2332;
        const lastPaceId = 6482;
        const lastWorkoutId = 4600;
        const calendarViewData: any = []
        let marathonId = lastMarId;
        let paceId = lastPaceId;
        let workoutId = lastWorkoutId;

        let dateVal = moment().format('YYYY-MM-DD')
        dateVal = planInfo?.start_date?.toString().slice(0, planInfo?.start_date?.toString().indexOf('T'))?.replaceAll('-', '/')
        let dateValCalendar = formatDateForCalendar(dateVal).includes('Invalid') ? formatDateForCalendar(planInfo?.start_date) : formatDateForCalendar(dateVal)

        // console.log('dateValCalendar', dateValCalendar)

        const lastWeek = gptTableData[gptTableData.length - 1]
        const addRaceDay = moment(planInfo?.race_date).diff(moment(planInfo?.start_date), 'days') % 7 == 0

        const raceType = raceSelection.find(x => x.title.toLowerCase() === planInfo.race_type.toLowerCase())?.raceValue || raceSelection.find(x => x.subtitle.toLowerCase() === planInfo.race_type.toLowerCase())?.raceValue
        const raceDayName = moment(planInfo?.race_date).format('dddd').toLowerCase();

        const isKm = planInfo?.is_km;
        if (addRaceDay && raceType != 4) {
            gptTableData.push({ ...lastWeek, week: lastWeek.week + 1 })
        }

        for (const week of removeFinalWeekCrossTraining(gptTableData, raceType as number, raceDayName)) {
            const dailyRuns = week?.daily_runs && JSON.parse(week.daily_runs)
            dailyRuns.forEach((dd: any, dI: number) => {
                const day = { ...dd, run_type: dd?.run_type || '' }
                marathonId++
                paceId++
                workoutId++
                // const runDistance = isKm ? Number((1.60934 * day.run_distance).toFixed(1)) : Math.round(day.run_distance)
                const isDefaultBase = day.run_distance > 0 && day?.run_type?.toLowerCase()?.includes('rest') ? true : false
                isDefaultBase ? day.run_type = 'Base' : day.run_type
                const runDistance = !conversion ? (isKm ? removeSecondDecimal(day.run_distance) : Math.round(day.run_distance)) : (conversion.toKm ? Math.round(day.run_distance * 1.60934) : conversion.toMiles ? Math.round(day.run_distance * 0.621371) : Math.round(day.run_distance))
                const dayIndex = dI + 1
                const data = {
                    "week": week.week,
                    "monthData": {
                        "week": week.week,
                        "day": dayIndex,
                        "dateVal": dateValCalendar,
                        "monthDigits": []
                    },
                    "activitiesValue": {
                        "marathon": {
                            "id": marathonId,
                            "marathon": 0,
                            "type": planInfo.weeks,
                            "training_goal": planInfo?.max_distance,
                            "week": week.week,
                            "day": dayIndex,
                            "distance": runDistance,
                            "race_day": null,
                            "converted": true,
                        },
                        "marathonPaces": [
                            {
                                "mar_id": marathonId,
                                "paceId": paceId,
                                "pace": day?.run_type?.toLowerCase()?.includes('workout') ? day?.run_type?.replace('Workout', '')?.trim() : day?.run_type?.replace('Run', '')?.trim(),
                                "notes": runDistance + " ",
                                "orderid": 0
                            }
                        ],
                        "workouts": [
                            {
                                "mid": marathonId,
                                "workoutId": workoutId,
                                "title": (day.run_type == 'Rest' || day.run_distance == 0) && checkCrossTraining(day.cross_training || '') ? "Cross Train" : '',
                                "description": day.cross_training,
                                "link": "",
                                "orderid": 0,
                                "workout_type": day.cross_training
                            }
                        ]
                    }
                }

                dateVal = incrementDate(dateVal, 1)
                dateValCalendar = formatDateForCalendar(dateVal)
                calendarViewData.push(data)
            })
        }

        // console.log('>>> calendarViewData', calendarViewData)
        generateWorkoutVideos(calendarViewData, planInfo.weeks, planInfo.training_level, planInfo.cross_training, planInfo.stretching_areas, planInfo?.race_type)
        return calendarViewData
    }

    const calculatePlanWeeks = (planRaceDate: any, manualStartDate: any) => {
        const raceDate = moment(planRaceDate, 'MMMM D, YYYY');

        // const dayToday = moment().day();
        // const customStartDate = moment(manualStartDate).day();
        // const daysUntilNextSunday = 7 - (customStartDate || dayToday);

        // const dateNextSunday = moment(manualStartDate).add(daysUntilNextSunday, 'days');

        const weeksLeft = Math.ceil(raceDate.diff(manualStartDate, 'weeks', true));
        // // console.log(`Planned weeks for ${raceDate}: ${weeksLeft}`);

        return weeksLeft > defaultMaxPlanWeeks ? defaultMaxPlanWeeks : weeksLeft
    }

    const calculateStartDate = (planRaceDate: any) => {

        const raceDate = moment(planRaceDate, 'MMMM D, YYYY')
        const weeksTillRace = Math.ceil(raceDate.diff(thisSunday(), 'weeks', true))
        const weeksBeforeStartDate = weeksTillRace > defaultMaxPlanWeeks ? (weeksTillRace - defaultMaxPlanWeeks) : 0

        return moment(thisSunday()).add(weeksBeforeStartDate, 'weeks').format('MMM D, YYYY');
    }

    const calculateStartWeek = (startDate: any) => {

        const pastSunday = () => {
            const day = moment(startDate).day();
            const sundayDate = moment(startDate).subtract(day, 'days').format('YYYY-MM-DD')
            return sundayDate
        }

        return pastSunday()

    }

    const thisSunday = () => {
        const dayToday = moment().day();
        const daysUntilNextSunday = 7 - dayToday;
        return moment().add(daysUntilNextSunday, 'days').format('YYYY-MM-DD');
    }

    return { convertToLegacyTrainingInfo, morphTrainingPlan, buildProgressionWorkouts, buildTempoWorkouts, buildIntervalWorkouts, buildLastWeekRuns, calculatePlanWeeks, calculateStartDate, calculateStartWeek, thisSunday, generateWorkoutVideos }
}

export default useGPTTrainingPlan
