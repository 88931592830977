import { AdminUser } from "../api/v2/admin-users/admin-users.types";
import { deleteReq, getReq, postReq } from "./apiConsume";

export const GetAllAdminUsers = async (page: number, limit: number) =>
    await getReq(`/v2/admin-users?page=${page}&limit=${limit}`)

export const GetAdminUserById = async (id: number) =>
    await getReq(`/v2/admin-users?user_id${id}`)

export const CreateAdminUser = async (user: AdminUser) =>
    await postReq(`/v2/admin-users`, user)

export const DeleteAdminUser = async (id: number) =>
    await deleteReq(`/v2/admin-users/${id}`)

export const SearchAccounts = async (searchParam: string) =>
    await getReq(`/v2/admin-users/search?params=${searchParam}`)