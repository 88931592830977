/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getReq, navReq, postReq } from '../modules/apiConsume'
import { getAllAccountInfo, signOut, GetOnBoarding, autoLogin } from '../modules/accountActions'
import { AuthUserInfo } from '../api/types'
import { CSS_DEV_MODE, IS_PROD, HAS_SALE } from '../modules/cliEnvValues'
import { getCurrentAmount, getCurrentPlanCode } from '../modules/subscriptionUtils'
import { urlWithOffer } from '../pages/account/SignIn'
import defaultUserInfo from '../modules/defaultUserInfo.json'
import useAppStorage from '../hooks/useAppStorage'

import {
	FC,
	ComponentClass,
	FunctionComponent,
	useEffect,
	useState,
	useRef,
} from 'react'
import useStore from '../store/useStore'
import { ICurrentUser } from '../store/createAccountStore'
import { useLocation } from 'react-router-dom'
import { IComponentStore } from '../store/createComponentStore'
import sha1 from 'locutus/php/strings/sha1'
import useQueryParams from '../hooks/useQueryParams'
import useStoreWSMessages from '../hooks/useStoreWSMessages'
import { planCodes } from '../api/values'
import { stubTrue } from 'lodash'
import { CreateEvent } from '../modules/appEventActions'

/**
 * With Auth Component
 */

const WithAuth = <Props extends object>(
	C: ComponentClass<Props> | FunctionComponent<Props>,
	skipRedirects = false
) => {
	const AuthComponent: FC<Props> = (props) => {
		// State
		const url = useLocation();

		const { requestKey, plan_code: planCode, p_id, garmin_connect, user_id, auto_login } = useQueryParams()

		const [userInfo, setUserInfo] = useState<Partial<AuthUserInfo>>(
			CSS_DEV_MODE && !IS_PROD ? (defaultUserInfo.data as any) : {}
		)

		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

		const hasCheckedAuth = useRef(false);

		//impact click id
		const CLICK_ID = localStorage.getItem("irclickid") || undefined

		const [hasLoaded, setHasLoaded] = useState(false)
		const { setCurrentUser, hasOffer, setHasOffer, setSubscription, subscription, billingInfo, setBillingInfo, balanceInfo, setBalanceInfo, account, setAccount, setIsPastDue, setPastDueBalance } = useStore((state: ICurrentUser) => state)
		const { setIsMobile, setShowAnnouncementPopup } = useStore((state: IComponentStore) => state)

		const isMobile = localStorageRequestKey ? true : false

		// Hooks
		useAppStorage()

		// Check Auth
		const checkAuth = async () => {
			if (!CSS_DEV_MODE || (CSS_DEV_MODE && !IS_PROD)) {
				let accountInfo: Partial<AuthUserInfo> = {}
				let loginResponse: any = undefined
				let subscriptionResponse: any = undefined
				let billingResponse: any = undefined
				let balanceResponse: any = undefined
				let subscriptionIsActive = false
				let billingIsValid = false
				let hasPastSubscription = false
				let hasActiveSubscription = false
				let hasRemainingSubscription = false
				let hasPastDuePayment = false
				let isAccountDeleted = false;
				let externalSubscription = false;

				try {
					// Get Account, Subscription, and Billing Info
					const responses = await getAllAccountInfo({ account: account ? false : true, subscription: subscription?.active ? false : true, billing: billingInfo ? false : true, balance: balanceInfo ? false : true })
					// console.log('subscription: ', responses.subscription)
					loginResponse = responses.account
					subscriptionResponse = responses.subscription
					billingResponse = responses.billing
					balanceResponse = responses.balance
					const accountId = loginResponse?.data?.account_id
					const abortControllerC = new AbortController();
					let onBoardingResponse: any = ''
					if (accountId)
						onBoardingResponse = await GetOnBoarding(accountId, abortControllerC.signal)
					const obDataString = onBoardingResponse?.data?.result?.[0]?.user_progress
					const onBoardingData = obDataString ? JSON.parse(obDataString) : {}
					// Get Most Recent Active and Expired Subscription Info
					const activeSubscription = subscriptionResponse?.data?.active || subscription?.active || undefined
					const expiredSubscription = subscriptionResponse?.data?.expired || subscription?.expired || undefined
					const accessDetails = subscriptionResponse?.data?.accessDetails || subscription?.accessDetails || undefined
					setSubscription({ active: activeSubscription, expired: expiredSubscription, accessDetails, locked: accessDetails ? (accessDetails?.type === 'basic' && !accessDetails?.pro_trial) : true })
					setBillingInfo(billingResponse.data ? billingResponse.data : billingInfo ? billingInfo : undefined || undefined)
					setBalanceInfo(balanceResponse ? balanceResponse : balanceInfo ? balanceInfo : undefined || undefined)
					setAccount(loginResponse?.data ? loginResponse?.data : account ? account : undefined || undefined)

					if (!billingResponse?.data && billingInfo)
						billingResponse['data'] = billingInfo

					if (balanceInfo)
						balanceResponse = balanceInfo

					if (!subscriptionResponse?.data && subscription)
						subscriptionResponse['data'] = subscription

					if (!loginResponse?.data && account)
						loginResponse['data'] = account


					//check if there is an active external subscription
					externalSubscription = activeSubscription?.subscription_type === "apple" || activeSubscription?.subscription_type === "google" ? true : false

					isAccountDeleted = subscriptionResponse?.data?.active?.status === "deleted" || subscriptionResponse?.data?.expired?.status === "deleted"

					if (isAccountDeleted)
						signOut()

					// Check if has Active or Expired Subscriptions
					hasActiveSubscription = activeSubscription?.id ? true : false
					hasPastSubscription = expiredSubscription?.id ? true : false

					// Check if Expired Subscription has remaining days
					if (!hasActiveSubscription && hasPastSubscription) {
						const endDate = expiredSubscription?.end_date
						const curDate = new Date().toISOString()
						hasRemainingSubscription = curDate < endDate
					}

					// Check if User has pending payment
					hasPastDuePayment = balanceResponse?.data?.pastDue

					// Get Active and Expired Subscription Amounts
					const activeAmount = getCurrentAmount(activeSubscription)
					const expiredAmount = expiredSubscription?.amount || 0

					// Check if Subscription is Free
					const subscriptionIsFree =
						(hasActiveSubscription && activeAmount == 0) ||
						(activeAmount || expiredAmount) == 0

					// Get Current or Most Recent Subscripition Amount
					const subscriptionAmount = activeAmount || expiredAmount

					// Check if user has an Active Subscription
					subscriptionIsActive =
						hasActiveSubscription || hasRemainingSubscription

					// Check for valid Billing Info
					billingIsValid = billingResponse?.data?.paymentType === "coupon" || !billingResponse?.data?.isValid ? false : (requestKey || localStorageRequestKey) ? true : billingResponse?.data?.isValid || subscriptionIsFree

					// Set Account Info to return
					accountInfo = loginResponse?.data
						? {
							...loginResponse?.data,
							is_valid: billingIsValid,
							is_expiring: hasRemainingSubscription,
							is_inactive: !subscriptionIsActive,
							amount: subscriptionAmount,
							paymentType: billingResponse?.data?.paymentType,
							onBoardingData,
							plan_code: activeSubscription?.plan_code || expiredSubscription?.plan_code || planCode
						} : {}
					setCurrentUser(loginResponse?.data)
					setUserInfo(accountInfo)
				} catch (err) {
					console.log('checkAuth err: ', err)
					// Do Nothing
				}
				// Token is Invalid
				const TOKEN_IS_INVALID = accountInfo.account_id == null

				// Subscription Is Inactive
				const SUBSCRIPTION_IS_INACTIVE = !subscriptionIsActive

				// Subscription is Expired
				const SUBSCRIPTION_IS_EXPIRED =
					hasPastSubscription && SUBSCRIPTION_IS_INACTIVE

				// User Info is Invalid
				// const USER_INFO_IS_INVALID =
				// 	!accountInfo.first_name || !accountInfo.last_name

				if (!skipRedirects) {
					// Login Page
					if (TOKEN_IS_INVALID) {
						console.log("TOKEN IS INVALID")
						//check if user wants to connect garmin
						if (garmin_connect && user_id) {
							const response = await autoLogin({ user_id: Number(user_id) })
							if (response?.data?.account_id != null) {
								navReq('/#/profile?garmin_connect=true')
							}
							else
								navReq("/#/signup")
						}
						else if (auto_login && user_id) {
							const response = await autoLogin({ user_id: Number(user_id) })
							if (response?.data?.account_id != null) {
								window.location.reload()
							}
							else
								navReq("/#/signup")
						}
						else
							navReq("/#/signup")
					}

					else if (!TOKEN_IS_INVALID && garmin_connect && user_id) {
						navReq('/#/profile?garmin_connect=true')
					}

					// Expired
					else if (SUBSCRIPTION_IS_EXPIRED) {
						window.location.reload()
					}

					// Choose Plan Page
					else if (SUBSCRIPTION_IS_INACTIVE && !hasOffer.hasOffer) {
						console.log("SUBSCRIPTION INACTIVE")

						if (!p_id || accountInfo?.plan_code) navReq(urlWithOffer('/#/signup' + url.search, HAS_SALE ? 2 : undefined))
					}

					else if (SUBSCRIPTION_IS_INACTIVE && hasOffer.hasOffer) {
						console.log("SUBSCRIPTION INACTIVE 2")
						if (!p_id || accountInfo?.plan_code) navReq(urlWithOffer('/#/signup', hasOffer.offerParam === "?offer=2" ? 2 : hasOffer.offerParam === "?offer=1" ? 1 : undefined))
					}

					// Update billing info
					// hasPastDuePayment && !externalSubscription
					else if (hasPastDuePayment && !externalSubscription) {
						const activeDetails = subscriptionResponse?.data?.active || subscriptionResponse?.data?.expired || {}
						let newPlanCode = getCurrentPlanCode(activeDetails) || planCode;
						if (newPlanCode?.toLowerCase()?.includes('annual'))
							newPlanCode = planCodes.annualPro

						else if (newPlanCode?.toLowerCase()?.includes('monthly'))
							newPlanCode = planCodes.monthlyPro

						if (newPlanCode) {

							setIsPastDue(true)
							setPastDueBalance(balanceResponse?.data?.balances[0]?.amount || 0)

						} else {
							console.log("NO PLAN CODE")
							navReq('/#/')
						}
					}

					else {
						setShowAnnouncementPopup()
					}
				}
			}
			setHasLoaded(true)
			return true
		}

		// Mount
		useEffect(() => {
			const abortController = new AbortController();
			setIsMobile(isMobile)

			if (!hasCheckedAuth.current) {
				checkAuth().then(res => {
					if (res) {
						hasCheckedAuth.current = true
					}
				})
			}

			return () => {
				abortController.abort();
			}
		}, [])

		useEffect(() => {
			if (url.search === "?offer=2" || url.search === "?offer=1") {
				setHasOffer({
					offerParam: url.search,
					hasOffer: true
				})
			}
		}, [])

		useEffect(() => {
			if (requestKey) {
				localStorage.setItem("deviceRequestKey", requestKey)
			}
		}, [requestKey])

		useEffect(() => {
			if (userInfo?.account_id && CLICK_ID) {
				// console.log("Trigger Identify")
				window?.callIdentify?.(String(userInfo?.account_id), sha1(userInfo?.email))
			}
		}, [userInfo, url])

		// Update Current User Store
		useEffect(() => {
			setCurrentUser(userInfo)
		}, [userInfo])

		return <C {...props} userInfo={userInfo} hasLoaded={hasLoaded} />
	}

	return AuthComponent
}

export default WithAuth
