/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useRef, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import { backNav, navReq } from '../../../modules/apiConsume'
import JustGettingStarted from './JustGettingStarted'
import featuredPrograms from '../../../modules/programs/featured-programs.json'
import programCategories from '../../../modules/programs/program-categories.json'
import ScrollRow from '../../../components/layout/ScrollRow'
import useScrollRow from '../../../hooks/useScrollRow'
import MainCard from '../../../components/cards/MainCard'
import WithAuth from '../../../components/WithAuth'
import Motion from '../../../components/animation/Motion'
import RSProgramCard from '../../../components/cards/RSProgramCard'
import { IFeaturedProgram } from '../../../api/v2/favorites/types'
import ProgramSplash from './ProgramSplash'
import ProgramMetadata from './ProgramMetadata'
import {
	slideInRight,
	slideOutRight,
	slideInLeft,
	slideOutLeft,
	fadeIn,
} from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useQueryParams from '../../../hooks/useQueryParams'
import useProgramFavorites from '../../favorites/useProgramFavorites'
import { UserInfoProps } from '../../../modules/types'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import useSuggestedPrograms from '../../onboarding/useSuggestedPrograms'
import { IComponentStore } from '../../../store/createComponentStore'
import useStore from '../../../store/useStore'
import { ISurveyStore } from '../../../store/createSurveyStore'
import { isIOS, isAndroid } from 'react-device-detect';
import RSNavCont from '../../../components/layout/RSNavCont'
import GenericModal from '../../../components/misc/GenericModal'
import Loader from '../../../components/animation/Loader';
import { WebSocketContext } from '../../../WebsocketProvider';
import useSWR from 'swr';
import { ActiveProgram, Program } from '../../../api/v2/programs/programs.types';
import { IProgramStore } from '../../../store/createProgramStore';
import RSButton from '../../../components/buttons/RSButton';
import RSFlexCard from '../../../components/cards/RSFlexCard';
import { H3 } from '../../../components/text/RSTypography'
import { getActivePlan } from '../../../modules/programActions';

const AslideInLeft = keyframes`${slideInLeft}`
const AslideInRight = keyframes`${slideInRight}`

const AslideOutLeft = keyframes`${slideOutLeft}`
const AslideOutRight = keyframes`${slideOutRight}`
const FadeIn = keyframes`${fadeIn}`

const DivFadeIn = styled.div`
	animation: 0.2s ${FadeIn};
`
const DivInLeft = styled.div`
	animation: 0.3s ${AslideInLeft};
`
const DivInRight = styled.div`
	animation: 0.3s ${AslideInRight};
`

const DivOutLeft = styled.div`
	animation: 0.3s ${AslideOutLeft};
`
const DivOutRight = styled.div`
	animation: 0.3s ${AslideOutRight};
`

export type ProgramProps = UserInfoProps & {
	setMainLoading?: Function,
	setMainSplash?: Function
}

const Programs: FC<ProgramProps> = ({ userInfo, setMainLoading, setMainSplash }) => {

	const { program_id, process, program, videoId, tab, prev_program_id, p_id, adminAccess, obDisplay, admin, week } = useQueryParams()
	const { data: allPrograms, isLoading: isLoadingPrograms, error, mutate } = useSWR(`/programs?texts=all${adminAccess ? '&isAdmin=1' : ''}`, { revalidateOnFocus: false, revalidateOnReconnect: false })

	const { data: suggestedPrograms, isLoading: isLoadingSuggestedPrograms } = useSWR(userInfo?.account_id ? `/suggested-programs?user_id=${userInfo?.account_id}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })
	const [categories, setCategories] = useState<any[]>([])
	const [suggestedProgramIds, setSuggestedProgramIds] = useState<number[]>()

	const requestKey = localStorage.getItem('deviceRequestKey') || undefined

	const { isMobile, setIsLoading } = useStore((state: IComponentStore) => state)
	const { setProgramVideoProgress, programVideoProgress } = useStore((state: IProgramStore) => state)
	const { sendJsonMessage } = useContext(WebSocketContext);

	const appVersion = localStorage.getItem('appVersion') || undefined
	// const isMobileApp = isMobile && requestKey && (isIOS || isAndroid) ? true : false
	const isMobileApp = isMobile;


	const [selectedProgram, setSelectedProgram] = useState<
		Program | undefined
	>(undefined)
	const [startedPrograms, setStartedPrograms] = useState<any>()
	const [showSplash, setShowSplash] = useState(false)
	const [showUpdateModal, setShowUpdateModal] = useState(false)

	const [programs, setPrograms] = useState<Program[] | undefined>(
		undefined
	)
	const [isFixed, setIsFixed] = useState(false)
	const mobileScreen = useIsMobileScreen()
	const { toggleProgramFavorite, programFavorites } = useProgramFavorites(
		userInfo?.account_id as number
	)

	const [isBackToHome, setIsBackToHome] = useState(false)

	const [liked, setLiked] = useState(false)
	const [activeProgramID, setActiveProgramID] = useState<any>(null)

	const [isRedirect, setIsRedirect] = useState(false)
	const [programVideo, setProgramVideo] = useState<any>(null)
	const [currentTab, setCurrentTab] = useState<any>(null)
	const [currentWeek, setCurrentWeek] = useState<any>(null)
	const currProgram = programs?.find(x => x.program_id == activeProgramID)
	const handleLikeProgram = async (programId: number) => {
		if (userInfo?.account_id && programId) {
			const current = programFavorites.find((x) => x.program_id == programId)
			if (liked && current?.favorited != 1)
				await toggleProgramFavorite(programId, userInfo.account_id, current)
			else if (!liked)
				await toggleProgramFavorite(programId, userInfo.account_id, current)
		}
	}

	const handleIsFavoritedProgram = (programId: number) => {
		const current = programFavorites?.find((x) => x.program_id == programId)
		if (current && current.favorited == 1) return true
		else return false
	}

	const slideDirection = useRef({ slide: localStorage.getItem('page') === 'pw' ? 'right' : 'left' })
	const slideProgram = useRef({ slide: localStorage.getItem('page') === 'pw' ? 'right' : '' })

	const handleBack = () => {
		slideDirection.current.slide = 'left'
		slideProgram.current.slide = 'right'
		navReq('/#/explore')
	}

	const viewProgram = (program: Program) => {
		if (program?.program_id) {
			const isUsingMetadata = programs?.find((x) => x.metadata == 1 && x.program_id == program.program_id)
			isUsingMetadata ? setActiveProgramID(program.program_id) : navReq('/#/explore?program_id=' + program.program_id || '/#/')
		}
	}

	const handleUpdateApp = () => {
		setShowUpdateModal(false)
		if (isMobile && isIOS) {
			window.location.assign('https://apps.apple.com/app/1507476659')
		}
		else if (isMobile && isAndroid)
			window.location.assign('https://play.google.com/store/apps/details?id=com.runsmartonline');
	}

	useEffect(() => {

		if (program_id && programs && programs?.length > 0) {
			setShowSplash(true)
			setIsFixed(false)
			setSelectedProgram(
				programs?.find((x) => x.program_id == Number(program_id))
			)
			setTimeout(() => {
				setIsFixed(true)
			}, 400)
		} else {
			localStorage.setItem('page', '')
			slideDirection.current.slide = 'left'
			slideProgram.current.slide = 'right'
			setShowSplash(false)
			setSelectedProgram(undefined)
			setIsFixed(false)
		}
	}, [program_id, programs])

	useEffect(() => {
		if (allPrograms && allPrograms?.data.result.length > 0) {
			const categories: any[] = [];
			const programs: Program[] = allPrograms?.data.result
			const sortedPrograms: Program[] = []
			if (suggestedProgramIds && suggestedProgramIds.length > 0) {
				suggestedProgramIds.forEach((progId) => {
					const newProg = programs.find((x) => x.program_id == progId)
					if (newProg) sortedPrograms.push(newProg)
				})
			}

			programs.forEach((program) => {
				const notInSuggested = !suggestedProgramIds?.includes(program.program_id)
				if (notInSuggested) sortedPrograms.push(program)
			})

			const progress: any = []
			programs.forEach(program => {
				if (program?.progress && program?.progress.length > 0)
					progress.push({ programId: program.program_id, progress: program?.progress })

				if (!categories.find(x => x.category === program.category))
					categories.push({ order: program.category_order, category: program.category })
			});
			categories.sort((a, b) => a.order - b.order)
			setProgramVideoProgress(progress)
			setCategories(categories)
			setPrograms(sortedPrograms.length > 0 ? sortedPrograms : programs)
		}
	}, [allPrograms, isLoadingPrograms, suggestedProgramIds])

	useEffect(() => {
		checkActivePrograms(userInfo?.account_id)

		sendJsonMessage && sendJsonMessage({
			requestType: 'show-status-bar',
			requestKey: requestKey,
			payload: {},
			timestamp: new Date().getTime()
		})
	}, [])

	useEffect(() => {
		checkActivePrograms(userInfo?.account_id)
	}, [userInfo?.account_id, currProgram])

	useEffect(() => {
		const program_ids: number[] = []
		if (suggestedPrograms?.data?.result && suggestedPrograms?.data?.result?.length > 0) {
			suggestedPrograms?.data?.result.forEach((x: any) => program_ids.push(x.program_id))
			const uniqueValues = program_ids.filter((a, i) => program_ids.findIndex((s) => a === s) === i)
			setSuggestedProgramIds(uniqueValues)
		}
	}, [suggestedPrograms])

	useEffect(() => {
		if (program && videoId && programs && programs?.length > 0) {
			const p = programs?.find(x => x.program_title.toLowerCase().includes(program) || x?.url?.includes(program))
			if (p && p.program_id) {
				setIsRedirect(true)
				setProgramVideo(videoId)
				setActiveProgramID(p.program_id)
				window.location.assign('/#/explore')
			}
		}
	}, [program, videoId, programs])

	useEffect(() => {
		if (p_id && programs && programs?.length > 0) {
			const fd = programs?.find((x) => x.program_id == Number(p_id))
			fd && viewProgram(fd)
		}
	}, [p_id, programs])

	useEffect(() => {
		if ((prev_program_id) && tab || week) {
			setTimeout(() => {
				setActiveProgramID(Number(prev_program_id))
				setIsRedirect(true)
				setCurrentTab(Number(tab))
				setCurrentWeek(Number(week))
				window.location.assign('/#/explore')
			}, 1)
		}
	}, [prev_program_id, tab, p_id, week])

	useEffect(() => {
		if (p_id && videoId && tab && programs && programs?.length > 0) {
			setTimeout(() => {
				setActiveProgramID(Number(p_id))
				setProgramVideo(videoId)
				setCurrentTab(Number(tab))
				setIsBackToHome(true)
				window.location.assign('/#/explore')
			}, 100)
		}
	}, [p_id, videoId, tab, programs])

	useEffect(() => {
		setMainLoading?.(isLoadingPrograms || !startedPrograms)
	}, [isLoadingPrograms, startedPrograms])

	useEffect(() => {
		setShowSplash?.(showSplash)
	}, [showSplash])

	const ProgramContainer = () => {
		const { isScrolling, setIsScrolling } = useScrollRow()
		return (
			<Box sx={{ px: 4 }}>
				<Row className="mt-4 programs-list">
					{categories?.map((pc, index) => (
						<div key={index}>
							<H3 className="mx-n3" size={3} sx={{pb: 1}}>{pc.category}</H3>
							<div className="mx-n3 mt-n2 mt-md-0">
								<div className="d-block d-md-none">

									<Grid container xs={12} sx={{ mr: '-9px', mb: '2.4rem' }} >
										<RSFlexCard className='transition-height' videoList={renderProgramsPerCategoryList(pc)} sx={{ py: 0, pr: 3 }} />
									</Grid>

								</div>

								<div className="d-none d-md-block">
									<Grid container xs={12} sx={{ mr: '-9px', mb: '2.4rem' }} >
										<RSFlexCard className='transition-height' videoList={renderProgramsPerCategoryList(pc)} sx={{ pt: 0, pb: '1rem', my: 0, overflow: 'hidden' }} />
									</Grid>
								</div>

								{/*<div className="d-none d-md-block">
									<div className="mx-n3 mt-n2 mt-md-0">
										<ScrollRow setIsScrolling={setIsScrolling}>
											{programs?.filter((x) => x.category === pc.category && x?.program_id != 6).map((program, key) => (
												<Button onClick={() => viewProgram(program)} disabled={isScrolling} key={key} sx={{ textAlign: 'left', textTransform: 'none', color: 'black !important' }}>
													<MainCard
														videoId={program?.program_id as unknown as string}
														isInline
														type="videoLg"
														bgImage={program?.image?.replace(
															'.jpg',
															'_400px.jpg'
														)}
														detailTitle={program?.program_title}
														isScrolling={isScrolling}
													/>
												</Button>
											))}
										</ScrollRow>
									</div>
								</div>*/}

							</div>
						</div>
					))}
				</Row>
			</Box>
		)
	}

	const renderProgramsPerCategoryList = (pc: { category: string | undefined; }) => {

		return programs ? programs.filter(x => x.program_id != 1).filter((x) => x?.category === pc.category).sort((a, b) => (a.sequence ? a?.sequence : 0) - (b.sequence ? b?.sequence : 0)).map((program, key) => {
			let highlightProgram = false
			if (startedPrograms?.includes(program.program_id)) highlightProgram = true;
			// console.log('program', program)
			return <RSProgramCard
				key={key + '-plan'}
				title={program?.program_title}
				estimate={program?.estimate}
				thumbnail={program?.logo}
				color={program?.bg_color}
				onClick={() => viewProgram(program)}
			/>
//			return <>
//				<RSButton card secondary={!highlightProgram} checked={highlightProgram} onClick={() => { viewProgram(program) }}
//					key={key + '-plan'} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', width: '94%', ml: '-5px', justifyContent: 'start', alignItems: 'flex-start' }}>
//
//					<img src={program?.banner ? program?.banner : program?.image} style={{ position: 'absolute', margin: 'auto', backgroundPosition: 'center center', objectFit: 'cover', backgroundSize: 'cover', height: '100%' }} />
//
//					<Grid sx={{ position: 'absolute', width: '150px', marginLeft: '1.3rem', marginTop: '0.4rem' }}>
//
//						{program?.logo ? <img src={program?.logo} style={{ paddingLeft: '-2rem' }} />
//							: <p style={{ fontSize: '18px', color: '#fff', paddingTop: '20px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{program?.program_title}</p>}
//
//					</Grid>
//
//				</RSButton>
//			</>
		}) : []
	}

	const checkActivePrograms = async (accountId?: number) => {
		if (accountId) {
			const activeProgramsData = await getActivePlan(accountId)
			const activePlanList: ActiveProgram[] = activeProgramsData.data.result
			const activeProgramIds = []

			for (const plan of activePlanList) {
				activeProgramIds.push(plan.program_id)
			}

			setStartedPrograms(activeProgramIds)
		}
	}


	return (
		<>
			<GenericModal
				type='update-app'
				show={showUpdateModal}
				title="Update RunSmart"
				text={'A new version of the app is now available for download.'}
				setShow={setShowUpdateModal}
				keyboard={false}
				backdrop="static"
				button1Action={handleUpdateApp}
				button1Text={'Update'}
			/>

			<ProgramMetadata currentProgram={currProgram} userInfo={userInfo} programID={activeProgramID} setProgramID={setActiveProgramID} videoId={programVideo} isRedirect={isRedirect} setIsRedirect={setIsRedirect} currentTab={currentTab} setCurrentTab={setCurrentTab} backToHome={isBackToHome} currentWeek={currentWeek} />
			{!currProgram ? <>
				{<Motion
					isVisible={showSplash}
					isMobile={mobileScreen}
					direction={slideDirection.current}
					clear={true}
					className='h-100'
				>
					{/* <></> */}
					<ProgramSplash
						isFixed={isFixed}
						handleIsFavoritedProgram={handleIsFavoritedProgram}
						handleLikeProgram={handleLikeProgram}
						handleOnBack={handleBack}
						SelectedProgram={selectedProgram as Program}
					/>
				</Motion>}

				<Motion
					isVisible={!showSplash}
					isMobile={mobileScreen}
					direction={slideProgram.current}
					className='h-100'
				>
					<ProgramContainer />
				</Motion>
			</> : <></>}
		</>
	)
}

export default Programs
