/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCheckoutPricing, useRecurly } from '@recurly/react-recurly';
import React, { FC, useEffect, useMemo, useState } from 'react'
import AppleIcon from '@mui/icons-material/Apple';
import { PrimaryButton } from './AuthSubmitButtons';
import { createPurchase, createSubscription, destroySession, getAllAccountInfo, getSubscriptionById, getSubscriptionInfo, getSubscriptionInfoWithError, logIn, updateBillingInfo } from '../../modules/accountActions';
import { navReq } from '../../modules/apiConsume'
import { titleCase } from '../../modules/miscUtils'
import useStore from '../../store/useStore';
import { IComponentStore } from '../../store/createComponentStore';
import useAppStorage from '../../hooks/useAppStorage';
import useLocalStorage from '../../hooks/useLocalStorage';
import { AUTH_COOKIE } from '../../modules/cliEnvValues';
import secureLocalStorage from "react-secure-storage";
import { RouletteSpinnerOverlay } from 'react-spinner-overlay';
import { RSButton1 } from '.';

interface IAppleButton {
    planCode: string,
    tranxType: 'renew' | 'update' | 'create' | 'reactivate' | "update-renew" | "create-signup",
    afterBillingProcess?: () => any
    afterBillingChange?: () => any
    handleContinue?: () => any
    isAppleSignup?: boolean,
    sx?: object
    costs?: number
    setIsApplePaySupport?: any
    // setIsApplePaySupport?: any
}

const ApplePayButton: FC<IAppleButton> = ({ planCode = 'runsmart-monthly', tranxType, afterBillingChange, afterBillingProcess, handleContinue, setIsApplePaySupport, sx = {}, costs }) => {
    // const appleUser = localStorage.getItem('rs_login_type') === "apple"
    const recurly = useRecurly()
    const [textError, setTextError] = useState<any | undefined>()
    const [pricing, setPricing] = useState<any | undefined>()
    const [showPrompt, setShowPrompt] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")
    const [title, setTitle] = useState("")
    const [afterAction, setAfterAction] = useState<any>()

    const { setIsLoading, setIsSuccess, isLoading, isSuccess, setToast } = useStore((state: IComponentStore) => state)

    const checkoutPricing = recurly.Pricing.Checkout();
    const subscription = recurly.Pricing.Subscription();

    const getPlanLabelByCode = (planCode: string, hasTrial: boolean) => {
        const trialText = hasTrial ? " (14-Day free trial)" : ""
        return titleCase(planCode) + trialText;
    }

    const hasPlanTrial = (planCode: string) => {
        return planCode === "runsmart-annual" || planCode === "runsmart-monthly" || planCode === "runsmart-annual-discount" ? true : false
    }

    const applePay = useMemo(() =>
        recurly.ApplePay({
            country: "US",
            currency: "USD",
            label: tranxType === "update" ? "RunSmart Update Billing" : titleCase(planCode),
            total: costs && tranxType !== "update" ? costs.toFixed(2) : "0.00",
            pricing: tranxType === "update" ? undefined : pricing
        }), [recurly, planCode]
    )

    const handleApplePayClick = () => applePay?.ready(function () {
        applePay.begin();
    });

    const errorMsg = () => {
        setTitle("Error: Create Subscription")
        setMessage(`Could not ${tranxType === "update" ? 'update' : 'create'
            } subscription.`)
        setVariant("danger")
        setHasError(true)
        setShowPrompt(true)
        return false
    }
    const successMessage = (amount: any, nextDate: string) => {
        setTitle("Success: Create Subscription")
        setMessage(`Your subscription has been ${tranxType === "update" ? 'updated' : 'created'
            }. You will be charged $${amount} on ${nextDate}.`)
        setVariant("success")
        setHasError(false)
        setShowPrompt(true)
    }

    const processCreateSubscription = async (token: any) => {
        try {
            const billingResult = await updateBillingInfo(token.id)
            if (billingResult?.data?.id) {
                // After Billing Update
                if (afterBillingChange) await afterBillingChange()
                const { isActive, subscription } = await getSubscriptionInfo()
                if (isActive && subscription?.id) {
                    setIsLoading(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false)
                        setTimeout(async () => {
                            if (afterBillingProcess) await afterBillingProcess()
                        }, 500)
                    }, 3000)
                }
            }
            else {
                setIsLoading(false)
                return errorMsg()
            }
        }
        catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    }

    const processUpdateBilling = async (token: any) => {
        try {
            const billingResult = await updateBillingInfo(token.id)
            if (billingResult?.data?.id && billingResult?.data?.valid) {
                const { isActive, subscription } = await getSubscriptionInfo()
                if (isActive && subscription?.id) {

                    setIsLoading(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false)
                        setTimeout(async () => {
                            navReq("/#/")
                        }, 500)
                    }, 3000)
                }
            } else {
                setHasError(true)
                setIsLoading(false)
            }
        }
        catch (err) {
            setHasError(true)
            setIsLoading(false)
        }

    }

    useEffect(() => {

        if (textError) return
        applePay.on('error', function (err: any) {
            console.log("Apple Pay Error", err)
            setIsApplePaySupport(false)
            setTextError(err)
        });

    }, [textError])

    useMemo(() => {
        if (applePay) {
            applePay.on("ready", () => {
                setIsApplePaySupport(true)
            });

            applePay.on('token', async function (token: any) {
                setIsLoading(true)
                try {
                    if (!token) {
                        setIsLoading(false)
                        return errorMsg()
                    } else {
                        if (tranxType === "create" || tranxType === "renew" || tranxType === "create-signup")
                            processCreateSubscription(token)
                        else if (tranxType === "update")
                            processUpdateBilling(token)
                    }
                } catch (err) {
                    setIsLoading(false)
                    return errorMsg()
                }
            });

        }
    }, [applePay])

    useEffect(() => {
        setPricing(checkoutPricing
            .currency("USD")
            .subscription(subscription.plan(planCode, { quantity: 1 }).currency("USD").done())
            .catch((err) => { alert(err) })
            .done())
    }, [planCode])

    useEffect(() => {
        return () => {
            console.log("Destroying Recurly Session")
            recurly.off()
            subscription.off()
            checkoutPricing.off()
        }
    }, [])

    useEffect(() => {
        setToast({
            show: showPrompt,
            title: title,
            message: message,
            variant: variant,
            onClose: () => {
                setShowPrompt(false)
            },
            duration: 3000
        })
    }, [showPrompt])

    return (
        <>
            {!textError ?
                (
                    <RSButton1 sx={{ backgroundColor: "#FFF !important", width: 80, border: "1px solid #777 !important", mr: 0.8 }} onClick={handleApplePayClick}>
                        <AppleIcon />
                        <span>Pay</span>
                    </RSButton1>
                ) : undefined
            }
        </>

    )
}

export default ApplePayButton
