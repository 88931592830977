//import Box from "@mui/material/Box"
//import RSButton from "../../components/buttons/RSButton"
import Grid from "@mui/material/Grid"
import RSFlexCard from "../../components/cards/RSFlexCard"

import { useNavigate } from 'react-router-dom'
import useSWR from "swr"
import { FC, useEffect, useState } from "react"
import { getActivePlan, saveSuggestedPrograms } from "../../modules/programActions"
import { ActiveProgram, Program } from "../../api/v2/programs/programs.types"
import useQueryParams from "../../hooks/useQueryParams"
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import ProgramMetadata from "./v2/ProgramMetadata"
import { AuthPageProps } from "../../modules/types"
import WithAuth from "../../components/WithAuth"
import { H1, SH2, P1 } from '../../components/text/RSTypography'
import RSProgramCard from '../../components/cards/RSProgramCard'
import { RSButton1, RSButton2 } from '../../components/buttons'
import suggestedExercisesList from '../../pages/onboarding/suggested-exercises.json'
import lodash from "lodash"
import useFavorites from "../favorites/useFavorites"
import useStore from "../../store/useStore"
import { ITrainingPlanStore } from "../../store/createTrainingPlanStore"

export type SuggestedProgramsProps = {
    done?: any,
    bodyData?: any,
    setBodyData?: any,
    nextAvailability?: any
    setAllowSkip?: any
}

const SuggestedPrograms: FC<SuggestedProgramsProps & AuthPageProps> = ({ bodyData, nextAvailability, userInfo, setAllowSkip }) => {
    const isMobile = useIsMobileScreen();
    const navigate = useNavigate()
    const { obDisplay } = useQueryParams()
    const { setIsOnboardingSelected } = useStore((state: ITrainingPlanStore) => state)

    const [textQuery, setTextQuery] = useState<string>()
    const [suggestedProgramsList, setSuggestedProgramsList] = useState<any[]>([])
    const [selectedPrograms, setSelectedPrograms] = useState<any>([])
    const [obDisplayPrograms, setobDisplayPrograms] = useState<number>(parseInt(obDisplay) || 4)
    const [currentProgram, setCurrentProgram] = useState<Program>()

    const [isSavingSuggested, setIsSavingSuggested] = useState(false)

    const { data: programs, isLoading, error } = useSWR(textQuery ? `/program-embeddings/search?searchQuery=${textQuery}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })


    const twoPerRow = window.innerWidth > 799 ? 2 : 1
    const containerHeight = (Math.round(obDisplayPrograms / twoPerRow)) * 96

    const { toggleFavorite: toggleFavoriteExercises } = useFavorites(userInfo?.account_id as number, undefined, "exercises", true)

    const processSaveSuggestedPrograms = async (userInfo: any, programs: Array<number>) => {
        if (programs && programs?.length > 0 && userInfo?.account_id) {
            await saveSuggestedPrograms(userInfo?.account_id, programs)
        }
    }

    useEffect(() => {
        constructTextQuery()
        checkActivePrograms(bodyData?.id)

        if (userInfo && userInfo?.account_id)
            getSuggestetExercises()

    }, [bodyData, userInfo])

    useEffect(() => {
        if (selectedPrograms && selectedPrograms.length > 0) {
            nextAvailability(true)
            // setAllowSkip && setAllowSkip(false)
        }
        else {
            nextAvailability(false)
            setAllowSkip && setAllowSkip(true)
        }
    }, [selectedPrograms])

    useEffect(() => {
        return () => {
            setAllowSkip && setAllowSkip(false)
        }
    }, [])

    useEffect(() => {
        if (selectedPrograms && selectedPrograms.includes(5)) setIsOnboardingSelected(true)
    }, [selectedPrograms])

    useEffect(() => {
        const selectedProgs: any = []
        const notSelectedPrograms: any = []
        if (programs?.data?.result && programs?.data?.result.length > 0 && !isLoading && !error) {
            programs?.data?.result?.forEach((prog: any) => {
            		if (prog?.program?.program_id == 19 ||  prog?.program?.program_id == 5) return
                if (selectedPrograms.includes(prog.program.program_id))
                    selectedProgs.push(prog)
                else
                    notSelectedPrograms.push(prog)
            })
            const newProgramsList = [...selectedProgs.sort((a: any, b: any) => b.similarity - a.similarity), ...notSelectedPrograms.sort((a: any, b: any) => b.similarity - a.similarity)]
						setSuggestedProgramsList(newProgramsList)
        }
    }, [selectedPrograms, isLoading, programs, error])


    useEffect(() => {
        if (programs?.data?.result && programs?.data?.result.length > 0 && !isLoading && !error) {
            setIsSavingSuggested(true)
            const programsToSave = programs?.data?.result.map((x: any) => x.program.program_id)
            processSaveSuggestedPrograms(userInfo, programsToSave).then(() => {
                setIsSavingSuggested(false)
            }).catch(() => {
                setIsSavingSuggested(false)
            })
        }
    }, [programs, userInfo, isLoading])


    useEffect(() => {
        if (obDisplay) setobDisplayPrograms(parseInt(obDisplay))
    }, [obDisplayPrograms])

    const checkActivePrograms = async (accountId?: number) => {
        if (accountId) {
            const activeProgramsData = await getActivePlan(accountId)
            const activePlanList: ActiveProgram[] = activeProgramsData.data.result
            const activeProgramIds = []

            for (const plan of activePlanList) {
                activeProgramIds.push(plan.program_id)
            }

            setSelectedPrograms(activeProgramIds)
        }
    }

    const getSuggestetExercises = async () => {
        let exercisesIds: number[] = []
        const ansArray = bodyData?.problemAreas.map((problem: any) => problem.index + 1)
        const ansLength = ansArray?.length
        if (ansLength) {
            ansArray.forEach((answer: number) => {
                suggestedExercisesList?.find((x: any) => x.answer == answer)?.exercises.forEach((id: number) => {
                    exercisesIds.push(id)
                })
            })
        }
        exercisesIds = lodash.uniq(exercisesIds)

        for (const id of exercisesIds) {
            await toggleFavoriteExercises(userInfo?.account_id as number, Number(id), true)
        }
    }

    const constructTextQuery = () => {

        if (bodyData == undefined || bodyData == '') return

        let textQuery = ''

        if (bodyData?.goals?.length) {
            bodyData.goals.map((goal: any) => {
                textQuery += 'My goal is to ' + goal.title.details + '. '
            })
        }

        if (bodyData?.runningActivity) {
            textQuery += 'I usually run ' + bodyData.runningActivity.titleKM + '. '
        }

        if (bodyData?.physicalActivity) {
            textQuery += 'I have ' + bodyData.physicalActivity.title + '. '
        }

        if (bodyData?.problemAreas?.length) {
            bodyData.problemAreas.map((problem: any) => {
                textQuery += 'I have a problem in my ' + problem.title + '. '
            })
        }

        if (bodyData?.runningStyle) {
            textQuery += 'My running style is ' + bodyData.runningStyle.title + '. '
        }

        if (bodyData?.dietaryPreferences) {
            textQuery += 'I prefer my diet to be ' + bodyData.dietaryPreferences + '. '
        }

        setTextQuery(textQuery)

    }

    const renderSuggestedProgramsList = () => {
        return suggestedProgramsList?.slice(0, isMobile ? suggestedProgramsList.length : obDisplayPrograms)?.filter(plan => plan?.program?.metadata).map((plan, planIndex: number) => {
            let highlightProgram = false
            if (selectedPrograms.includes(plan.program.program_id)) highlightProgram = true;
            console.log('plan', plan)
            return <RSProgramCard
                key={planIndex + '-plan'}
                title={plan.program?.program_title}
                estimate={plan.program?.estimate}
                category={plan.program?.category}
                thumbnail={plan.program?.logo}
                color={plan.program?.bg_color}
                onClick={() => {
                    setCurrentProgram(undefined)
                    setCurrentProgram(plan.program)
                }}
                disabled={isLoading || isSavingSuggested}
                checked={highlightProgram}
            />
        })
    }


    return (
        <>
            <H1 size={5} sx={{ pb: 2, textAlign: 'left' }}>
                Add Programs to Your Routine.
            </H1>
            <P1 sx={{ pb: 1, width: '100%' }}>
                {`Based on your running history and goals, here's what we recommend`}
            </P1>
            <SH2 sx={{ pb: 2, width: '100%' }}>
                Add at least one program to get started
            </SH2>

            <Grid container xs={12} >
                {!isLoading ? <RSFlexCard className='transition-height' videoList={renderSuggestedProgramsList()} sx={{ pt: 0, pb: '5px', my: 0, height: isMobile ? `${containerHeight}px` : '100%', overflow: 'hidden' }} />
                    : <>
                    <RSButton2 borderless secondary={false} className={`${isLoading ? 'loading-days toRight' : ''}`} sx={{ maxWidth: '450px', height: '80px', my: 2, overflow: 'hidden', justifyContent: 'center', alignItems: 'center'}}/>
                    <RSButton2 borderless secondary={false} className={`${isLoading ? 'loading-days toRight' : ''}`} sx={{ maxWidth: '450px', height: '80px', mb: 2, overflow: 'hidden', justifyContent: 'center', alignItems: 'center'}}/>
                    <RSButton2 borderless secondary={false} className={`${isLoading ? 'loading-days toRight' : ''}`} sx={{ maxWidth: '450px', height: '80px', mb: 2, overflow: 'hidden', justifyContent: 'center', alignItems: 'center'}}/>
                    <RSButton2 borderless secondary={false} className={`${isLoading ? 'loading-days toRight' : ''}`} sx={{ maxWidth: '450px', height: '80px', mb: 2, overflow: 'hidden', justifyContent: 'center', alignItems: 'center'}}/>
                    </> }
            </Grid>

            <Grid container xs={12} sx={{ pb: 1 }}>
                {suggestedProgramsList?.length !== obDisplayPrograms && <RSButton1 tertiary sx={{ mt: 2, width: 'auto', p: '2px 5px' }} onClick={() => {
                    const moreObDP = obDisplayPrograms + 3
                    const newObDisplay = moreObDP > suggestedProgramsList?.length ? suggestedProgramsList.length : moreObDP
                    setobDisplayPrograms(newObDisplay)
                    navigate('?obDisplay=' + (newObDisplay))
                }}>
                    View More
                    <i className="fa-regular fa-angle-down" style={{ fontSize: '20px', marginLeft: '5px' }} />
                </RSButton1>}
            </Grid>

            <ProgramMetadata onboarding={currentProgram?.program_id || !userInfo?.plan_code} currentProgram={currentProgram} userInfo={userInfo} programID={currentProgram?.program_id} isRedirect={true} setIsRedirect={() => null} currentTab={0} setCurrentTab={() => null} setProgramID={() => {
                setCurrentProgram(undefined)
                checkActivePrograms(userInfo?.account_id)
            }} />
        </>
    )
}

export default WithAuth(SuggestedPrograms)
