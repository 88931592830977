// Local Storage Key
export const LOCAL_STORAGE_KEY = 'rs-user-info'

// Auth Cookie
export const AUTH_COOKIE = 'rs-recurly-auth'

// Continue Training Card
export const continueTrainingCard = {
	logoType: 'training',
	bgImage: '',
	link: '/#/training-plan/view',
	text: 'Every Finish Line Starts with a Plan\nBuild consistency, maintain fitness, ease back into running, or gear up for your next PR.\nLet’s create a personalized plan to get you there',
	altText: 'Continue your training plan',
}

// Facebook Card
// export const facebookCard = {
// 	logoType: 'reset',
// 	bgImage: '',
// 	text: `30-Day Challenge`,
// 	link: '/#/runsmart-reset/info?start=1',
// 	title: " RunSmart Reset",
// 	otherInfo: "Learn More",
// 	hasInfo: true
// }

export const facebookCard = {
	logoType: 'recover',
	bgImage: '',
	text: `Post-Race Plan`,
	// link: '/#/programs/midfoot-project',
	link: '/#/programs?program_id=19',
	directLink: '/#/programs/runsmart-recover',
	title: "Recover",
	otherInfo: "",
	hasInfo: true
}

// Guide Text
export const guideText = {
	Strength:
		"The RunSmart Strength Guide is everything runners need to run stronger and be less prone to injury. Whether you're just starting out or a seasoned runner, this guide will help you get stronger.",
	Training:
		"Whether you're looking to get back in shape, train for a race, or just maintain your running, this guide is perfect for you. Learn what you can do every step of the way to minimize your risk for injury and get faster!",
	CreateNewWarning:
		"If you build a new training plan you will lose your current plan, including all notes.",
	LateStartWarning:
		"Your plan is shorter than our recommended minimum for safe and effective training. Please consider extending your training period or manually adapting your plan to reduce injury risk and improve performance.",
	// "Your chosen race date is too close for our training plans. We will still build your plan, but it will begin from the middle. It’s not recommended unless you have an already established base.",
	SelectRaceDate1:
		"Training plans for 5K and 10K races are 10-weeks long and require a Saturday or Sunday race day. If a day is not available it’s because it does not meet these criteria.",
	SelectRaceDate2:
		"Our plans are built for 14, 16, and 18 weeks. If a calendar day is not available below, it’s because race day is too close.",
	WeatherWarning: "Due to current weather conditions and your WTA Score, we suggest you avoid all hard running or perform the given run inside on a treadmill."
}

// Guide Headline
export const guideHeadline =
	'A path to reaching your goals is ready. Below are videos chosen just for you, as well as a download to get you started.'

// Guide Documents
export const guideDocuments = {
	'5K 10K': '/files/Success-Path-5k10k.pdf',
	'Half Marathon': '/files/Success-Path-Half-Marathon.pdf',
	Marathon: '/files/Success-Path-Marathon.pdf',
	Strength: '/files/RunSmart-Strength-Guide.pdf',
	'RunSmart Reset': '/files/RunSmart-Reset.pdf'
}

// About Links
export const aboutLinks = {
	terms: 'https://runsmartonline.com/terms-of-use',
	privacy: 'https://runsmartonline.com/privacy-policy',
	waiver: 'https://runsmartonline.com/waiver-of-liability',
}

// Download Guide Text
export const DOWNLOAD_GUIDE_TEXT =
	'Download your guide or send to your email below'

// Cancel Step 1 Text
export const CANCEL_STEP_1_TEXT = {
	title: 'Wait! Have you made the most of RunSmart Pro?',
	info: `
	We don't want you to go. RunSmart was created for runners like you to increase strength and speed
	for a lifetime of happy running. Have you made the most of your membership?
	`,
	trainingTitle: 'I am training for a race.',
	trainingInfo: `From 5K to the Marathon, build a custom training plan in minutes. Get all the training hacks Steve has discovered over the last decade as a Physical Therapist and 6X Boston Qualifier.`,
	trainingButton: 'Build a Training Plan',
	strengthTitle: "I don't know where to start strength training.",
	strengthInfo: `Strength training is the key to any successful plan. Start a strength program that targets the exact muscles you need to run stronger without injury.`,
	strengthButton: 'Go to Strength Guide',
	injuryTitle: "I'm injured.",
	injuryInfo: `Injuries happen. Our founder Steve Gonser has a simple system you can follow to not only beat any injury, but to do so before they force you to miss serious training.`,
	injuryButton: 'Go to Injury Guide',
}

// Cancel Step 2 Text
export const CANCEL_FINAL_STEP = {
	title: "Final Step",
	info: `We are constantly launching new features and improvements. What best describes why you are downgrading your subscription?`,
	tagline: 'Which of these describes your experience?',
	options: {
		hard: 'Exercises are too hard for me',
		injury: "I'm injured and I can't run right now",
		technical: 'Technical Issue',
		expensive: 'Too Expensive',
		other: 'Other (please specify)',
	},
}

// Cancel Step 2 Text
export const CANCEL_STEP_2_TEXT = {
	title: "We're sorry you're thinking of leaving.",
	info: `
	Cancelling today means you'll no longer be able to access your training plan, the Run Group, or RunSmart Strength Training.`,
	tagline: 'Which of these describes your experience?',
	options: {
		hard: 'Exercises are too hard for me',
		injury: "I'm injured and I can't run right now",
		technical: 'Technical Issue',
		expensive: 'Too Expensive',
		other: 'Other (please specify)',
	},
}

// Cancel Step 3 Text
export const CANCEL_STEP_3_TEXT = {
	title: 'Can we help you change some part of your current plan?',
	tagline: 'before you decide to cancel, consider these offers:',
}

// Join Reasons
export const JOIN_REASONS = {
	strength: 8,
	form: 9,
	injury: 10,
	race: 11,
}

// Get Started Image
export const GET_STARTED_IMG = '/img-new/get-started/cover-image.jpg'

// Webinar Downloads
export const WEBINAR_DOWNLOADS = {
	'60 Second Core': '/files/60SecondCore-Handbook.pdf',
	'Injury Management': '/files/4-steps-beating-injury.pdf',
	'Peak on Race Day': '/files/Training to Peak on Race Day.pdf',
	'Fast Over 40': '/files/Master Running - Fast Over 40.pdf',
	'Run Through Fatigue': '',
	'RunSmart Protocol': '/files/RunSmart-Protocol.pdf',
	'Foam Rolling': '',
}

// Cancel Images
export const CANCEL_IMAGES = {
	injury: '/img/cancel/cancel-injury.jpg',
	strength: '/img/cancel/cancel-strength.jpg',
	training: '/img/cancel/cancel-training.jpg',
}

// Cancel Storage Key
export const CANCEL_STORAGE_KEY = 'rs-cancel-reason'

// App Storage Key
export const APP_STORAGE_KEY = 'rs-app'

// Default Costs
export const DEFAULT_COSTS = {
	monthly: 15,
	annual: 119,
	annualFlash: 99,
	monthlyPro: 15,
	annualPro: 119,
	monthlyCode: 'runsmart-monthly',
	annualCode: 'runsmart-annual',
	annualFlashCode: 'runsmart-annual-flash',
	basic: 0,
	basicCode: 'runsmart-basic',
	annualProCode: 'pro-annual',
	monthlyProCode: 'pro-monthly',
}

// Default Plan Cost Args
export const defaultPlanCostArgs = {
	hasOffer: false,
	isRenew: false,
	isYearlyRenewOnly: false,
	hasSale: false,
}

export const strengthGuideTexts = {
	headlines: ['Using the Strength Training Guide'],
	descriptions: ["The RunSmart Strength Guide is everything runners need to run stronger and be less prone to injury. Whether you’re just starting out, or a seasoned runner, this guide will help you get stronger. \r\n\r\nThe Strength Guide is your road map to RunSmart Strength Programs. Take the assessments to find your perfect starting place!"]
}

// export const strengthGuideTexts = {
// 	headlines: ['Using the Strength Training Guide'],
// 	descriptions: ["The RunSmart strength guide provides you with a step-by-step system for improving your strength and fixing imbalances for injury-free running. \r\n\r\nTo get started, download or email yourself the guide. Then, pick your starting point from page two and begin working through the checklist for the exercises, programs,and training."]
// }

export const DEFAULT_RACE_TYPES = ['5k', '8k', '10k', '10 Mile', 'Half', 'Full']
export const DEFAULT_LOCATION_TYPES = ['country', 'city', 'zipcode', 'state']
export const DISABLED_RACE_TYPES = ['5K', '10K']
