/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from "@mui/material";
import { Box } from "@mui/material"
import { useRecurly } from "@recurly/react-recurly";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { RSButton1 } from "../../components/buttons";
import RSDrawer from "../../components/layout/RSDrawer";
import RSCardCont from "../../components/cards/RSCardCont";
import CustomAccordion from "../../components/misc/CustomAccordion"
import { SH2, SH3, P1, P2, P3, A } from "../../components/text/RSTypography"
import useAlert from "../../hooks/useAlert";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { createSubscription, getPlanCosts, getSubscriptionInfo, updateBillingInfo } from "../../modules/accountActions";
import { navReq, postReq } from "../../modules/apiConsume";
import { CreateShareTransaction } from "../../modules/shareActions";
import { DEFAULT_COSTS } from "../../modules/values";
import { IComponentStore } from "../../store/createComponentStore";
import useStore from "../../store/useStore";
import PaymentDetails from "./checkout/PaymentDetails";
import ReactPixel from 'react-facebook-pixel';
import useSale from "../../hooks/useSale";
import useQueryParams from "../../hooks/useQueryParams";
import useRNBridge from "../../hooks/useRNBridge";
import RSHeaderCont from "../../components/layout/RSHeaderCont";
import useIsMobileScreen from "../../hooks/useIsMobileScreen";
import ApplePayButton from "../../components/buttons/ApplePayButton";
import GooglePayButton from "../../components/buttons/GooglePayButton";
import moment from "moment";
import { ICurrentUser } from "../../store/createAccountStore";

// import useIsMobileScreen from "../../hooks/useIsMobileScreen";

const BASIC_FEATURES = [
    "Personanlized run plan with distances and run types (limited creation).",
    "12 runner-specific strength and mobility training",
    "Access 3 run form training programs.",
    "RunSmart Protocol to stop, fix, and prevent injuries.",
    "Introduction to RunSmart Fuel to fuel your running and life."
];

const PRO_FEATURES = [
    "Personanlized run plan with distances, run types, connect to watch, adjustments, and more.",
    "All runner-specific strength and mobility training",
    "All run form training programs.",
    "RunSmart Protocol to stop, fix, and prevent injuries.",
    "RunSmart Fuel to fuel your running and life.",
    "And more!",
];

const UpgradePlan = (props: any) => {
    const { userInfo, openPaymentDetails, setOpenPaymentDetails, setOpenUpgradePro, openUpgradePro, isUpdatePaymentInfo } = props
    const [openChoosePlan, setOpenChoosePlan] = useState(false)
    // const [openPaymentDetails, setOpenPaymentDetails] = useState(false)

    const [selectedPlan, setSelectedPlan] = useState('yearly')
    const [isValidCard, setIsValidCard] = useState(false)
    const [costs, setCosts] = useState({ ...DEFAULT_COSTS })
    const [monthly, setMonthly] = useState(false)
    const [isNativeMobile, setIsNativeMobile] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [postalCode, setPostalCode] = useState("")

    const [hasSale, setHasSale] = useState(false)

    const { requestKey } = useQueryParams()

    const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || requestKey || undefined
    const { mobileType } = useDeviceDetect()

    const { activeSale, isLoadingSale } = useSale()
    const [googlePayIsReady, setGooglePayIsReady] = useState(false)

    const [loadingGooglePay, setLoadingGooglePay] = useState(false)
    const [isApplePaySupport, setIsApplePaySupport] = useState(false)
    const { sendDataToReactNative } = useRNBridge()

    const [openUpgradeComplete, setOpenUpgradeComplete] = useState(false)

    const isMobile = useIsMobileScreen()

    const openUrlIOS = localStorageRequestKey && mobileType.toLowerCase() === "ios"
    const termsLink = 'https://runsmartonline.com/terms-of-use/'
    const privacyLink = 'https://runsmartonline.com/privacy-policy/'
    const waiverLink = 'https://runsmartonline.com/waiver-of-liability/'

    const { setIsLoading, setIsSuccess, isLoading, lastRNBridgeMessage, setLastRNBridgeMessage } = useStore((state: IComponentStore) => state)
    const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();
    const { pastDueBalance } = useStore((state: ICurrentUser) => state)

    const {
        setAlertVariant,
        setAlertMessage,
        onAlertOpen
    } = useAlert()

    const recurly = useRecurly()

    const gtagTrack = () => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-10847885916/DW1UCO6PkssZENy01rQo',
            'value': monthly ? 67 : 147,
            'currency': 'USD'
        });
    }

    const createShareTransaction = async (subscriptionId: string) => {
        const referralCode = localStorage.getItem('referral_code')
        if (referralCode && userInfo?.account_id && subscriptionId) {
            await CreateShareTransaction(userInfo?.account_id, referralCode, subscriptionId, "pro", 1)
        }
    }

    // After Billing Change
    const afterBillingChange = async () => {
        const signupMethod = (isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web'
        // ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
        const planCode = monthly ? costs.monthlyProCode : costs.annualProCode
        await createSubscription(planCode, signupMethod)
    }

    const afterBillingProcess = async () => {
        // ReactPixel.trackCustom('AddPaymentInfo', null);
        ReactPixel.trackCustom('ProPurchase', { k: new Date().getTime() });
        setOpenUpgradePro(false)
        setOpenPaymentDetails(false)
        setOpenUpgradeComplete(true)
        //window.location.reload()
    }

    // On Change
    const onChange = (event: any) => {
        if (event.valid != null) {
            setIsValidCard(event.valid)
        }
    }

    // In app purchase

    const handleInitPurchaseSubscription = async () => {

        if (localStorageRequestKey && userInfo?.account_id) {
            sendDataToReactNative({
                requestType: 'google-choose-plan-webapp',
                requestKey: requestKey || localStorageRequestKey,
                payload: monthly ?
                    { amount: costs.monthlyPro, planCode: costs.monthlyProCode, type: 'trial', account_id: userInfo.account_id, isSale: hasSale }
                    : { amount: costs.annualPro, planCode: hasSale ? "annual_99" : costs.annualProCode, type: 'trial', account_id: userInfo.account_id, isSale: hasSale },
                timestamp: new Date().getTime()
            })
        }
    }

    // On Submit
    const onSubmit = () => {
        // ReactPixel.trackCustom('InitiateCheckout', { k: new Date().getTime() });
        setIsLoading(true)
        const errorMsg = () => {
            setAlertVariant('danger')
            setAlertMessage(
                `Could not create subscription.`
            )
            onAlertOpen()
            return false
        }
        try {
            const form = document.querySelector('#billing-form') as HTMLFormElement
            recurly.token(form, async (err, token) => {
                if (err) {
                    setIsLoading(false)
                    return errorMsg()
                } else {
                    const billingResult = await updateBillingInfo(token.id)
                    if (billingResult?.data?.id) {
                        if (isUpdatePaymentInfo) {
                            setIsLoading(false)
                            setIsSuccess(true)
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        }
                        else {
                            // After Billing Update
                            await afterBillingChange()
                            const { isActive, subscription } = await getSubscriptionInfo()
                            if (isActive && subscription?.id) {
                                setIsLoading(false)
                                setIsSuccess(true)
                                setTimeout(() => {
                                    setIsSuccess(false)
                                    setTimeout(async () => {
                                        gtagTrack()
                                        // await createShareTransaction(subscription.id)
                                        await afterBillingProcess()
                                    }, 500)
                                }, 3000)


                                return true
                            }
                        }
                    }
                    setIsLoading(false)
                    return errorMsg()
                }
            })
        } catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    }

    const handleSaveExternalSubscription = async (data: any) => {
        setIsLoading(true)
        await postReq('/v2/external-subscription', data).then(async () => {
            setIsLoading(false)
            gtagTrack()
            await afterBillingProcess()
        }).catch(error => {
            setIsLoading(false)
        })
    }

    const getData = async () => {
        const newCosts = await getPlanCosts({
            hasOffer: false,
            isRenew: false,
            isYearlyRenewOnly: false,
            hasSale: hasSale,
        })

        setCosts(newCosts)
    }

    const handleOpenUrl = (url: string) => {
        if (localStorageRequestKey && mobileType.toLowerCase() === "ios") {
            const dataToSend = {
                requestType: 'open-rs-shop',
                payload: { url: url },
                timestamp: new Date().getTime(),
            }
            sendDataToReactNative(dataToSend);
        } else
            window.open(url, '_blank')
    }

    useEffect(() => {
        // console.log(activeSale)
        if (activeSale && !localStorageRequestKey || activeSale && localStorageRequestKey) {
            if (activeSale.active == 1)
                setHasSale(true)

            else if (activeSale.active == 0)
                setHasSale(false)
        }
        else
            setHasSale(false)
    }, [activeSale, localStorageRequestKey, mobileType])

    useEffect(() => {
        setIsNativeMobile((requestKey || localStorage.getItem("deviceRequestKey")) ? true : false)
    }, [localStorageRequestKey, requestKey])

    useEffect(() => {
        // handleGetExternalAccount()
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        if (lastRNBridgeMessage && userInfo) {
            const RNBridgeData = JSON.parse(lastRNBridgeMessage)

            if (RNBridgeData.requestKey === requestKey || RNBridgeData.requestKey === localStorageRequestKey) {
                setMessageData(RNBridgeData)
                setLastRNBridgeMessage(undefined)
            }
        }

    }, [lastRNBridgeMessage, userInfo]);

    useEffect(() => {
        // alert(JSON.stringify(messageData))
        if (messageData) {

            const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

            const handleSucessSub = (messageData.requestKey === requestKey || messageData.requestKey === localStorageRequestKey) && (messageData.requestType === "google-success-sub-mobileapp") ? true : false

            const handleSucessSubIos = (messageData.requestKey === requestKey || messageData.requestKey === localStorageRequestKey) && (messageData.requestType === "apple-success-sub-mobileapp") ? true : false

            // alert(handleSucessSub)
            // alert(handleSucessSubIos)

            if (userInfo?.account_id && handleSucessSub) {
                const externalData = { user_id: userInfo?.account_id, type: 'google', purchase_token: messageData.payload, is_expired: 0, app_account_token: requestKey || localStorageRequestKey }
                handleSaveExternalSubscription(externalData)
            }
            else if (userInfo?.account_id && handleSucessSubIos) {
                const externalData = { user_id: userInfo?.account_id, type: 'apple', purchase_token: messageData.payload, is_expired: 0, app_account_token: requestKey || localStorageRequestKey }
                handleSaveExternalSubscription(externalData)
            }
        }

    }, [messageData]);

    useEffect(() => {
        if (userInfo?.account_id) {
            getData()
        }
    }, [userInfo])

    const renderOtherPaymentMethods = () => {
        const supportedPaymentMethods = isApplePaySupport || googlePayIsReady
        return <>
            {supportedPaymentMethods ? <P2 sx={{ my: 0 }} card>{isUpdatePaymentInfo ? "Update billing with" : `Choose a payment method`}:</P2> : undefined}
            <Box sx={{ width: '100%', my: 1, display: 'flex', flexDirection: 'row', }}>
                <ApplePayButton
                    costs={monthly ? costs.monthlyPro : (hasSale && !monthly) ? costs.annualFlash : costs.annualPro}
                    planCode={monthly ? costs.monthlyProCode : costs.annualProCode}
                    afterBillingChange={afterBillingChange}
                    afterBillingProcess={afterBillingProcess}
                    tranxType={isUpdatePaymentInfo ? "update" : "create-signup"}
                    setIsApplePaySupport={setIsApplePaySupport}
                />

                <GooglePayButton
                    costs={monthly ? costs.monthlyPro : (hasSale && !monthly) ? costs.annualFlash : costs.annualPro}
                    planCode={monthly ? costs.monthlyProCode : costs.annualProCode}
                    afterBillingChange={afterBillingChange}
                    afterBillingProcess={afterBillingProcess}
                    tranxType={isUpdatePaymentInfo ? "update" : "create-signup"}
                    setLoadingGooglePay={setLoadingGooglePay}
                    loadingGooglePay={loadingGooglePay}
                    buttonStyle={{
                        buttonRadius: 5,
                        buttonColor: 'white',
                    }}
                    // className="checkout-google-pay"
                    setGooglePayIsReady={setGooglePayIsReady}
                />

            </Box>
        </>
    }

    const renderPaymentDetails = () => {
        return <Box sx={{ height: '100%' }}>
            <RSHeaderCont noBanner isStatic={!isMobile} backClick={isMobile ? () => setOpenPaymentDetails(false) : undefined} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={isUpdatePaymentInfo ? 'Update Billing' : "Upgrade to RunSmart Pro"} />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start", width: "100%", height: 'calc(100% - 60px)' }}>
                <Box>
                    {!isUpdatePaymentInfo ? renderChoosePlan() : undefined}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', px: 3 }}>
                        {!isUpdatePaymentInfo ? <P3 card size={3} sx={{ mt: isUpdatePaymentInfo ? 4 : 0.5, mb: 2, width: "100%", textAlign: "left", }}>Total Due Today: ${hasSale && !monthly ? costs.annualFlash : !hasSale && !monthly ? costs.annualPro : costs.monthlyPro} USD</P3> : <P3 card size={3} sx={{ mt: isUpdatePaymentInfo ? 4 : 0.5, mb: 2, width: "100%", textAlign: "left", }}>Total Due Today:${pastDueBalance} USD</P3>}

                        {!localStorageRequestKey ? renderOtherPaymentMethods() : undefined}
                        {!localStorageRequestKey ? (
                            <>                <P3 size={0.9} sx={{ my: 0.7 }} card>Credit Card</P3>
                                <PaymentDetails
                                    firstName={firstName}
                                    lastName={lastName}
                                    postalCode={postalCode}
                                    setFirstName={(e) => setFirstName(e.target.value)}
                                    setLastName={(e) => setLastName(e.target.value)}
                                    setPostalCode={(e) => setPostalCode(e.target.value)}
                                    onChangeCard={onChange}
                                />
                            </>
                        ) : undefined}

                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', mt: localStorageRequestKey ? 2 : 3 }}>
                            <RSButton1 disabled={!localStorageRequestKey && !isValidCard} onClick={localStorageRequestKey ? handleInitPurchaseSubscription : onSubmit} impose sx={{ width: "100%" }}>
                                {isUpdatePaymentInfo ? `Update Billing` : "Upgrade to Pro"}
                            </RSButton1>
                        </Box>

                        {isUpdatePaymentInfo ? <P2 card size={0.5} sx={{ mt: localStorageRequestKey ? 4 : 3 }}>
                            {`Your account will be charged $${pastDueBalance.toFixed(2)} USD. Cancel your plan any time from your profile page.`}
                        </P2> : <P2 card size={0.5} sx={{ mt: localStorageRequestKey ? 4 : 3 }}>
                            {`Your account will be charged every ${monthly ? 'month' : '12 months'} from ${moment().format("MMMM DD, YYYY")}. Cancel your plan any time from your profile page.`}
                        </P2>}

                    </Box>
                </Box>

                <Box sx={{ display: 'flex', pb: 2, flexDirection: 'column', justifyContent: 'center', my: 3, alignItems: 'center', textAlign: 'center', px: 2, }}>
                    <P2 card size={0.5} sx={{ mt: 1 }}>
                        Read our {' '}
                        <A link={termsLink} onClick={openUrlIOS ? () => handleOpenUrl(termsLink) : undefined} newWindow p1 underline>
                            Terms of Service
                        </A>
                        ,{' '}
                        <A link={privacyLink} onClick={openUrlIOS ? () => handleOpenUrl(privacyLink) : undefined} newWindow p1 underline>
                            Privacy Policy
                        </A>
                        ,{' '}
                        <A link={waiverLink} onClick={openUrlIOS ? () => handleOpenUrl(waiverLink) : undefined} newWindow p1 underline>
                            Waiver of Liability
                        </A>
                    </P2>
                </Box>
            </Box>
        </Box>
    }


    const renderChoosePlan = () => {
        return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', mt: localStorageRequestKey ? 3 : 2, px: 3 }}>
            <P3 card size={2}>Choose a plan to get started.</P3>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "start", justifyContent: 'star', mt: 1 }}>
                <div onClick={() => setMonthly(false)} style={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: !monthly ? '#fcc26a' : 'white', borderRadius: 2, px: 3, py: 2, mr: 3 }}>
                        <P2 card size={1}>Yearly</P2>
                        <SH3 card size={2}>${hasSale ? costs.annualFlash : costs.annualPro}/yr USD</SH3>
                        <P3 size={-1} >{`($${Number((hasSale ? costs.annualFlash : costs.annualPro) / 12).toFixed(2)}/mo)`}</P3>
                    </Box>
                </div>
                <div onClick={() => setMonthly(true)} style={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: monthly ? '#fcc26a' : 'white', borderRadius: 2, px: 3, py: 3 }}>
                        <P2 card size={1} >Monthly</P2>
                        <SH3 card size={2} >${costs.monthlyPro}/mo USD</SH3>
                    </Box>
                </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 3, alignItems: 'center', textAlign: 'center', px: 3 }}>
                {/* {!localStorageRequestKey ? (
                    <div className="stripe-footer mt-4 mb-5">
                        <img className="guard" src="/images/icons/security-badge.svg" />
                        <img src="/images/icons/Powered by Stripe - black.svg" />
                    </div>
                ) : undefined} */}
            </Box>
        </Box >
    }

    const renderUpgradeComplete = () => {
        return <Box className='background-bottom' sx={{ height: '100%', backgroundImage: 'url(/img-new/misc/pro_upgrade_complete.jpg)' }}>
            <RSHeaderCont noBanner isStatic={!isMobile} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title='Upgrade to RunSmart Pro' />
            <Box sx={{ px: 3, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'center', width: "100%", pt: 5, pb: '100px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) calc(100% - 80px), transparent calc(100% - 40px))' }}>

                <Box>
                    <img src='/images/icons/pro-icon.png' style={{ height: '60px', width: '60px' }} />
                </Box>
                <SH2 size={5} sx={{ pt: 2 }}>{`Boom! You're Pro Now.`}</SH2>
                <P1 card sx={{ px: 1, textAlign: 'center', pt: 2 }}>You just leveled up your training-and joined
                    thousands of runners training smarter, running
                    stronger, and getting results with RunSmart.
                </P1>
                <RSButton1 sx={{ mt: 3 }} onClick={() => window.location.reload()}>
                    Finish
                </RSButton1>
            </Box>
        </Box>
    }

    return (
        <>
            <RSDrawer bottom fitContent={isMobile ? false : true} popUpWidth={'500px'} popUpHeight={'auto'} open={openUpgradePro} onClose={() => setOpenUpgradePro(false)}>
                <Box sx={{
                    px: 3, py: 3, display: 'flex', flexDirection: 'column'
                }}>
                    <SH3 card size={5}>Ready to Train and RunSmart?</SH3>
                    <P2 desc size={-0.1} sx={{ mt: 0.7 }}>
                        Take your running to the next level with advanced tools, insights, and personalized features.
                    </P2>
                    <RSCardCont sx={{ display: 'flex', flexDirection: 'column', mt: 2, p: 0, overflow: 'hidden' }}>
                        <Box sx={{ width: '100p%', px: 2, pt: '13px', }}>
                            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'end' }}>
                                <P2 card className='bg-pink white-text' size={-1.1} sx={{ mt: 0, px: 0.5, py: 0.2, mb: -0.8, borderRadius: 1.3 }}>
                                    Current Plan
                                </P2>
                            </Box>
                            <SH3 desc size={1}>RunSmart Basic</SH3>
                            <P2 card size={-0.1} sx={{ mt: 0.7 }}>
                                Everything you need to get started on your training journey
                            </P2>
                            <SH3 card size={3} sx={{ mt: 1.2, mb: 3 }}>$0 USD</SH3>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomAccordion title="Key Features" reverse>
                                {BASIC_FEATURES.map((feature, index) => (
                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 1, py: 0.7 }}>
                                        <i className="fa-duotone fa-solid fa-circle-check duo-tone-GW" style={{ fontSize: '10px', marginRight: 10 }} />
                                        <P2 key={index} card size={-0.9} sx={{ color: "#FFF" }}>{feature}</P2>
                                    </Box>
                                ))}
                            </CustomAccordion>
                        </Box>
                    </RSCardCont>

                    <RSCardCont sx={{ display: 'flex', flexDirection: 'column', mt: 3, p: 0, overflow: 'hidden' }}>
                        <Box sx={{ width: '100p%', px: 2, pt: '13px', }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', mb: -2 }}>
                                <img src='/images/icons/pro-icon.png' style={{ height: '30px', width: '30px' }} />
                            </Box>
                            <SH3 desc size={1}>RunSmart Pro</SH3>
                            <P2 card size={-0.1} sx={{ mt: 0.7 }}>
                                Unlock premium content, more personalized training, and AI features
                            </P2>
                            <Box sx={{ display: 'flex', flexDirection: 'row', my: 2, justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <P2 card size={-0.8} >Monthly</P2>
                                    <SH3 card size={3}>$15 USD</SH3>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
                                    <P2 card size={-0.8} >Yearly</P2>
                                    <SH3 card size={3} >$119 USD</SH3>
                                    <P3 size={-2} >{`(Best value - $9.92/mo)`}</P3>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <CustomAccordion title="Key Features" reverse>
                                {PRO_FEATURES.map((feature, index) => (
                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 1, py: 0.7 }}>
                                        <i className="fa-duotone fa-solid fa-circle-check duo-tone-GW" style={{ fontSize: '10px', marginRight: 10 }} />
                                        <P2 key={index} card size={-0.9} sx={{ color: "#FFF" }}>{feature}</P2>
                                    </Box>
                                ))}
                            </CustomAccordion>
                        </Box>
                    </RSCardCont>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 5, mb: 2 }}>
                        {/* <Button sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#9fcd2b !important', borderRadius: 3, p: 2, cursor: 'pointer', width: '230px' }}>
                    <P2 card size={1}>Upgrade to Pro</P2>
                </Button> */}

                        <RSButton1 onClick={() => {
                            setOpenUpgradePro(false)
                            setOpenPaymentDetails(true)
                        }
                        } impose>
                            Upgrade to Pro
                        </RSButton1>
                    </Box>
                </Box >
            </RSDrawer >

            <RSDrawer bottom fitContent popUpWidth={"700px"} open={openChoosePlan} onClose={() => setOpenChoosePlan(false)}>
                {renderChoosePlan()}
            </RSDrawer>

            <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={openPaymentDetails} onClose={() => {
                setOpenPaymentDetails(false)
            }}>
                {renderPaymentDetails()}
            </RSDrawer>

            <RSDrawer popUpWidth={"auto"} popUpHeight={"800px"} bottom={!isMobile} open={openUpgradeComplete} onClose={() => {
                setOpenUpgradeComplete(false)
            }}>
                {renderUpgradeComplete()}
            </RSDrawer>
        </>
    )
}

export default UpgradePlan
