/* eslint-disable @typescript-eslint/no-unused-vars */

import { RSButton1 } from "../../../components/buttons"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MultiLineText from '../../../components/text/MultiLineText'
// import MainCard from '../../../components/cards/MainCard'
import Form from 'react-bootstrap/Form'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { CANCEL_STORAGE_KEY } from '../../../modules/values'
import { navReq, postReq } from '../../../modules/apiConsume'
import { CANCEL_FINAL_STEP } from '../../../modules/values'
import { FC, useEffect, useState } from 'react'
import { SH3 } from "../../../components/text/RSTypography"
import useAlert from "../../../hooks/useAlert"
import useRNBridge from "../../../hooks/useRNBridge"
import useDeviceDetect from "../../../hooks/useDeviceDetect"
import { cancelSubscription, getAllAccountInfo, getSubscriptionInfo } from "../../../modules/accountActions"
import { Spinner } from "react-bootstrap"
import GenericAlert from "../../../components/misc/GenericAlert"


// Info Text
const { title, info, tagline, options } = CANCEL_FINAL_STEP

const FinalStep: FC<{ closeModal?: () => any }> = ({ closeModal }) => {
    // State
    const [keyIndex, setKeyIndex] = useState(0)
    const [option, setOption] = useState<keyof typeof options>('hard')
    const [feedback, setFeedback] = useState('')

    // State
    const [planId, setPlanId] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isIAP, setIsIAP] = useState(false)
    // Hooks
    const {
        showAlert,
        onAlertOpen,
        onAlertClose,
        alertTitle,
        alertMessage,
        setAlertMessage,
        alertVariant,
        setAlertVariant,
    } = useAlert()
    const { storedValue } = useLocalStorage({ key: CANCEL_STORAGE_KEY })

    const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
    const { sendDataToReactNative } = useRNBridge()

    const { mobileType } = useDeviceDetect()

    // Hooks
    const { setValue } = useLocalStorage({ key: CANCEL_STORAGE_KEY })

    // On Option Change
    const onOptionChange = (newOption: keyof typeof options) => {
        setOption(newOption)
        setKeyIndex((curKeyIndex) => curKeyIndex + 1)
    }

    // On Feedback Change
    const onFeedbackChange = (e: any) => setFeedback(e?.target?.value || '')

    // On Submit
    const onSubmit = async () => {
        setIsLoading(true)
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        const responses = await getAllAccountInfo({ account: false })
        const billingResponse = responses.billing
        const planCode = responses.subscription.data.active.plan_code.replace('-', '_')
        const purchaseToken = responses.subscription.data.active.purchase_token
        const billingDetails = billingResponse?.data

        if (localStorageRequestKey && billingDetails && billingDetails.paymentType === "in_app_purchase") {
            setIsIAP(true)
            if (mobileType === "Android") {
                await postReq('/v2/app-purchase/cancel', { purchaseToken: purchaseToken, productId: planCode }).then(() => {
                    setAlertVariant('success')
                    setAlertMessage('Subscription cancelled')
                    onAlertOpen()
                    setTimeout(() => {
                        setIsLoading(false)
                        navReq('/#/cancel/step-5')
                    }, 2000)
                }).catch(() => {
                    setIsLoading(false)
                    setAlertVariant('danger')
                    setAlertMessage('Could not cancel subscription')
                    onAlertOpen()
                })
            }
            else if (mobileType === "Ios") {
                setIsLoading(false)
                sendDataToReactNative({
                    requestType: 'webapp-open-apple-subscriptions',
                    requestKey: localStorageRequestKey,
                    payload: undefined,
                    timestamp: new Date().getTime()
                })

                navReq('/#/')
            }
        }

        else {
            if (planId) {
                const result = await cancelSubscription(planId, storedValue)
                if (result?.status == 'ok') {
                    setAlertVariant('success')
                    setAlertMessage('Subscription cancelled')
                    onAlertOpen()
                    setIsLoading(false)
                    setTimeout(() => {
                        setIsLoading(false)
                        navReq('/#/cancel/step-5')
                    }, 2000)
                } else {
                    setIsLoading(false)
                    setAlertVariant('danger')
                    setAlertMessage('Could not cancel subscription')
                    onAlertOpen()
                }
            }
        }
    }

    // Get Plan ID
    const getPlanId = async () => {
        const { isActive, subscription } = await getSubscriptionInfo()
        if (isActive && subscription?.id) setPlanId(subscription.id)
    }

    // Mount Effect
    useEffect(() => {
        getPlanId()
    }, [])

    // Render
    return (
        <>
            <GenericAlert
                show={showAlert}
                title={alertTitle}
                message={alertMessage}
                variant={alertVariant}
                onClose={onAlertClose}
            />
            <Col xs={12} md={6} lg={6} xl={6} className='mx-auto'>
                {/* Title */}
                <Row className="mt-1 mb-4">
                    <Col>
                        <SH3 size={3}>{title}</SH3>
                    </Col>
                </Row>

                {/* Info */}
                <Row className="mb-4 text-small">
                    <Col>
                        <MultiLineText text={info} />
                    </Col>
                </Row>

                {/* Options */}
                <Row className="text-start mb-2 d-flex justify-content-center">
                    <Col className="card py-5" xs={12}>
                        <Form>
                            <Form.Group controlId="reason">
                                {Object.keys(options).map((key) => (
                                    <Form.Check key={`r-${keyIndex}-${key}`} type="radio" id={`r-${keyIndex}-${key}`} className="mb-3 c-checkbox--wrapper ps-0">
                                        <Form.Check.Input
                                            type="radio"
                                            checked={key === option}
                                            onChange={onOptionChange.bind(
                                                null,
                                                key as keyof typeof options
                                            )}
                                        />
                                        <Row className="mb-1">
                                            <Col xs={12} className="pe-0">
                                                <Form.Check.Label className="font-14 detail-text custom-checkbox">{options[key as keyof typeof options]}</Form.Check.Label>
                                            </Col>
                                        </Row>
                                    </Form.Check>
                                ))}
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={feedback}
                                    onChange={onFeedbackChange}
                                    style={{ width: '85%', margin: '0 auto' }}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                    {/* Cancel Button */}
                    <Row className="text-center mt-4 mb-4">
                        <Col>
                            <RSButton1
                                sx={{ width: '100%' }}
                                onClick={onSubmit}
                                disabled={planId || !isLoading ? false : true}
                            >
                                {isLoading ? (<Spinner animation={'border'} />) : <div className='mobile-small'>
                                    {localStorageRequestKey && mobileType === "Ios" && isIAP ? 'Go Here To Cancel' : 'Continue'}
                                </div>
                                }
                            </RSButton1>
                        </Col>

                        <RSButton1 tertiary onClick={() => closeModal ? closeModal() : navReq("/#/")} impose sx={{ maxWidth: 'auto', mt: 2 }}>
                            {`Nevermind, I don't want to downgrade.`}
                        </RSButton1>
                    </Row>
                </Row>
                {/* </MainCard> */}
            </Col>
            {/* </Row > */}
        </>
    )
}

export default FinalStep