// Plan Codes
export const planCodes = {
	annual: 'runsmart-annual',
	annualRenew: 'runsmart-annual-renew',
	annualDiscount: 'runsmart-annual-discount',
	annualDiscountRenew: 'annual-discount-renew',
	annualFlash: 'runsmart-annual-flash',
	monthly: 'runsmart-monthly',
	monthlyRenew: 'runsmart-monthly-renew',
	basic: 'runsmart-basic',
	monthlyPro: 'pro-monthly',
	annualPro: 'pro-annual',
}

// Tables
export const tables = {
	accountInfo: 'accounts',
	// accountEmails: 'account_emails',
	accountCancelReason: 'account_cancel_reason',
	passwordReset: 'accounts_pass_reset',
	users: 'users',
	userPace: 'user_pace',
	userChoice: 'user_choice',
}

// Password Reset Fields
export const passwordResetFields = ['email', 'code', 'time']

// Cancel Reason Fields
export const cancelReasonFields = [
	'subscription_id',
	'reason',
	'other_text',
	'time',
]

// User Fields
export const userFields = [
	'account_id',
	'vdot_id',
	'username',
	'email',
	'first_name',
	'last_name',
	'password',
	'plan_code',
	'dob',
	'gender',
	'join_reason',
	// 'favorite',
	// 'photo',
	// 'import_redirect_status',
	'signup_date',
	'created',
	'modified',
	'distance_unit',
	'day_start',
	'weight',
	'height',
]

// One Second
export const ONE_SECOND = 1000

// One Minute
export const ONE_MINUTE = 60 * ONE_SECOND

// One Hour
export const ONE_HOUR = 60 * ONE_MINUTE

// One Day
export const ONE_DAY = 24 * ONE_HOUR
