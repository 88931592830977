import React, { FC, useState } from "react";
import { Elements, RecurlyProvider } from '@recurly/react-recurly'

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { RSButton1 } from '../buttons'
import { H1, P1 } from "../text/RSTypography";
import UpgradePlan from '../../pages/account/UpgradePlan'
import { RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues';

export type RSUpgradeProps = {
	title: string,
	description: string,
	userInfo: any
}

const RSUpgrade: FC<RSUpgradeProps> = ({ title, description, userInfo }) => {
	const [openPaymentDetails, setOpenPaymentDetails] = useState<boolean>(false)
	const [openUpgradePro, setOpenUpgradePro] = useState<boolean>(false)

	return <Grid container xs={12} className='bg-main' sx={{ p: 3 }}>
		<Box sx={{ width: '100%' }}>
			<img src='/images/icons/pro-icon-word.png' style={{ height: '20px', width: 'auto' }} />
		</Box>
		<H1 card size={5} sx={{ pb: 1 }}>{title}</H1>
		<P1 desc>{description}</P1>
		<RSButton1 onClick={() => setOpenPaymentDetails(true)} sx={{ mt: 2, width: "100%", maxHeight: "50px" }}>Upgrade to Pro</RSButton1>

		<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
			<Elements>
				<UpgradePlan userInfo={userInfo} setOpenPaymentDetails={setOpenPaymentDetails} openPaymentDetails={openPaymentDetails} setOpenUpgradePro={setOpenUpgradePro} openUpgradePro={openUpgradePro} />
			</Elements>
		</RecurlyProvider>

	</Grid>
};

export default RSUpgrade;
