/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from "react"
import { ChangeShareDestination, GetAllReferralLinks, GetShareDestination, GetShareTransactions, GetShareTransactionsByUser, GetShareTransactionsByUserId, GetTransactionLogsByTransactionId, GetTransactionsAllLogs } from "../../modules/shareActions"
import Grid from "@mui/material/Grid"
import RSButton from "../../components/buttons/RSButton"
import { GetSearchedAccountProfile, getAllAccounts, getSubscriptionById } from "../../modules/accountActions"
import { Form } from "react-bootstrap"
import ReferrerPanel from "./ReferrerPanel"
import Box from "@mui/material/Box"
import RSDrawer from "../../components/layout/RSDrawer"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from "@mui/material/Switch"
import moment from "moment"
import Stack from "@mui/material/Stack"
import LinearProgress from "@mui/material/LinearProgress"

import RSDateRange from "../../components/input/RSDateRange"
import { Button, MenuItem, Select } from "@mui/material"
import { RouletteSpinner } from "react-spinner-overlay"




const ReferralProgram = () => {

    const actionTypes = ['All', 'Trial', 'Paid', 'Manual']

    const [shareTransactions, setShareTransactions] = useState<any>([])
    const [userList, setUserList] = useState<any>([])
    const [referrerListByRecentTransactions, setReferrerListByRecentTransactions] = useState<any>([])
    const [userNames, setUserNames] = useState<any>([])
    const [emails, setEmails] = useState<any>([])
    const [formTextUserFilter, setFormTextUserFilter] = useState('')
    const [formTextShareLink, setFormTextShareLink] = useState('')
    const [displayUserPoints, setDisplayUserPoints] = useState(false)
    const [referrerDetails, setReferrerDetails] = useState<any>([])
    const [referralsFilter, setReferralsFilter] = useState('')
    const [userDetails, setUserDetails] = useState<any>([])
    const [displaySuccessPopup, setDisplaySuccessPopup] = useState(false)
    const [initiateRefresh, setInitiateRefresh] = useState(false)
    const [displayTableView, setDisplayTableView] = useState(true)
    const [usersSubscription, setUsersSubscription] = useState<any>([])
    const [userReferralLogs, setUserReferralLogs] = useState<any>([])
    const [allTransactionsLogs, setAllTransactionsLogs] = useState<any>([])
    const [selectedUserTransactions, setSelectedUserTransactions] = useState<any>([])
    const [filterDateRangeStart, setFilterDateRangeStart] = useState<any>('2024/10/25')
    const [filterDateRangeEnd, setFilterDateRangeEnd] = useState<any>(moment().format('YYYY/MM/DD'))
    const [actionTypeOfInterest, setActionTypeOfInterest] = useState<any>(actionTypes[0])
    const [dateAscendingOrder, setDateAscendingOrder] = useState(false)
    const [selectedReferralStatistics, setSelectedReferralStatistics] = useState<any>(null)
    const [selectedReferralCode, setSelectedReferralCode] = useState<any>(null)

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [partyTime, setPartyTime] = useState(false)

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('hits')

    const loadMore = (page: number) => {
        const pageValue = page + 1
        setPage(pageValue)
        setIsLoadingMore(true)
        GetAllReferralLinks(limit, pageValue, sortBy).then((res) => {
            const result = res.data.result
            setReferrerListByRecentTransactions(result)
            setIsLoadingMore(false)
        })
    }

    const getReferralEvents = async (user_id?: number) => {

        setIsLoading(true)

        GetAllReferralLinks(limit, page, sortBy).then((res) => {
            const result = res.data.result
            if (user_id) {
                const user = result.find((item: any) => item.user_id == user_id)
                setReferrerListByRecentTransactions([user])
            }
            else
                setReferrerListByRecentTransactions(result)
            setIsLoading(false)
        })
    }


    const handleClickDisplayPoints = async (userId: any) => {

        await GetShareTransactionsByUser(userId).then((res) => {
            const result = res.data.result
            if (result.messages == 'error') {
                alert('User not found')
                return false
            }
            setAllTransactionsLogs(result?.transactionLogs)
            setReferralsFilter(userId)
            setSelectedReferralCode(result?.referralCode)
            setIsLoading(false)
            setDisplayUserPoints(true)
        }).catch((err) => {
            setIsLoading(false)
            return false
        })

        return true
    }

    const handleSearchUser = async (userId: any) => {
        setIsLoading(true)
        await GetAllReferralLinks(limit, page, sortBy, userId).then(async (res) => {
            const result = res.data.result
            if (result.length == 0) {
                alert('User not found')
                setIsLoading(false)
                return false
            }
            if (userId) {
                const user = result.find((item: any) => item.user_id == userId)
                setReferrerListByRecentTransactions([user])
            }
            await GetShareTransactionsByUser(result[0].user_id).then((res) => {
                const result = res.data.result
                setAllTransactionsLogs(result?.transactionLogs)
                setSelectedReferralCode(result?.referralCode)
                setIsLoading(false)
                setDisplayUserPoints(true)
            }).catch((err) => {
                setIsLoading(false)
                return false
            })
        })

    }


    const handleChangeOrderByDates = () => {
        setAllTransactionsLogs(handleSortTransactions(allTransactionsLogs, !dateAscendingOrder))
        setDateAscendingOrder(prev => !prev)
    }


    const handleSortTransactions = (transactionsData: Array<any>, orderAscending: boolean) => {

        const entryDate = (data: any) => moment(data.subscription?.trialStartedAt)

        if (orderAscending) {
            // console.log('>>> orderAscending true', orderAscending)

            transactionsData.sort((a, b) => {
                return entryDate(a).diff(entryDate(b))
            })

        } else if (!orderAscending) {
            // console.log('>>> orderAscending false', orderAscending)
            transactionsData.sort((a, b) => {
                return entryDate(b).diff(entryDate(a))
            })
        }

        return transactionsData
    }

    useEffect(() => {
        if (referralsFilter == '' && !formTextUserFilter) {
            getReferralEvents()
            displayUserPoints && setDisplayUserPoints(false)
        }
        // getShareLogs()
    }, [sortBy, referralsFilter, formTextUserFilter, displayUserPoints])

    useEffect(() => {
        if (referralsFilter) {
            getReferralEvents(Number(referralsFilter))
            setFormTextUserFilter(referralsFilter)
        }
    }, [referralsFilter])


    useEffect(() => {
        if (initiateRefresh && selectedReferralCode) {
            handleClickDisplayPoints(selectedReferralCode.split('-')[0]).then((res) => {
                if (res)
                    setInitiateRefresh(false)
            })
        }
    }, [initiateRefresh, selectedReferralCode])


    const renderTopConfig = () => <>
        <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ height: '5rem', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '0.3rem', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontFamily: 'Poppins-Bold', color: 'black', width: '12rem' }}>User ID</div>
                <Form.Control
                    value={formTextUserFilter}
                    type="text"
                    placeholder=""
                    onChange={(e) => setFormTextUserFilter(e.target.value)}
                />
                <RSButton secondary sx={{ height: '2.6rem', ml: '0.4rem' }}
                    onClick={() => handleSearchUser(formTextUserFilter)}
                >Display Points</RSButton>
            </div>

        </div>
    </>


    const renderReferralEventsListHeader = () => <>

        <Grid sx={{ height: '3rem', mb: '0.5rem', fontFamily: 'Poppins-Light !important', p: 0, overflow: 'hidden', transitionDuration: '300ms' }}>
            <RSButton disabled customized secondary card sx={{ width: '100%', height: '100%', overflow: 'hidden', backgroundColor: '#D9FFE3', }}>
                <Grid sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Grid sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'space-between' }}>

                        {/* @@@ left-side of the item, referrer, points earned */}
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1rem' }} >

                            <span style={{ fontFamily: 'Poppins-Medium', marginLeft: '0.7rem', fontSize: '1rem' }}>Parent User</span>
                        </div>

                        <div className="card-slanted--reverse" style={{ height: '3rem', width: '60%', display: 'flex', flexDirection: 'row', background: '#D9FFE3', justifyContent: 'flex-end', alignItems: 'center', transitionDuration: '500ms' }} >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '1rem' }}>
                                <span style={{ fontFamily: 'Poppins-bold' }}>FROM USER</span>
                                <span style={{ fontFamily: 'Poppins-bold', marginLeft: '3.5rem', fontSize: '0.9rem' }}>ACTION</span>
                                <span style={{ fontFamily: 'Poppins-bold', marginLeft: '0.1rem', fontSize: '1rem' }}>PTs</span>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </RSButton>
        </Grid >
    </>


    const renderReferralEventsTransactionsTable = (shareTransactions: any, filterUser: any) => <>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow >
                        {/* <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell> */}
                        <TableCell sx={{ fontWeight: 'bold' }}>Referred User</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Trial Start Date</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Paid Start Date</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Referred User Plan</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Action</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shareTransactions.map((data: any, dI: any) => {

                        const referrerUserID = data?.referral_code.split('-')[0]
                        if (filterUser != '' && filterUser != referrerUserID) return null
                        // if (data?.referral?.user_id == undefined || data?.referral?.user_id == 0) return null

                        const actionType = data?.status == 0 ? 'JOINED' : data?.status == 1 ? 'TRIAL' : data?.status == 2 ? 'VOIDED' : data?.status == 3 ? 'REFUND' : data?.status == 4 ? 'MANUAL' : 'Unknown'
                        const dateStarted = moment(data?.date_created).format('MM/DD/YYYY') || 'N/A'
                        const datePaid = data?.purchase_subscription_id != '' && moment(data?.last_modified).format('MM/DD/YYYY') || 'N/A'
                        const planType = usersSubscription.find((item: any) => item.userId == data?.user_id)?.subscriptionPlan?.plan_name || 'N/A'

                        return <TableRow key={dI}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{data?.user_id == 1 ? 'Adjusted by Admin' : userNames[dI]?.referred}</TableCell>
                            <TableCell align="right">{data?.user_id == 1 ? '' : dateStarted}</TableCell>
                            <TableCell align="right">{data?.user_id == 1 ? '' : datePaid}</TableCell>
                            <TableCell align="right">{data?.user_id == 1 ? '' : planType}</TableCell>
                            <TableCell align="right">{actionType}</TableCell>
                            <TableCell align="right">{data?.points_earned}</TableCell>
                        </TableRow>
                    }
                    )}
                </TableBody>
            </Table>
        </TableContainer>

    </>

    // @@@ Main Referral Table / All Users that referred view
    const renderReferralEventsTable = () => <>

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{ cursor: 'pointer' }}>
                        <TableCell sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == '' ? '#9DFCFF' : 'white' }}
                            onClick={() => setSortBy('')} >{displayUserPoints ? 'This User' : 'User'}</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'hits' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('hits')}
                        >Clicks</TableCell>

                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'new_accounts' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('new_accounts')}
                        >New Accounts</TableCell>

                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'trial' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('trial')}
                        >Basic</TableCell>

                        {/* <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'subscribed' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('subscribed')}
                        >Members</TableCell> */}

                        {/* <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'manual' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('manual')}
                        >Manual</TableCell> */}

                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'credits' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('credits')}
                        >Pro Accounts</TableCell>

                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: !displayUserPoints && sortBy == 'balance' ? '#D9FFE3' : 'white' }}
                            onClick={() => setSortBy('balance')}
                        >Remaining Balance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {referrerListByRecentTransactions.map((data: any, dI: any) => {
                        const newAccounts = data?.stats?.new_accounts || 0
                        const subscribed = data?.stats?.purchased_subscriptions || 0
                        const manual = data?.stats?.manual_transactions || 0

                        return <TableRow key={dI}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                            onClick={() => {
                                handleClickDisplayPoints(data?.user_id || data?.referrerAccount?.account_id)
                                // setReferralsFilter(data?.user_id || data?.referrerAccount?.account_id)
                            }}

                        >
                            <TableCell component="th" scope="row">
                                {data?.user?.email || 'ID: ' + data?.user_id || 'ID: ' + data?.referrerAccount?.account_id}
                            </TableCell>

                            <TableCell align="right">{data?.hits || 0}</TableCell>
                            <TableCell align="right">{newAccounts}</TableCell>
                            <TableCell align="right">{data?.stats?.trial_subscriptions || '0'}</TableCell>
                            {/* <TableCell align="right">{subscribed}</TableCell> */}
                            {/* <TableCell align="right">{manual}</TableCell> */}
                            <TableCell align="right">{data?.stats?.credits_redeemed || '0'}</TableCell>
                            <TableCell align="right">{'$'}{data?.stats?.total_amount_earned?.toFixed(2) || '0'}</TableCell>
                        </TableRow>
                    }
                    )}
                </TableBody>
            </Table>
            {!displayUserPoints && <RSButton secondary sx={{ height: '2.6rem', width: '300px', margin: '1rem auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={() => loadMore(page)}
            >{!isLoadingMore ? "Load More..." : <RouletteSpinner color='#9FCD2B' size={20} />}</RSButton>}
        </TableContainer>

    </>

    // @@@ Referral Transactions Logs for selected user
    const renderReferralEventsLogsTable = () => <>

        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ fontWeight: 'bold' }}>Referred User</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>User Plan</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>User Status</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Action</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Points</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Transaction Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        allTransactionsLogs && allTransactionsLogs.length ? allTransactionsLogs?.map((data: any, dI: any) => {
                            const actionType = data?.type;
                            const isAdmin = data?.referred_user?.account_id == 1;
                            const referredUser = data?.referred_user?.email || ""
                            const planType = data?.subscription?.plan?.name || ""
                            const planStatus = data?.subscription?.state || ""
                            const pointsEarned = actionType !== "account_created" ? data?.transaction?.points_earned : 0 || 0
                            const amountEarned = actionType === "refund" ? -data?.transaction?.amount_earned : actionType !== "account_created" ? data?.transaction?.amount_earned : 0 || 0
                            return <TableRow key={dI}>
                                <TableCell component="th" scope="row">{isAdmin ? 'Adjusted by Admin' : referredUser}</TableCell>
                                <TableCell align="right">{isAdmin ? '' : planType}</TableCell>
                                <TableCell align="right">{isAdmin ? '' : planStatus.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())}</TableCell>
                                <TableCell align="right">{actionType.replaceAll('_', " ").replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase())}</TableCell>
                                <TableCell align="right">{pointsEarned}</TableCell>
                                <TableCell align="right">${amountEarned ? amountEarned.toFixed(2) : 0.00}</TableCell>
                                <TableCell align="right">{moment(data?.transaction?.created_at).format('MM/DD/YYYY')}</TableCell>
                            </TableRow>
                        }) : <TableRow>
                            <TableCell component="th" scope="row">No Records Found</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>

    </>


    useEffect(() => {
        getReferralEvents()
    }, [])


    return <Grid container>

        <Grid item xs={1} />
        {/* old layout */}
        {/* <Grid xs={3} sx={{ marginRight: '1rem' }}>
            {renderSideConfig()}
        </Grid> */}

        <Grid item xs={displayTableView || displayUserPoints ? 9 : 9}>

            <h1 style={{ fontSize: '1.8rem', color: '#777', lineHeight: '0.6rem', marginBottom: '1.2rem', marginLeft: '1rem' }}>
                Referrers
            </h1>

            <Grid sx={{ mb: '0.8rem' }}>
                {renderTopConfig()}
            </Grid>

            <Grid>
                {!displayUserPoints && !isLoading ?

                    <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ backgroundColor: '#E6EBF0' }}>

                        {displayTableView ? <> {renderReferralEventsTable()} </>
                            : <Box sx={{ px: '4rem' }}>
                                {renderReferralEventsListHeader()}
                                {renderReferralEventsTransactionsTable(shareTransactions, referralsFilter)}
                            </Box>}
                    </div>

                    : displayUserPoints ?
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ backgroundColor: 'white' }}>

                            <div style={{ cursor: 'pointer', width: '3rem', marginBottom: '1rem' }}
                                onClick={() => {
                                    setReferralsFilter('')
                                    setFormTextUserFilter('')
                                    setDisplayUserPoints(false)
                                }}>
                                <h1 style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: '1rem', marginTop: '0.6rem' }}>{'  < '}</h1>
                            </div>

                            {renderReferralEventsTable()}
                            <ReferrerPanel referralCode={selectedReferralCode} setInitiateRefresh={setInitiateRefresh} />

                            <Box sx={{ backgroundColor: '#E6EBF0', p: '0rem 1rem 1rem', mt: '1rem' }}>

                                <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row', alignContent: 'flex-end', justifyContent: 'flex-end', alignItems: 'end', backgroundColor: 'white', p: '0.4rem 0.8rem 0.6rem', my: '0.6rem', borderRadius: '5px' }}>
                                    {/* <ReferralUsersTable></ReferralUsersTable> */}
                                    {/* 
                                    <Box sx={{ backgroundColor: 'white' }}>
                                        <RSDateRange isWeekSelector isDayRangeSelector startLabel={'Start Date'} endLabel={`End Date`} startDate={filterDateRangeStart || moment().format('DD/MM/YYYY')} setStartDate={setFilterDateRangeStart} startDay endDate={filterDateRangeEnd || moment().format('DD/MM/YYYY')} setEndDate={setFilterDateRangeEnd} startCalendarLabel='Choose Week to Start Training' endCalendarLabel='Choose Week to End Training' endDay />
                                    </Box> */}

                                    <Box sx={{ height: '100%', width: '10rem', ml: '1rem' }}>
                                        <Button sx={{ height: '3rem', width: '8rem', border: '1px solid #5DB371', color: '#5DB371', fontWeight: 'bold', borderRadius: '5px' }}
                                            onClick={handleChangeOrderByDates}>
                                            {dateAscendingOrder ? <i className='fa-solid fa-arrow-up' /> : <i className='fa-solid fa-arrow-down' />}
                                            <span style={{ fontSize: '12px', marginLeft: '0.4rem' }} >{dateAscendingOrder ? 'ascending' : 'descending'}</span>
                                        </Button>

                                    </Box>

                                    <Select sx={{ width: '10rem' }}
                                        value={actionTypeOfInterest}
                                        onChange={(e) => setActionTypeOfInterest(e.target.value)} >
                                        {actionTypes.map((item: any, dI: any) => <MenuItem key={dI} value={item}>{item}</MenuItem>)}
                                    </Select>

                                </Box>

                                {renderReferralEventsLogsTable()}
                            </Box>

                        </div>

                        :

                        !isLoading && referrerListByRecentTransactions.length == 0 ?

                            <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ backgroundColor: '#E6EBF0' }}>
                                No Entries Found
                            </div>

                            :

                            <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ backgroundColor: '#E6EBF0' }}>
                                <div style={{ width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                                    <Stack sx={{ width: '100%', color: 'grey.500', borderRadius: '10px' }} spacing={0}>
                                        <LinearProgress color="secondary" sx={{ height: '7px' }} />
                                        <LinearProgress color="success" sx={{ height: '7px' }} />
                                        <LinearProgress color="inherit" sx={{ height: '10px', color: 'white' }} />
                                        <LinearProgress color="success" sx={{ height: '7px' }} />
                                        <LinearProgress color="secondary" sx={{ height: '7px' }} />
                                    </Stack>
                                </div>
                            </div>



                }
            </Grid>
        </Grid>

        <RSDrawer bottom open={displaySuccessPopup} onClose={() => setDisplaySuccessPopup(false)}>
            <div style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <div style={{ marginTop: '2rem', marginBottom: '1rem', fontSize: '1.2rem', width: 'auto', fontWeight: 'bold', textAlign: 'center' }}>Link successfully changed!</div>
            </div>
        </RSDrawer>
    </Grid>
}

export default ReferralProgram

