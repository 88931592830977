/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState, useEffect, useRef } from 'react'
import moment from 'moment'
//import { motion } from "framer-motion";

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Input from '@mui/material/InputBase';

//import SearchAdvance from './SearchAdvance'
import { AccountProfile } from '../../api/v2/account/Account.types'
import { SearchFiltersProps } from '../../modules/types'
import { DEFAULT_RACE_TYPES, DISABLED_RACE_TYPES, DEFAULT_LOCATION_TYPES } from '../../modules/values'
import RSButton from '../../components/buttons/RSButton'
import RSChipButton from '../../components/buttons/RSChipButton';
import ScrollRow from '../../components/layout/ScrollRow'
import RSDrawer from '../../components/layout/RSDrawer';
import RSInputAdornment2 from '../../components/input/RSInputAdornment2';
import useHereLocation from '../../hooks/useHereLocation';
import { useDebounce } from '../../hooks/useDebounced';
import useQueryParams from '../../hooks/useQueryParams'
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import useStore from '../../store/useStore';
import { ICurrentUser } from '../../store/createAccountStore';
import useSWR from 'swr';
import { IBuildPlanAvailability } from '../../api/v2/training-plan/training-plan.types';
// import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';

export type FiltersProps = {
	accountProfile: AccountProfile,
	isLoading: boolean
	scrollReset: any,
	isNear: boolean,
	setIsNear: any,
	filters: SearchFiltersProps | undefined,
	setFilters: any,
	setGeoLoading: any,
	geoLoading?: boolean,
	raceName?: string,
	hide?: boolean,
	location?: any,
	setLocation?: any,
	getLocation?: any,
	isGeolocationAvailable?: any,
	isGeolocationEnabled?: any,
	noGeoAlert?: any
	initialRaceType?: string,
	isGeolocated?: boolean
	setIsGeolocated?: any,
	inputRemoved?: boolean,
	setHasActiveFilters?: any
	disabledRaces?: Array<string>
}

const SearchFilters: FC<FiltersProps> = ({ accountProfile, isLoading, scrollReset, raceName, filters = {}, setFilters, setLocation, hide, getLocation, location, isGeolocationAvailable, isGeolocationEnabled, noGeoAlert, setGeoLoading, isGeolocated, setIsGeolocated, geoLoading, inputRemoved, setHasActiveFilters, disabledRaces, initialRaceType }) => {
	const [isScrolling, setIsScrolling] = useState(false)
	const [isLoadingLocation, setIsLoadingLocation] = useState(false)
	const inputRef = useRef<any>();
	const initGeoRef = useRef<boolean>(false);
	const [locationInputValue, setLocationInputValue] = useState('')
	const [locationInputValueDrawer, setLocationInputValueDrawer] = useState(locationInputValue)
	const { race_type, start_date, end_date, radius = '80' } = filters
	const { getSuggestedLocation } = useHereLocation()
	const [isAutocompleted, setIsAutocompleted] = useState(false)
	const [currentLocation, setCurrentLocation] = useState({ city: '', country: '', state: '', zipcode: '', isNearMe: false })
	const [locationItems, setLocationItems] = useState([])
	const [openFilter, setOpenFilter] = useState<number | undefined>()
	const [raceTypeDrawer, setRaceTypeDrawer] = useState(race_type || [])
	const [geoClicked, setGeoClicked] = useState(false)
	const [radiusDrawer, setRadiusDrawer] = useState(radius?.toString() || '')
	const [isTyped, setIsTyped] = useState(false)
	const [isSuggestedClicked, setIsSuggestedClicked] = useState(false)
	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

	const isMobile = useIsMobileScreen()
	const raceType = initialRaceType
	const debouncedLocationText = useDebounce(locationInputValueDrawer, locationInputValueDrawer ? 500 : 0, 3)
	const { userCoords, setUserCoords } = useStore((state: ICurrentUser) => state)
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const { measurement_system } = accountProfile
	const isMetric = measurement_system === 'metric' ? true : false
	const metricArial = isMetric ? 'Kilometers' : 'Miles'
	const metricLabel = isMetric ? 'km' : 'mi'

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const updateFilters = (f: any, fromLocation?: boolean) => {
		setOpenFilter(undefined)
		const newFilter = !fromLocation && !locationInputValue ? handleRemoveNear(f) : f
		setFilters({ ...newFilter, ...{ event_name: raceName || '' } })
		scrollReset()
	}

	const handleAdd = (val: string) => {
		!race_type?.includes(val) && updateFilters({ ...filters, race_type: [...(race_type || []), val] })
	}

	const handleRemove = (val: string) => {
		const newRaceType = race_type?.filter((rt: string) => rt !== val)
		let newFilter = { ...filters }
		if (newRaceType?.length)
			newFilter = { ...newFilter, race_type: newRaceType }
		else if (newFilter?.race_type)
			delete newFilter.race_type
		updateFilters(newFilter)
	}

	const handleRaceTypeClick = (val: any) => {
		updateFilters({ ...filters, race_type: val })
	}

	const handleClickDateRange = (startDate: string, endDate: string) => {
		updateFilters({ ...filters, start_date: startDate, end_date: endDate })
	}

	const handleRemoveDateRange = () => {
		const newFilter = { ...filters }
		delete newFilter?.start_date
		delete newFilter?.end_date
		updateFilters({ ...newFilter })
	}

	const handleRemoveNear = (f: any) => {
		const newFilter: any = { ...f }
		DEFAULT_LOCATION_TYPES.map((lk: any) => {
			delete newFilter?.[lk]
		})
		setLocation?.(undefined)
		return { ...newFilter }
	}

	const handleNoGeoAlert = (isClicked?: boolean) => {
		if (isGeolocationEnabled) {
			isClicked && setGeoClicked(true)
			setGeoLoading?.(true)
			getLocation?.()
			!userCoords && isNativeApp ? setGeoLoading?.(false) : undefined
		} else
			noGeoAlert?.()
	}

	const handleEnterLocation = () => {
		setLocationInputValue(locationInputValueDrawer)
		setFilters({ ...filters, ...currentLocation, radius: radiusDrawer ? Number(radiusDrawer) : 80, event_name: raceName || '' })
		setOpenFilter(undefined)
		setIsGeolocated(true)
	}

	const handleRemoveInput = () => {
		setIsAutocompleted(false)
		setLocationInputValue('')
		setRadiusDrawer('')
		setLocation?.({ city: '', country: '', state: '', zipcode: '', isNearMe: false })
		setCurrentLocation?.({ city: '', country: '', state: '', zipcode: '', isNearMe: false })
		setFilters({ ...filters, ...{ city: '', country: '', state: '', zipcode: '', isNearMe: false, radius: 80 }, event_name: raceName || '' })
		setIsLoadingLocation(false)
		setOpenFilter(undefined)
		// if (userCoords && isNativeApp) setUserCoords(undefined)
	}

	useEffect(() => {
		if (debouncedLocationText?.length >= 3 && !isAutocompleted && !isGeolocated && isTyped) {
			setIsLoadingLocation(true)
			getSuggestedLocation(debouncedLocationText).then((location) => {
				if (location?.items?.length > 0) {
					// const address = location?.items[0]?.address
					setLocationItems(location?.items)
					setOpen(true)
					setIsLoadingLocation(false)
					// setIsAutocompleted(true)
				}
				else {
					setIsLoadingLocation(false)
				}
			}).catch(() => {
				setIsLoadingLocation(false)
			})
		}
	}, [debouncedLocationText, isAutocompleted, isGeolocated])

	useEffect(() => {
		if (locationInputValueDrawer == '') {
			setOpen(false)
			setIsAutocompleted(false)
			setIsGeolocated(false)
			setLocation?.({ city: '', country: '', state: '', zipcode: '', isNearMe: false })
			setCurrentLocation?.({ city: '', country: '', state: '', zipcode: '', isNearMe: false })
		}
	}, [locationInputValueDrawer])

	useEffect(() => {
		setLocationInputValueDrawer(locationInputValue)
	}, [locationInputValue])

	useEffect(() => {
		if (inputRemoved)
			locationInputValue ? setFilters({ ...filters, ...location, event_name: raceName || '' }) : handleNoGeoAlert()
	}, [inputRemoved])

	useEffect(() => {
		if (filters?.city && filters?.country)
			setLocationInputValue(`${filters.city}, ${filters?.state ? filters.state + "," : ''}${filters.country}`)
	}, [filters])

	useEffect(() => {
		if (location && location.city && location.country) {
			const locStr = `${location.city}, ${location.state ? location.state + "," : ''}${location.country}`
			geoClicked ? setLocationInputValueDrawer(locStr) : setLocationInputValue(locStr)
			!geoClicked ? setFilters({ ...filters, ...location, event_name: raceName || '', ...raceType && { race_type: [raceType] } }) : undefined
			setCurrentLocation(location)
			setIsAutocompleted(true)
		}
	}, [location])

	useEffect(() => {
		setRaceTypeDrawer(race_type || [])
	}, [])

	const renderRaceTypes = (isFromDrawer?: boolean) => {
		const raceTypesList: Array<any> = []
		DEFAULT_RACE_TYPES?.map((df: string, dfi: number) => {
			const isDisabled = disabledRaces && disabledRaces.length ? disabledRaces?.indexOf(df.toLowerCase()) > -1 : false
			const isRemoved = (isFromDrawer ? raceTypeDrawer : race_type)?.includes(df) ? false : true
			const btn = <RSChipButton secondary={isFromDrawer} disabled={isScrolling || (isDisabled && isFromDrawer)} setAdd={() => isFromDrawer ? setRaceTypeDrawer([...raceTypeDrawer, df]) : handleAdd(df)} setRemove={() => {
				isFromDrawer ? setRaceTypeDrawer(raceTypeDrawer?.filter((rt: any) => rt !== df)) : handleRemove(df)
			}} removed={isRemoved} sx={{ mr: isFromDrawer ? 0 : '5px', ml: 0, my: isFromDrawer ? '5px' : 0, height: `${isFromDrawer ? 40 : 28}px`, width: isFromDrawer ? '100%' : 'auto' }} key={df + dfi} label={df} />
			const btnCont = isFromDrawer ? <Grid item container xs={isMobile ? 3.5 : 2.6} key={'race-type-filter-cont' + df + dfi} >{btn}</Grid> : btn
			isFromDrawer ? raceTypesList.push(btnCont) : (!isRemoved && raceTypesList.push(btnCont))
		})
		isMobile && isFromDrawer && (raceTypesList?.length % 3) === 2 && raceTypesList.push(<Grid item container xs={3.5}/>)
		return raceTypesList
	}

	const renderMonthFilters = (isFromDrawer?: boolean) => {
		const monthsList: Array<any> = []
		for (let dri = 0; dri < 12; dri++) {
			const monthDate = moment().add(dri, 'months')
			const month = moment(monthDate).format('MMM')
			let isRemoved = true
			if (start_date && end_date) {
				isRemoved = moment(start_date?.replaceAll('-', '/')).format('MMM') === month && moment(end_date?.replaceAll('-', '/')).format('MMM') === month ? false : true
			}
			let startDate = moment(`${monthDate.format('YYYY/MM')}/01`).format('YYYY-MM-DD')
			startDate = moment(startDate?.replaceAll('-', '/')).isBefore(moment()) ? moment().format('YYYY-MM-DD') : startDate
			const endDate = moment(`${monthDate.format('YYYY/MM')}/${monthDate.daysInMonth()}`).format('YYYY-MM-DD')
			const btn = <RSChipButton secondary={isFromDrawer} disabled={isScrolling} setAdd={() => handleClickDateRange(startDate, endDate)} setRemove={handleRemoveDateRange} removed={isRemoved} sx={{ mr: isFromDrawer ? 0 : '5px', ml: 0, my: isFromDrawer ? '5px' : 0, height: `${isFromDrawer ? 40 : 28}px`, width: isFromDrawer ? '100%' : 'auto' }} key={'month-filter' + dri} label={month} />
			const btnCont = isFromDrawer ? <Grid item container xs={isMobile ? 3.5 : 2.6} key={'month-filter-cont' + dri} >{btn}</Grid> : btn
			isFromDrawer ? monthsList.push(btnCont) : (!isRemoved && monthsList.push(btnCont))
		}
		return monthsList
	}

	const renderLocationChip = () => {
		const locVal = `${locationInputValue} (+${radius ? `${radius}` : '80'}${metricLabel})`
		return locationInputValue ? [<RSChipButton key='location-chip' disabled={isScrolling} removed={false} setRemove={handleRemoveInput} sx={{ mr: '5px', ml: 0 }} label={locVal} />] : []
	}

	const renderLocation = () => {
		return <Grid container xs={12} justifyContent='flex-end' alignItems='center'>
			<Grid container sx={{ width: '100%', borderRadius: '5px', backgroundColor: 'white', height: '45px', border: '1px solid #80808057' }}>
				{/* input */}

				<Input ref={anchorRef} inputProps={{ enterkeyhint: 'enter' }} inputRef={inputRef} placeholder="City, State or Zip Code"
					onChange={(e) => {
						!isTyped && setIsTyped(true)
						isSuggestedClicked && setIsSuggestedClicked(false)
						setLocationInputValueDrawer(e.target.value)
					}}
					value={locationInputValueDrawer}
					sx={{ width: `calc(100% - 45px)`, pl: '10px', height: '100%', backgroundColor: 'transparent', fontSize: '18px', fontFamily: 'Poppins-Light !important' }}
				/>

				<RSButton disabled={geoLoading || isLoadingLocation} onClick={() => locationInputValueDrawer ? setLocationInputValueDrawer('') : handleNoGeoAlert(true)} customized sx={{ backgroundColor: 'transparent', border: 'unset', borderRadius: '0px', height: '100%', width: '45px', minWidth: '0px', justifyContent: 'center', alignItems: 'center' }}>
					{(geoLoading || isLoadingLocation) ? (<i className='fa-regular fa-spinner fa-spin' style={{ fontSize: '20px' }}></i>) : locationInputValueDrawer ? (<i className='fa-light fa-xmark' style={{ fontSize: '20px', color: 'gray' }}></i>) : (<i className='fa-light fa-location-arrow' style={{ fontSize: '22px' }}></i>)}
					{/* <i className='fa-regular fa-location-arrow' style={{ fontSize: '20px' }}></i> */}
				</RSButton>

				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					placement="bottom-start"
					transition
					disablePortal
					sx={{ width: isMobile ? '92%' : '96%', zIndex: 999, overflow: 'hidden', mt: '3px !important', border: '1px solid #80808057', borderRadius: '5px' }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										// autoFocusItem={open}
										id="composition-menu"
										aria-labelledby="composition-button"
										onKeyDown={handleListKeyDown}
									>
										{locationItems?.map((item: any, i: number) => (
											<MenuItem sx={{ borderBottom: locationItems?.length - 1 == i ? '' : '1px solid #DEDFDE' }} placeholder={locationInputValue} key={i} onClick={() => {
												const address = item?.address
												const locationResult = { city: address?.city || '', country: address?.countryCode, state: address?.stateCode, zipcode: address?.postalCode, isNearMe: false }
												setCurrentLocation(locationResult)
												setLocationInputValueDrawer(address?.label)
												setIsAutocompleted(true)
												setOpen(false)
												setIsSuggestedClicked(true)
											}}>{item?.address?.label}</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>

			</Grid>

			<Grid container xs={12} sx={{ fontFamily: 'Poppins !important', fontSize: '14px', pt: 2, pb: 1 }}>
				Find races within this radius
			</Grid>
			<Grid container>
				<RSInputAdornment2 sx={{ maxWidth: '80px' }} value={radiusDrawer} setValue={setRadiusDrawer} label={metricArial} placeholder={'80'} />
			</Grid>
		</Grid>
	}

	const allFilters = [{
		type: 'Race type',
		body: renderRaceTypes(true),
		isEnabled: raceTypeDrawer?.length !== race_type?.length || raceTypeDrawer?.find((rt: any) => !race_type?.includes(rt)),
		onSearch: () => {
			handleRaceTypeClick(raceTypeDrawer)
		},
		headerTitle: 'Race Filter',
		headerDescription: 'Choose a race distance'
	}, {
		type: 'Month',
		body: renderMonthFilters(true),
		headerTitle: 'Date Filter',
		headerDescription: 'Find a race in a specific month'
	}, {
		type: 'Location',
		body: renderLocation(),
		isEnabled: (locationInputValueDrawer !== locationInputValue || radius?.toString() !== radiusDrawer) && (locationInputValueDrawer !== locationInputValue ? (isSuggestedClicked || !locationInputValueDrawer) : true) ? true : locationInputValueDrawer !== "" ? true : false,
		onSearch: () => {
			locationInputValueDrawer ? handleEnterLocation() : handleRemoveInput()
		},
		headerTitle: 'Location Filter'
	}
	]

	const renderFilters = () => {
		return allFilters.map((af: any, fi: number) => {
			const type = af.type
			return <RSChipButton key={'filter' + type} setAdd={() => setOpenFilter(fi)} iconRight icon={<i className={'fa-light fa-chevron-down'} style={{ color: '#010101', fontSize: '15px' }} />} removed disabled={isScrolling} sx={{ mr: '5px', ml: 0, height: '40px' }} label={type} />
		})
	}

	const renderDrawer = () => {
		return <Grid container xs={12} justifyContent='space-between' sx={{ py: 3, px: 3 }}>
			{openFilter !== undefined ? <>
				<Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '18px', pb: 1 }}>{allFilters[openFilter]?.headerTitle}</Grid>
				{allFilters[openFilter]?.headerDescription && <Grid container xs={12} sx={{ fontFamily: 'Poppins !important', fontSize: '14px' }}>{allFilters[openFilter]?.headerDescription}</Grid>}
				{allFilters[openFilter]?.body}
				{allFilters[openFilter]?.onSearch && <Grid container xs={12} justifyContent='center'>
					<Box sx={{ maxWidth: '375px', width: '100%' }}>
						<RSButton sx={{ width: '100%', mt: 3 }} disabled={!allFilters[openFilter].isEnabled} onClick={() => allFilters[openFilter].onSearch?.()} >
							Search
						</RSButton>
					</Box>
				</Grid>}
			</> : <></>}
		</Grid>
	}

	const activeFilters = [...renderRaceTypes(), ...renderMonthFilters(), ...renderLocationChip()]

	useEffect(() => {
		setHasActiveFilters?.(activeFilters?.length > 0)
	}, [activeFilters])

	useEffect(() => {
		setRaceTypeDrawer(race_type || [])
		setRadiusDrawer(radius?.toString() || '80')
		if (!openFilter) {
			setIsAutocompleted(false)
			setIsTyped(false)
			setIsLoadingLocation(false)
			setIsSuggestedClicked(false)
			setIsGeolocated(false)
			setLocationInputValueDrawer(locationInputValue || '')
		}
	}, [openFilter])

	return <Grid sx={{ overflow: 'visible' }} container>

		{!hide && <Grid container xs={12} >
			<ScrollRow setIsScrolling={setIsScrolling} sx={{ marginBottom: '0px' }}>
				<Box>
					<Grid container sx={{ width: '100%', flexWrap: 'nowrap', py: '5px' }} alignItems='center'>
						<Box sx={{ ml: '13px' }}>
							{renderFilters()}
						</Box>
					</Grid>
				</Box>
			</ScrollRow>
			{activeFilters?.length ? <ScrollRow setIsScrolling={setIsScrolling} sx={{ marginBottom: '0px' }}>
				<Box>
					<Grid container sx={{ width: '100%', flexWrap: 'nowrap', py: '5px' }} alignItems='center'>
						<Box sx={{ ml: '13px' }}>
							{activeFilters}
						</Box>
					</Grid>
				</Box>
			</ScrollRow> : <></>}
		</Grid>}
		<RSDrawer bottom fitContent popUpWidth='500px' open={openFilter === undefined ? false : true} onClose={() => setOpenFilter(undefined)}>
			{renderDrawer()}
		</RSDrawer>
	</Grid>
}

export default SearchFilters;
