import WithAuth from '../../../components/WithAuth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Grid from '@mui/material/Grid'

import MainCard from '../../../components/cards/MainCard'
import { RSButton1 } from "../../../components/buttons"
import RSNavCont from '../../../components/layout/RSNavCont'
// import PageTitle from '../../../components/text/PageTitle'
import { CANCEL_IMAGES } from '../../../modules/values'
import { webinarLinks } from '../../../modules/linkInfo'
import { guideLinks } from '../../../modules/linkInfo'
import { CANCEL_STEP_1_TEXT } from '../../../modules/values'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { H1, H2, SH3, P1, P2 } from '../../../components/text/RSTypography'

// Info Text
const {
	title,
	info,
	trainingTitle,
	trainingInfo,
	trainingButton,
	strengthTitle,
	strengthInfo,
	strengthButton,
	injuryTitle,
	injuryInfo,
	injuryButton,
} = CANCEL_STEP_1_TEXT


/**
 * Step1 Component
 */

const Step1: FC = () => {
	const navigate = useNavigate()
	// Render
	return (
		<RSNavCont>
			<Grid container xs={12} sx={{ px: 3 }}>
				{/* Title Row */}
				{/* <Row className="justify-content-center d-none d-md-block">
				<Col className="text-center">
					<PageTitle text={CANCEL_TITLE} />
				</Col>
			</Row> */}

				{/* Header Row */}
				<Grid container xs={12} sx={{ mt: 4, textAlign: 'center' }} justifyContent='center' alignItems='center'>
					<H1 size={5}>{title}</H1>
				</Grid>

				{/* Info */}
				<Grid container xs={12} sx={{ my: 4, textAlign: 'center' }} justifyContent='center' alignItems='center'>
					<P1 size={2} desc>{info}</P1>
				</Grid>

				{/* Cards */}
				<Row className="mb-5">
					{/* Training Card */}
					<Col sm={12} md={6}>
						<MainCard
							cardType="split"
							bgImage={CANCEL_IMAGES.training}
							link={guideLinks.Training}
							buttonLink={guideLinks.Training}
							buttonText={trainingButton}
							className="card-fixed-body-height"
							isColored
							isSmall
						>
							<SH3 size={4} sx={{ mt: 1, mb: 2 }}>{trainingTitle}</SH3>
							<P2 size={1} desc>{trainingInfo}</P2>
						</MainCard>
					</Col>

					{/* Strength Card */}
					<Col sm={12} md={6}>
						<MainCard
							cardType="split"
							bgImage={CANCEL_IMAGES.strength}
							link={guideLinks.Strength}
							buttonLink={guideLinks.Strength}
							buttonText={strengthButton}
							className="card-fixed-body-height"
							isColored
							isSmall
						>
							<SH3 size={3} sx={{ mt: 1, mb: 2 }}>{strengthTitle}</SH3>
							<P2 size={1} desc>{strengthInfo}</P2>
						</MainCard>
					</Col>

					{/* Injury Card */}
					<Col sm={12} md={6}>
						<MainCard
							cardType="split"
							bgImage={CANCEL_IMAGES.injury}
							link={webinarLinks['RunSmart Protocol']}
							buttonLink={webinarLinks['RunSmart Protocol']}
							buttonText={injuryButton}
							className="card-fixed-body-height"
							isColored
							isSmall
						>
							<SH3 size={3} sx={{ mt: 1, mb: 2 }}>{injuryTitle}</SH3>
							<P2 size={1} desc>{injuryInfo}</P2>
						</MainCard>
					</Col>
				</Row>
			</Grid>
			{/* Prompt */}
			<Grid container xs={12} sx={{ mb: 4, textAlign: 'center' }} justifyContent='center' alignItems='center'>
				<H2 size={4}>Not interested?</H2>
			</Grid>

			{/* Cancel Button */}
			<Grid container xs={12} sx={{ pb: 4 }} justifyContent='center' alignItems='center'>
				<RSButton1
					sx={{ width: '100%', maxWidth: '220px', textTransform: 'uppercase !important' }}
					onClick={() => navigate("/cancel/step-2", { replace: true })}
				>
					Downgrade
				</RSButton1>
			</Grid>
		</RSNavCont>
	)
}

export default WithAuth(Step1)
