/* eslint-disable @typescript-eslint/no-unused-vars */
import { Add, Delete, Remove, Search } from "@mui/icons-material"
import { Grid } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import useSWR from "swr"
import { AccountProfile } from "../../api/v2/account/Account.types"
import { AdminUser } from "../../api/v2/admin-users/admin-users.types"
import { RSButton1 } from "../../components/buttons"
import RSInput from "../../components/input/RSInput"
import { SH3 } from "../../components/text/RSTypography"
import { CreateAdminUser, DeleteAdminUser, SearchAccounts } from "../../modules/adminUsersActions"
import { ICurrentUser } from "../../store/createAccountStore"
import useStore from "../../store/useStore"


const ManageAdminUsers = () => {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [searchAdd, setSearchAdd] = useState('')
    const [searchResults, setSearchResults] = useState<AccountProfile[]>([])
    const [searchParam, setSearchParam] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [filteredList, setFilteredList] = useState<AdminUser[]>([])

    const { currentUser } = useStore((state: ICurrentUser) => state)

    const { data: adminUsers, mutate } = useSWR<{ data: { result: AdminUser[] } }>(`/admin-users?page${page}&limit=${limit}`, { refreshInterval: 0 })


    const handleSearchUser = async (value: string) => {
        if (value.length < 3) return
        setIsLoading(true)
        const results = await SearchAccounts(value)
        setIsLoading(false)
        setSearchResults(results?.data.result || [])
    }

    const handleSearchAdminUsers = async (value: string) => {
        //search admin users
        setIsLoading(true)
        const res = adminUsers?.data.result.filter((user) => {
            return user.user?.first_name?.toLowerCase().includes(value.toLowerCase()) ||
                user.user?.last_name?.toLowerCase().includes(value.toLowerCase()) ||
                user.user?.email?.toLowerCase().includes(value.toLowerCase())
        })
        setFilteredList(res ? res : [])
        setIsLoading(false)
    }

    const handleAddUser = async (user: AccountProfile) => {
        setIsLoading(true)
        if (currentUser && user)
            CreateAdminUser({ user_id: user.account_id as number, created_by_id: currentUser.account_id as number }).then(() => {
                setIsLoading(false)
                mutate()
            })
    }

    const handleRemove = async (user: AdminUser) => {
        setIsLoading(true)
        if (currentUser && user && user.user_id != 1)
            DeleteAdminUser(user.id as number).then(() => {
                setIsLoading(false)
                mutate()
            })
    }

    useEffect(() => {
        if (searchParam === '') {
            setFilteredList(adminUsers?.data?.result || [])
        }
        else if (searchParam.length > 3) {
            handleSearchAdminUsers(searchParam)
        }
    }, [searchParam])

    useEffect(() => {
        setFilteredList(adminUsers?.data?.result || [])
    }, [adminUsers])

    return (
        <Grid container spacing={1}>
            <Grid xs={12} md={4}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <SH3 size={2}>Add Admin Users</SH3>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <RSInput value={String(searchAdd)} setValue={setSearchAdd} title='Search User by name, email or id' />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <RSButton1 disabled={isLoading} sx={{ mt: "10px", mx: 1, height: "40px" }} onClick={async () => await handleSearchUser(searchAdd)}><Search /></RSButton1>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th colSpan={1}></th>
                                    </tr>
                                </thead >
                                <tbody>
                                    {searchResults.map((user, index) => (
                                        <tr key={user.user_id}>
                                            <td>{index + 1}</td>
                                            <td>{user.account_id}</td>
                                            <td style={{ maxWidth: "150px" }}>{user.first_name} {user.last_name}</td>
                                            <td style={{ maxWidth: "150px" }}>{user.email}</td>
                                            <td>
                                                <RSButton1 disabled={isLoading} sx={{ my: 1, mx: 1, height: "40px", width: "40px" }} onClick={() => handleAddUser(user)}><Add /></RSButton1>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid xs={12} md={8}>
                <div className='half-md-card w-100 card mx-1 p-3 mt-0'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <SH3 size={2}>Admin Users</SH3>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RSInput value={String(searchParam)} setValue={setSearchParam} title='Search User by name, email or id' />
                        </Grid>
                        {/* <Grid item xs={12} md={2}>
                            <RSButton1 sx={{ my: 1, mx: 1, height: "40px" }} onClick={async () => await handleSearchAdminUsers(searchParam)}>Search</RSButton1>
                        </Grid> */}

                        <Grid item xs={12} md={12}>
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Added By</th>
                                        <th>Added On</th>
                                        <th colSpan={1}></th>
                                    </tr>
                                </thead >
                                <tbody>
                                    {filteredList?.map((user, index) => (
                                        <tr key={user.id}>
                                            <td>{index + 1}</td>
                                            <td>{user?.user_id}</td>
                                            <td>{user.user?.first_name} {user.user?.last_name}</td>
                                            <td>{user.user?.email}</td>
                                            <td>{user.added_by?.first_name} {user.added_by?.last_name}</td>
                                            <td>{moment(user.date_created).format("MMM DD, YYYY hh:mm:ss")}</td>
                                            <td>
                                                <RSButton1 disabled={isLoading} danger sx={{ my: 1, mx: 1, height: "40px", width: "40px" }} onClick={() => handleRemove(user)}><Remove /></RSButton1>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}

export default ManageAdminUsers