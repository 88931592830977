/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap'
import moment from 'moment';
import confetti from 'canvas-confetti'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { RSButton1 } from '../../components/buttons'
import { SH1, SH2, P1 } from '../../components/text/RSTypography'
import Calendar from '../../components/your-training-plan-sidebar/Calendar';

import JSONTable from '../admin/JSONTable'
import useBuildTrainingPlan from '../training-plan/hooks/useBuildTrainingPlan';
import useCalendarBase from '../training-plan/hooks/useCalendarBase';
import raceSelection from '../training-plan/build/RaceSelection.json'

import { GetUserInfo } from '../../modules/trainingPlanActions';
import { getColorCode } from '../../modules/miscUtils';
import { ActivateGPTPlan, CreateAItrainingPlan } from '../../modules/aiTrainingPlanActions';
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';

import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { IAiTrainingPlanStore } from '../../store/createAITrainingPlanStore';
import useGPTTrainingPlan from './useGPTTrainingPlan';

import useWakeLock from '../admin/useWakeLock'

export type PlanOverviewProps = {
    accountProfile: any
    addDetails: any
    allStageDetails: any,
    handleIsStepComplete?: any
    tableDataOverview?: any
    tableDataCompleteOverview?: any
    planId?: any
    fetchingAIresults?: boolean
    buildStepAI?: number,
    planReady: boolean,
    setIsPLanReady: any
    updatedBaseDetails?: boolean
    setUpdatedBaseDetails?: any,
    setFetchingAIresults?: any
    updatedAddonDetails?: any
    setUpdatedAddonDetails?: any
    latestAIplan?: any
    setLatestAIplan?: any
    raceDetails: any
    planRevision: number
    buildError: boolean
    setBuildError: any
    handleBack: any
    handlePlanBase: any
}

const loaderBuildingPlanTextsByAIsteps = [
    `Laying out the plan best fit for your preferences and body conditions`,
    `Planning out your mileage and run schedule that would suit your training`,
    `Building the plan while following the best training principles with the guidance of Physical Therapists`,
    `Integrating crossfit training and workouts of the Coaches' recommendations`,
]

const loaderBuildingPlanTextByProgress = {
    15: ` Evaluating your running preferences`,
    30: `Building the framework for the plan`,
    45: `Incorporating pacing strategy for race day`,
    60: ` Assigning run types to build endurance `,
    75: `Evaluating plan and making necessary updates `,
    90: `Putting on the final touches `,
    100: `Preparing a preview for you`
}

const PlanOverview: FC<PlanOverviewProps> = ({ accountProfile, allStageDetails, handleIsStepComplete, tableDataCompleteOverview, planRevision, buildError, setBuildError, handleBack, handlePlanBase,
    buildStepAI, updatedBaseDetails, setUpdatedBaseDetails, setFetchingAIresults, updatedAddonDetails, setUpdatedAddonDetails, latestAIplan, setLatestAIplan, planReady, setIsPLanReady, raceDetails }) => {

    // console.log('allStageDetails', allStageDetails)

    const dailyRunTable = false
    const initialMount = useRef(true)

    const { userOverrides } = useStore((state: ITrainingPlanStore) => state)
    const { buildCalendarMonth: buildCalendarMonthBase } = useCalendarBase(userOverrides);

    const [userPlanDetails, setUserPlanDetails] = useState<any>({})

    const { currentUser } = useStore((state: ICurrentUser) => state)
    const { CalcvDOT, ConvertM2Mt } = useTrainingPlanUtils()

    const [celebrated, setCelebrated] = useState<boolean>(false)
    const [errorAlready, setErrorAlready] = useState(false)
    const [results, setResults] = useState<any>([])
    const [showViewCalendar, setShowViewCalendar] = useState<boolean>(false)
    const [planInfo, setPlanInfo] = useState<any>({})
    const [progress, setProgress] = useState<number>(0)
    const [lastUpdated, setLastUpdated] = useState<number>(Date.now())
    const [error, setError] = useState<any>(false)
    const [updateCalendar, setUpdateCalendar] = useState<boolean>(true)
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [calendarData, setCalendarData] = useState<any>([])
    const [calendarLoading, setCalendarLoading] = useState(true)
    const [selectedDay, setSelectedDay] = useState<number>(0)
    const [selectedWeek, setSelectedWeek] = useState<{
        week: string
        key: number
    }>({ week: '', key: 1 })
    const [currentWeek, setCurrentWeek] = useState<{
        week: string
        key: number
    }>({ week: '', key: 1 })

    useWakeLock(progress < 100)

    const raceDate = moment(allStageDetails["1-0"]?.raceDate).format('YYYY-MM-DD')

    const [buildingPlanText, setBuildingPlanText] = useState<string>('Building your plan...')

    const mergedStageDetails = {
        ...allStageDetails["0"], ...allStageDetails["1-0"], ...allStageDetails["1-1"], ...allStageDetails["1-2"],
        ...allStageDetails["2"], ...allStageDetails["3"], ...allStageDetails["4"], ...allStageDetails["5"]
    }

    const { dataGenerated: AIStepsDataGenerated } = useStore((state: IAiTrainingPlanStore) => state)
    const { generateWorkoutVideos } = useGPTTrainingPlan()

    const { getDailyActivityDisplay } = useBuildTrainingPlan(Number(userPlanDetails?.training_level), raceSelection?.find(x => x.title.toLowerCase() === mergedStageDetails?.raceType?.toLowerCase())?.raceValue, "GPT")

    // console.log(mergedStageDetails)
    const navigate = useNavigate();
    const location = useLocation();

    const handleAcceptPlan = async () => {
				const fromSignUp = location?.pathname?.includes('signup')
        await ActivateGPTPlan(Number(planInfo.uid), new AbortController().signal).then((res) => {
            const OFFSET = 50000
            navigate(fromSignUp ? '/' : `/training-plan-v3/view?planId=${Number(planInfo.uid) + OFFSET}`, { replace: true })
        })
    }

    const getRaceDistance = (raceType: string) => {
        const halfMILES = 13.1094
        const fullMILES = 26.2188
        const fiveK = 3.1068
        const tenK = 6.2137
        const eightK = 4.97097

        switch (raceType) {
            case '5k':
                return fiveK
            case '10k':
                return tenK
            case '8k':
                return eightK
            case 'half':
                return halfMILES
            case 'full':
                return fullMILES
            case 'maintenance':
                return fullMILES
        }
    }

    const removeFinalWeekCrossTraining = (data: any, raceType: number, raceDayName: string, weeksMatch: boolean) => {
        if (raceType == 4) {

            return data
        };

        const isTaperPrev = (raceDayName.toLowerCase() === "monday" || (raceDayName.toLowerCase() === "sunday" && !weeksMatch)) && (raceType === 2 || raceType === 3 || raceType === 5);
        const lastWeek = data[data.length - (isTaperPrev ? 2 : 1)];
        let dailyRuns = JSON.parse(lastWeek.daily_runs);
        dailyRuns = dailyRuns.map((run: any) => {
            if (run.cross_training?.toLowerCase()?.includes('yoga') || run.cross_training?.toLowerCase()?.includes('strength')) {
                run.cross_training = "None";
            }
            return run;
        });

        lastWeek.daily_runs = JSON.stringify(dailyRuns);
        return data;
    }

    const handleTableData = async () => {

        // console.log('stageDetails', allStageDetails)

        if (tableDataCompleteOverview.revision === planRevision) {

            // console.log('>>> tableDataCompleteOverview: ', tableDataCompleteOverview)
            // console.log('[saving to DB . . .]')

            setUpdatedBaseDetails(false)
            setUpdatedAddonDetails(false)

            const newPlan = constructPlanDetails()
            console.log('>>> newPlan', newPlan)

            // save to DB
            newPlan && CreateAItrainingPlan(newPlan).then((res) => {

                // console.log('[saved to DB]')
                console.log('>>> handleTableData response: ', res)

                // setUpdatedBaseDetails(false)
                // setUpdatedAddonDetails(false)
                setUpdateCalendar(false)
                setShowLoader(false)

                const results = res.data.result
                setResults(results)

                // console.log('>>> creation results', results)

                // retrieving data from db approach
                // getUserPlanDetailsAPI(res.data.result[0].plan_id)
                // planInfoSAMPLE['uid'] = res.data.result[0].plan_id
                // constructPlanInfo()

                // console.log('[setting latestAIplan]')
                setLatestAIplan(results)
                constructCalendarPlanInfo(results[0])

                setCalendarData(morphResultsForCalendarData(results))
                // console.log('[updated calendar data]')

            }).catch((err) => {
                console.log('>>> saving to DB err', err)
                setError(true)
            })
        }
    }

    const morphResultsForCalendarData = (gptTableData: any) => {
        const calendarViewData: any = []
        let marathonId = 3333
        let paceId = 4444

        const dayToday = moment().day();
        const daysUntilNextSunday = 7 - dayToday;
        let dateVal = moment().format('YYYY-MM-DD')
        dateVal = mergedStageDetails?.startDate || moment().add(daysUntilNextSunday, 'days').format('YYYY-MM-DD')
        let dateValCalendar = formatDateForCalendar(dateVal)
        const raceType = raceSelection.find(rs => rs?.title?.toUpperCase() === raceDetails?.raceTitle?.toUpperCase() || rs?.subtitle?.toUpperCase() === raceDetails?.raceTitle?.toUpperCase())
        let distanceVal: any = 0
        const raceDayName = moment(raceDate).format('dddd').toLowerCase();
        const isWeeksMatch = gptTableData.length === mergedStageDetails?.planWeeks
        if (mergedStageDetails?.unit?.toLowerCase()?.includes('kilometer')) distanceVal = raceType?.distanceValueKM || 0
        else distanceVal = raceType?.distanceValue || 0
        const isMaintenance = raceType?.raceValue == 4 ? true : false
        for (const week of removeFinalWeekCrossTraining(gptTableData, raceType?.raceValue as number, raceDayName, isWeeksMatch)) {
            const isLast = week === gptTableData[gptTableData.length - 1]

            // console.log('>>> week', week.daily_runs)
            let dailyRuns = week?.daily_runs && JSON.parse(week.daily_runs)
            let raceEnded = false

            const addRaceDay = isLast && moment(raceDate).diff(moment(incrementDate(dateVal, 6)), 'days') === 1 ? true : false
            dailyRuns = addRaceDay && dailyRuns?.length ? [...dailyRuns, dailyRuns[0]] : dailyRuns
            dailyRuns?.forEach((day: any, dI: number) => {
                const isAddedRaceDay = addRaceDay && dI === 7 && dI === dailyRuns.length - 1 ? true : false
                // console.log('Race Date: ', formatDateForCalendar(moment(raceDate).format('YYYY-MM-DD')))
                const isRaceDay = isMaintenance ? false : formatDateForCalendar(moment(raceDate).format('YYYY-MM-DD')) === dateVal
                raceEnded = (!raceEnded && isRaceDay) ? true : raceEnded
                marathonId++
                paceId++
                const dayIndex = dI + 1
                const dd = isRaceDay && distanceVal ? distanceVal : Math.round(day.run_distance) || 0
                const weekNum = isAddedRaceDay ? week.week + 1 : isMaintenance ? week.week - 1 : week.week
                const dayNum = isAddedRaceDay ? 1 : dayIndex
                const data = {
                    "monthData": {
                        "week": weekNum,
                        "day": dayNum,
                        "dateVal": dateValCalendar,
                        "monthDigits": []
                    },
                    "activitiesValue": {
                        "marathon": {
                            "id": marathonId,
                            "marathon": isMaintenance ? 4 : 0,
                            "type": mergedStageDetails?.planWeeks,
                            "training_goal": 30,
                            "week": weekNum,
                            "day": dayNum,
                            "distance": raceEnded && !isRaceDay ? 0 : dd,
                            "race_day": isRaceDay ? 1 : null
                        },
                        "marathonPaces": [
                            {
                                "mar_id": marathonId,
                                "paceId": paceId,
                                "pace": isRaceDay ? 'Race Day' : !raceEnded ? day?.run_type?.toLowerCase()?.includes('workout') ? day?.run_type?.replace('Workout', '')?.trim() : day?.run_type?.replace('Run', '')?.trim() : '',
                                "notes": dd + " miles",
                                "orderid": 0
                            }
                        ],
                        "workouts": [
                            {
                                "mid": 1245,
                                "workoutId": 2118,
                                "title": (day.run_type == 'Rest' || day.run_distance == 0) && checkCrossTraining(day.cross_training || '') ? "Cross Train" : '',
                                "description": "",
                                "link": "",
                                "orderid": 0,
                                "workout_type": day.cross_training || ''
                            }
                        ]
                    }
                }

                dateVal = incrementDate(dateVal, 1)
                dateValCalendar = formatDateForCalendar(dateVal)
                calendarViewData.push(data)
            })

        }

        generateWorkoutVideos(calendarViewData, mergedStageDetails.planWeeks, mergedStageDetails.level, mergedStageDetails.crossTraining.join(","), mergedStageDetails.stretchingPlan.join(","), mergedStageDetails?.raceType)
        return calendarViewData
    }


    const checkCrossTraining = (crossTraining: any) => {

        const trainingTypes = ['strength', 'yoga', 'stretch'];
        const regexPattern = new RegExp('\\b(' + trainingTypes.join('|') + ')\\b', 'i');

        return regexPattern.test(crossTraining.toLowerCase());
    }


    const shortenRunType = (runTypeData: any) => {

        const runTypes = {
            "easy": "Easy",
            "recovery": "Recover",
            "long": "Long",
            "speed": "Speed",
            "tempo": "Tempo",
            "hill": "Hill",
            "fartlek": "Fartlek",
            "interval": "Interval",
            "progression": "Prog.",
            "rest": "Rest",
            "base": "Base",
            "race": "Race Pace"
        }

        for (const [key, value] of Object.entries(runTypes)) {
            if (runTypeData.toLowerCase().includes(key)) return value
        }

        return runTypeData
    }


    const constructPlanDetails = () => {
        const startDate = moment(mergedStageDetails?.startDate).format('YYYY-MM-DD')
        setSelectedWeek({ week: startDate, key: 1 })
        setCurrentWeek({ week: startDate, key: 1 })
        const isMaintenance = mergedStageDetails?.raceType?.toLowerCase() === 'maintenance'

        const goalTimeMins = Number(mergedStageDetails?.goalTime?.minutes) + Number(mergedStageDetails?.goalTime?.hours * 60) + Number(mergedStageDetails?.goalTime?.seconds / 60)
        const distanceMt = isMaintenance && mergedStageDetails?.runPace ? ConvertM2Mt(Number(getRaceDistance(mergedStageDetails?.runPace?.toLowerCase().split(" ")[0]) || 0)) : ConvertM2Mt(mergedStageDetails?.raceDistance || 26);
        const vDOT = CalcvDOT(distanceMt, goalTimeMins || 120)
        let goalTimeMinConverted = 0
        // // legacy training plan code assumes all data comes from full marathon, hence the initial conversion
        // if(mergedStageDetails?.raceType?.toUpperCase()?.includes('HALF')) goalTimeMinConverted = goalTimeMins / Math.pow((13.1094 / 26.2188), 1.06)
        // if(mergedStageDetails?.raceType?.toUpperCase()?.includes('FULL')) goalTimeMinConverted = goalTimeMins
        goalTimeMinConverted = goalTimeMins

        // console.log('!!! accountProfile', accountProfile)
        // console.log('!!! mergedStageDetails', mergedStageDetails)

        return {
            "user_id": accountProfile?.user_id || accountProfile?.data?.result[0]?.user_id || accountProfile?.account_id || localStorage.getItem('user_id'),
            "event_name": mergedStageDetails?.raceName || "",
            "race_type": mergedStageDetails?.raceType,
            "type": raceSelection.find(x => x.title.toLowerCase() === mergedStageDetails?.raceType.toLowerCase())?.raceValue || raceSelection.find(x => x.subtitle.toLowerCase() === mergedStageDetails.raceType.toLowerCase())?.raceValue || 0,
            "target_time": goalTimeMinConverted || 120,
            "vdot": vDOT || 0,
            "race_date": moment(raceDate).format('YYYY-MM-DD'),
            "start_date": startDate,
            "weeks": mergedStageDetails?.planWeeks || 13,
            "max_distance": mergedStageDetails?.maxRunPerWeek || 25,
            "average_distance": mergedStageDetails?.userInputCurrentWeeklyMileage || mergedStageDetails?.currentWeeklyMileage || mergedStageDetails?.averageRunPerWeek,
            "suggested_start_distance": mergedStageDetails?.suggestedPlanStartDistance || mergedStageDetails?.currentWeeklyMileage || mergedStageDetails?.averageRunPerWeek,
            "suggested_start_week_distance": mergedStageDetails?.suggestedDistanceWeekStart || mergedStageDetails?.currentWeeklyMileage || mergedStageDetails?.averageRunPerWeek,
            "longest_run": mergedStageDetails?.userInputRecentLongRun || mergedStageDetails?.lastLongestRun || 0,
            "longrun_day": mergedStageDetails?.preferredLongRun || accountProfile?.run_day || "?",
            "run_days": mergedStageDetails?.availableRunDays || "tuesday,thursday,saturday,sunday",
            "max_run_days": mergedStageDetails?.runDaysPerWeek || 4,
            "training_level": mergedStageDetails?.level || 3,
            "cross_training": mergedStageDetails?.crossTraining || [],
            "stretching_areas": mergedStageDetails?.stretchingPlan || [],
            "max_range": mergedStageDetails?.maxRunPerWeekRange || "",
            "average_range": mergedStageDetails?.averageRunPerWeekRange || "",
            "status": 0,
            "plan": tableDataCompleteOverview.results,
            "generated_step_data": mergedStageDetails?.stepsDataGenerated || AIStepsDataGenerated || [],
            "race_logo": raceDetails?.logo || "",
            "race_link": raceDetails?.homepage || "",
            "race_origin": raceDetails?.source || "",
            "unit": accountProfile?.measurement_system === 'metric' ? 'km' : 'mi' || 'mi'
        }
    }

    const constructCalendarPlanInfo = (data: any) => {
        const calendarPlanInfo = {
            "uid": data.plan_id,
            "internal_id": accountProfile?.user_id || accountProfile?.data?.result[0]?.user_id || data.user_id,
            "start": mergedStageDetails?.startDate || data.start_date,
            "race_date": mergedStageDetails?.raceDate || data.race_date,
            "signup_date": moment().format('YYYY-MM-DD'),
            "location": null,
            "late_start": 1,
            "monday_start": 0,
            "aggressiveness": 0,
            "training_level": mergedStageDetails?.level || data.training_level,
            "status": 1,
            "type": raceSelection.find(x => x.title.toLowerCase() === mergedStageDetails?.raceType.toLowerCase())?.raceValue || raceSelection.find(x => x.subtitle.toLowerCase() === mergedStageDetails.raceType.toLowerCase())?.raceValue || 0,
            "weeks": mergedStageDetails?.planWeeks || data.planWeeks,
            "distance": mergedStageDetails?.maxRunPerWeek || data.max_distance,
            "km": "N",
            "vdot": data.vdot || 21.5,
            "targetm": data.target_time || 120,
            "is_active_plan": false
        }

        setPlanInfo(calendarPlanInfo)
    }

    const formatDateForCalendar = (dateStr: any) => {

        const date = moment(dateStr);
        const year = date.format('YYYY');
        const month = date.format('MM');
        let day = date.format('DD');

        // Remove starting zero from days
        if (day.startsWith('0')) {
            day = day.substring(1);
        }

        const formattedDate = `${year}-${month}-${day}`
        return formattedDate;
    }


    const incrementDate = (dateStr: any, times: number) => {
        return moment(dateStr).add(times, 'days').format('YYYY-MM-DD');
    }


    const getUserPlanDetailsAPI = async (planId: any) => {

        // console.log('>>> currentUser', currentUser?.account_id)

        const abortController = new AbortController()
        if (planId && currentUser?.account_id) {
            GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
                (res) => {
                    // console.log('>>> getUserPlanDetails res: ', res?.data?.result)
                    const d = res?.data?.result.find((x: any) => x.uid == planId)
                    // console.log('>>> getUserPlanDetails d: ', d)
                    // setUserPlanDetails(removeTZ(d))
                }
            ).catch((err) => {
                alert(err.response.data.error)
                // return apiResponse(res, {
                //     type: 'error',
                //     ...err.response.data.error
                // })
            })
        }

        return () => {
            abortController.abort()
        }
    }

    const abortController = useRef<any>({ current: '' })
    const generatePlanInfo = () => {

        const planInfo = planInfoSAMPLE
        const gptUsage = true
        abortController?.['current']?.abort?.();
        abortController['current'] = new AbortController();

        // buildCalendarMonthBase(0, planInfo, abortController['current'], gptUsage).then((res) => {
        //     const result = res;

        //     let dateValCont = {}
        //     for (const x of result) {
        //         const dateVal = x?.monthData?.dateVal
        //         if (dateVal)
        //             dateValCont = { ...dateValCont, [`${dateVal}`]: x }
        //     }

        //     setCalendarData(dateValCont)
        // })

    }

    const handleCheckIfRecoveryPlan = () => {
        return false
    }

    const releaseConfettifest = () => {
        for (let i = 0; i <= 2; i++) {
            setTimeout(() => {
                showConfetti()
            }, (i * 250))
        }
    }

    const confettiFire = (particleRatio: any, opts: any) => {
        confetti({
            ...opts,
            origin: { x: 0.5, y: 1 },
            zIndex: 10000,
            particleCount: Math.floor(200 * particleRatio)
        });
    }

    const showConfetti = () => {

        setCelebrated(true)

        confettiFire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        confettiFire(0.2, {
            spread: 60,
        });
        confettiFire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        confettiFire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        confettiFire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }


    const finishBuildLoading = async () => {
        const finalLoadProgress = [60, 80, 100]
        let finalTick = 0
        const timer = setInterval(() => {
            finalLoadProgress[finalTick] > progress && setProgress(finalLoadProgress[finalTick])
            finalTick++
            if (finalTick == 3) clearInterval(timer)
        }, 900)
        setTimeout(() => {
            handleTableData()
        }, 3300)

        return () => {
            clearInterval(timer);
        }
    }

    useEffect(() => {
        // console.log('>>> initial calendarData: ', calendarData)
        // console.log('updateCalendar', updateCalendar)
        // console.log('updatedAddonDetails', updatedAddonDetails)
        // console.log('updatedBaseDetails', updatedBaseDetails)

        setResults([])
        handleIsStepComplete(true, '6')

        if (tableDataCompleteOverview.results && tableDataCompleteOverview.results.length > 0 && (updatedBaseDetails || updatedAddonDetails)) {

            // console.log('>>> latestAIplan: ', latestAIplan)
            if (!latestAIplan || latestAIplan.length == 0) {
                // console.log('>>> no latest plan')
                finishBuildLoading()
                setShowUpdateConfirmation(false)
            } else {
                // console.log('[shown update confirmation]')
                setShowUpdateConfirmation(true)
                setIsPLanReady(false)
            }

        } else if (latestAIplan.length > 0) {
            // console.log('>>> no build edits, showing latest plan')

            finishBuildLoading()

            // constructCalendarPlanInfo(latestAIplan[0])
            // setCalendarData(morphResultsForCalendarData(latestAIplan))
        }
    }, [])


    useEffect(() => {

        // setCalendarData(calendarDataSAMPLE)
        if (initialMount.current) {
            initialMount.current = false
            return
        }

        // console.log('[AI plan complete]')
        // console.log('>>> tableDataCompleteOverview: ', tableDataCompleteOverview)
        // console.log('>>> updatedBaseDetails: ', updatedBaseDetails)
        // console.log('>>> updatedAddonDetails: ', updatedAddonDetails)
        // console.log('>>> updateCalendar: ', updateCalendar)
        if (tableDataCompleteOverview.results && tableDataCompleteOverview.results.length > 0 && updateCalendar && (updatedBaseDetails || updatedAddonDetails)) {
            // console.log('>> saving plan . . .')
            finishBuildLoading()
        }
    }, [tableDataCompleteOverview])


    useEffect(() => {
        if (calendarData.length > 0) {
            !showUpdateConfirmation && !celebrated && showConfetti()
            setShowLoader(false)
            setIsPLanReady(true)
        }
    }, [calendarData, showUpdateConfirmation])


    useEffect(() => {
        // planReady && showConfetti()
    }, [planReady])


    // loading progress circle value
    useEffect(() => {

        const timeIncrement = 1500
        // console.log(timeIncrement)

        const totalStagesAI = 5
        const maxProgress = buildStepAI && (buildStepAI / totalStagesAI * 100) || 0

        const timer = setInterval(() => {
            progress < 100 && setProgress((prevProgress) => (prevProgress < 100 ?
                (prevProgress < (maxProgress + 2) ? prevProgress + Math.floor((Math.random() * 2) + 2)
                    : prevProgress < (maxProgress + 34) ? prevProgress + 1 : prevProgress) : 100
            ))
            progress < 100 && setLastUpdated(Date.now())
        }, timeIncrement);

        return () => {
            clearInterval(timer);
        }
    }, [buildStepAI])


    useEffect(() => {

        // if (progress > 100) {
        //     setProgress(0)
        //     setBuildError(true)
        // }

        for (const text of Object.keys(loaderBuildingPlanTextByProgress)) {
            const progressKey = Number(text) as keyof typeof loaderBuildingPlanTextByProgress
            if (progress <= Number(text)) {
                setBuildingPlanText(loaderBuildingPlanTextByProgress[progressKey])
                return;
            }
        }
    }, [progress])


    useEffect(() => {
        const interval = setInterval(() => {
            if (!errorAlready && !buildError && Date.now() - lastUpdated >= 80000) { // 1min20sec
                //   console.log('>>> taking too long . . .')
                setBuildError(true)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [progress])


    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            event.preventDefault()
            const message = "Are you sure you want to leave? Your changes may not be saved.";
            event.returnValue = message
            console.log(message)
            return message
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [])


    const renderCalendar = () => {
        // if user clicked on 'Accept', change the latest plan saved status to 1
        return <>
            <Calendar
                showViewCalendar={showViewCalendar}
                handleCloseViewCalendar={setShowViewCalendar}
                handleClose={setShowViewCalendar}
                userInfo={planInfo}
                getDailyActivityDisplay={getDailyActivityDisplay}
                selectedRace={[]}
                currentWeek={currentWeek}
                allUserNotes={[]}
                selectedWeek={selectedWeek}
                setSelectedWeek={setSelectedWeek}
                selectedDay={0}
                setSelectedDay={setSelectedDay}
                getColorCode={getColorCode}
                loading={calendarLoading}
                setCalendarLoading={setCalendarLoading}
                weekDays={weekDaysSAMPLE}
                raceResults={undefined}
                handleCheckIfRecoveryPlan={handleCheckIfRecoveryPlan}
                presetCalendarData={calendarData}
                preview={true}
                handleAcceptPlan={handleAcceptPlan}
            />
        </>
    }

    const renderLoader = () => {

        return <>
            <SH1 size={3} sx={{ textAlign: 'center', mb: 2 }}>
                {`Sit tight, we’re hard at work personalizing your plan for the ${mergedStageDetails?.raceName || 'Marathon'}!`}
            </SH1>

            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>

                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: '#B8DAF0',
                        position: 'absolute',
                    }}
                    size={170}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: '#ADD6F1',
                        animationDuration: '850ms',
                        position: 'absolute',

                    }}
                    size={170}
                    thickness={4}
                />
                <CircularProgress variant="determinate" value={progress} size={170} thickness={4} sx={{ color: '#72A8E9' }} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        // color="text.secondary"
                        sx={{ fontSize: '1.8rem', fontFamily: 'Poppins-Bold', color: '#72A8E9' }}
                    >{`${Math.round(progress)}%`}</Typography>
                </Box>
            </Box>

            {/* extra details text per stage */}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: '1.5rem' }}>
                <p style={{ textAlign: 'center' }}>
                    {buildingPlanText ? buildingPlanText :
                        loaderBuildingPlanTextsByAIsteps[(buildStepAI || 1) - 1]}
                </p>
            </Box>
        </>
    }

    const renderUpdateConfirmation = () => {
        return <>
            <SH2 size={3} sx={{ pb: '10px', width: '100%' }}>Update plan?</SH2>
            <P1 sx={{ width: '100%' }}>
                It seems you have changed some details of your preferences. Would you like us to rebuild your suggested plan?
            </P1>

            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <RSButton1
                    secondary
                    sx={{ width: '40%' }}
                    onClick={() => {
                        constructCalendarPlanInfo(latestAIplan[0])
                        setCalendarData(morphResultsForCalendarData(latestAIplan))
                        setUpdatedBaseDetails(false)
                        setUpdatedAddonDetails(false)
                        setShowLoader(false)
                        setFetchingAIresults(false)
                        setUpdateCalendar(false)
                        setShowUpdateConfirmation(false)
                        setIsPLanReady(true)
                    }}
                >No</RSButton1>
                <RSButton1
                    sx={{ width: '40%' }}
                    onClick={() => {
                        if (results.length > 0) {
                            // handleTableData()
                            setLatestAIplan(results)
                            constructCalendarPlanInfo(results[0])
                        }
                        setUpdateCalendar(true)
                        setIsPLanReady(false)
                        setShowUpdateConfirmation(false)
                        if (tableDataCompleteOverview.revision === planRevision) finishBuildLoading()
                    }}
                >Yes</RSButton1>
            </Box>

        </>
    }

    const renderDisconnectionScreen = () => {
        return <>
            <SH2 size={3} sx={{ pb: '10px', width: '100%' }}>Build Plan</SH2>
            <P1 sx={{ width: '100%' }}>
                {!errorAlready ? 'It seems you have disconnected while building your plan. Would you like to try again?' : `We weren't able to retrieve built plan. Kindly retry.`}
            </P1>

            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <RSButton1
                    secondary
                    sx={{ width: '40%' }}
                    onClick={handleBack}
                >Back</RSButton1>
                <RSButton1
                    sx={{ width: '40%' }}
                    onClick={() => {
                        setLastUpdated(Date.now())
                        !errorAlready && handlePlanBase()
                        errorAlready && handleBack()

                        setErrorAlready(true)
                    }}
                >{!errorAlready ? `Resume` : `Retry`}</RSButton1>
            </Box>
        </>
    }

    return <Box sx={{ height: '100%', px: 3 }}>

        {(calendarData.length > 0 && !showUpdateConfirmation && !showLoader) ?
            <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignContent: 'center', alignItems: "center", width: 'full', height: 'full', overflow: 'hidden' }}>

                <Box display="flex" justifyContent="center" alignItems="start" sx={{ flexDirection: 'column', alignText: 'start' }}>
                    <SH2 size={3} sx={{ pb: '10px', width: '100%' }}>
                        Your plan is ready!
                    </SH2>
                    <P1 sx={{ width: '100%' }}>
                        Your plan is ready for review!  Once you accept the plan, you will be able to view the full details of each run and make further adjustments
                    </P1>

                    {calendarData.length > 0 ? <RSButton1
                        sx={{ mt: 3, width: 'auto'}}
                        onClick={() => setShowViewCalendar(!showViewCalendar)}
                    >
                        Preview Plan
                    </RSButton1> : <>
                        {/* <Loader active={fetchingAIresults} /> */}
                        {/* <h5 style={{ marginTop: '14px', fontStyle: 'italic' }}>Loading Results...</h5> */}
                    </>}
                </Box>

                {showViewCalendar && renderCalendar()}

                {/* <img src='/img-new/misc/gonser-message.png' style={{ marginTop: '2rem', width: '16rem' }}></img> */}

            </Box>

            : !error ? ((showUpdateConfirmation && !showLoader) ? renderUpdateConfirmation() : !buildError ? renderLoader() : renderDisconnectionScreen()) : renderDisconnectionScreen()}</Box>
}

export default PlanOverview;




const planInfoSAMPLE = {
    "uid": 7636,
    "internal_id": 16123,
    "start": "2024/06/10",
    "race_date": "2024/12/26",
    "signup_date": "2024/11/23",
    "location": null,
    "late_start": 1,
    "monday_start": 0,
    "aggressiveness": 0,
    "training_level": 3,
    "status": 1,
    "type": 0,
    "weeks": 13,
    "distance": 30,
    "km": "N",
    "vdot": 21.5,
    "targetm": 375.2879943847656,
    "is_active_plan": false
}

const weekDaysSAMPLE = [
    "2023-08-20",
    "2023-08-27",
    "2023-09-03",
    "2023-09-10",
    "2023-09-17",
    "2023-09-24",
    "2023-10-01",
    "2023-10-08",
    "2023-10-15",
    "2023-10-22",
    "2023-10-29",
    "2023-11-05",
    "2023-11-12",
    "2023-11-19",
    "2023-11-26",
    "2023-12-03",
    "2023-12-10",
    "2023-12-17",
    "2023-12-24",
    "2023-12-31",
    "2024-01-07",
    "2024-01-14",
    "2024-04-07",
    "2024-04-14",
    "2024-04-21",
    "2024-04-28",
    "2024-05-05",
    "2024-05-12",
    "2024-05-19",
    "2024-05-26",
    "2024-06-02",
    "2024-06-09",
    "2024-06-16",
    "2024-06-23",
    "2024-06-30",
    "2024-07-07",
    "2024-07-14",
    "2024-07-21",
    "2024-07-28",
    "2024-08-04",
    "2024-08-11",
    "2024-08-18",
    "2024-08-25",
    "2024-09-01",
    "2024-09-08",
    "2024-09-15",
    "2024-09-22",
    "2024-09-29",
    "2024-10-06",
    "2024-10-13"
]


const calendarDataSAMPLE = [
    {
        "monthData": {
            "week": 1,
            "day": 1,
            "dateVal": "2024-06-17",
            "monthDigits": []
        },
        "activitiesValue": {
            "marathon": {
                "id": 1241,
                "marathon": 0,
                "type": 14,
                "training_goal": 30,
                "week": 1,
                "day": 1,
                "distance": 4,
                "race_day": null
            },
            "marathonPaces": [
                {
                    "mar_id": 1241,
                    "paceId": 2468,
                    "pace": "",
                    "notes": "4 miles",
                    "orderid": 0
                }
            ],
            "workouts": [
                {
                    "mid": 1241,
                    "workoutId": 2114,
                    "title": "Cross Train",
                    "description": "",
                    "link": "",
                    "orderid": 0
                }
            ]
        }
    }
]
