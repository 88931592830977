/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState, useEffect, useRef } from 'react';
import moment, { unitOfTime } from 'moment';
import { AnimatePresence, motion } from "framer-motion";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import ChooseAPlanType from './ChooseAPlanType';
import EstablishBasePlan from './EstablishBasePlan';
import WeeklyRunningSchedule from './WeeklyRunningSchedule';
import StrengthYoga from './StrengthYoga';
import StretchingPlan from './StretchingPlan';
import PlanPacing from './PlanPacing';

import { RSButton1 } from '../../components/buttons'
import { H1, H2 } from '../../components/text/RSTypography'
import Loader from '../../components/animation/Loader'
import Stage1Loader from '../../components/animation/stage1_loader.json'
import Stage1SmLoader from '../../components/animation/stage1_sm_loader.json'
import { AccountProfile } from '../../api/v2/account/Account.types'
import useRSPlanAI from '../../hooks/useRSPlanAI'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useRSTabs from '../../hooks/useRSTabs'
import { ConsoleView } from 'react-device-detect';
import PlanOverview from './PlanOverview';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useStore from '../../store/useStore';
import { IAiTrainingPlanStore } from '../../store/createAITrainingPlanStore';
import RSDateInput from '../../components/input/RSDateInput';
import RSDateRange from '../../components/input/RSDateRange';
import useGPTTrainingPlan from './useGPTTrainingPlan';
import useRSPlanAIv2 from '../../hooks/useRSPlanAIv2';

export type RaceBuildProps = {
	accountProfile: AccountProfile,
	raceDetails: any,
	onClose?: any,
	popUpHeight: string,
	maintenanceEnd?: string
}

const MotionGrid = motion(Grid)

const variants = {
	enter: { opacity: 0 },
	center: { opacity: 1 },
	exit: { opacity: 0 }
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => {
	return ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 16
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: '#9fcd2b',
				opacity: 0.6,
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: '#9fcd2b'
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			width: '100%',
			margin: 'auto',
			border: 0,
			backgroundColor:
				theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderRadius: 1,
		},
	})
}
);

const RaceBuild: FC<RaceBuildProps> = ({ accountProfile, raceDetails, onClose, popUpHeight, maintenanceEnd }) => {
	const { isLoading: aiLoading, fetchingAIresults, setFetchingAIresults, stage3aAICall, buildTablePlanOverview, buildTablePlanOverviewV2, addCrossFitTraining, buildStepAI, buildError, setBuildError, setDisplayLogCounter } = useRSPlanAIv2({})
	const { clearDataGenerated } = useStore((state: IAiTrainingPlanStore) => state)
	const { gender } = accountProfile
	const { raceTitle, name } = raceDetails || {}

	const isMobile = useIsMobileScreen()
	const allStageDetailsRef = useRef<any>()
	const isMaintenancePlan = raceDetails?.selectedRaceDistance?.toLowerCase() === "maintenance"

	const [allStageDetails, setAllStageDetails] = useState<any>({})
	const [enableNext, setEnableNext] = useState(false)
	const [enableBackNext, setEnableBackNext] = useState(true)
	const [lastStep, setLastStep] = useState<any>({})
	const [tableDataBaseOverview, setTableDataBaseOverview] = useState<any>([])
	const [tableDataCompleteOverview, setTableDataCompleteOverview] = useState<any>([])
	const [subStep, setSubStep] = useState(0)
	const [updatedBaseDetails, setUpdatedBaseDetails] = useState(false)
	const [updatedAddonDetails, setUpdatedAddonDetails] = useState(true)
	const [latestAIplan, setLatestAIplan] = useState<any>([])
	const [isLoading, setIsLoading] = useState<any>(false)
	const [isPageLoading, setIsPageLoading] = useState<any>(false)
	const [planReady, setIsPLanReady] = useState<any>(false)
	const [addonsPicked, setAddonsPicked] = useState<any>(false)
	const [preventBack, setPreventBack] = useState<any>(false)
	const [planRevision, setPlanRevision] = useState<any>(0)
	const [hasHandledAddons, setHasHandledAddons] = useState<any>(false)
	const [manualPlanStartDate, setManualPlanStartDate] = useState<any>(moment().format('MMM D, YYYY'))
	const [suggestedStartDate, setSuggestedStartDate] = useState<any>(moment().format('MMM D, YYYY'))
	const [displayCustomDatePicker, setDisplayCustomDatePicker] = useState<any>(false)
	const [raceDate, setRaceDate] = useState<any>(moment().format('MMM D, YYYY'))
	const [maintenanceDateEnd, setMaintenanceDateEnd] = useState<any>(moment().format('MMM D, YYYY'))

	const [stretching, setStretching] = useState<Array<string>>()
	const activities = ['Low back', 'Hip Flexors', 'Quads', 'Hamstrings', 'Calves']
	const minimumWeeksPlan = 4
	const defaultMDays = 55
	const defaultEndMaxDays = 139

	const handleBack = () => {
		const maintenanceType = allStageDetails["0"]?.level == 1 ? 'maintain' : 'build'
		if (buildError) {
			setBuildError(false)
			handlePlanBase()
		}
		const hasSubStep = stepData?.[tab]?.hasSubStep
		if (hasSubStep && subStep) {
			setEnableBackNext(false)
			setSubStep(subStep - 1)
			setTimeout(() => {
				setEnableBackNext(true)
			}, 500)
		} else {
			isMaintenancePlan && maintenanceType === "maintain" && tab == 3 ? handleChangeStep(tab - 2) : isHome ? onClose?.() : handleChangeStep(tab - 1)
		}
	}

	const handleNext = async () => {
		const hasSubStep = stepData?.[tab]?.hasSubStep
		if (hasSubStep && subStep < hasSubStep) {
			setEnableBackNext(false)
			setSubStep(subStep + 1)
			setTimeout(() => {
				setEnableBackNext(true)
			}, 500)
		} else {
			const setStageData = stepData?.[tab]?.setData
			if (setStageData) {
				const allDetailsStr = JSON.stringify(allStageDetails)
				if (!(allStageDetailsRef.current && allDetailsStr?.includes(allStageDetailsRef.current))) {
					allStageDetailsRef.current = allDetailsStr.slice(0, allDetailsStr.length - 1)
					handleChangeStep(tab + 1)
					// setPlanRevisions((prev: { edits: number }) => ({ ...prev, edits: prev.edits + 1 }))
					let newUpdate = planRevision

					if (updatedBaseDetails) {
						// console.log('there is an update in base details')
						++newUpdate
						setPlanRevision(newUpdate)
						// console.log('>>> allStageDetails: ', allStageDetails)

						setTableDataBaseOverview({ results: await setStageData(allStageDetails, newUpdate), revision: newUpdate })
					}
					// setTableDataBaseOverview(await setStageData(allStageDetails, updatedBaseDetails))

				} else
					handleChangeStep(tab + 1)
			} else
				handleChangeStep(tab + 1)
		}
	}

	const handlePlanBase = async () => {
		setBuildError(false)
		let newUpdate = planRevision
		if (updatedBaseDetails) {
			++newUpdate
			setPlanRevision(newUpdate)
			setTableDataBaseOverview({ results: await buildTablePlanOverviewV2(allStageDetails, newUpdate), revision: newUpdate })
		}
	}

	const handleLogging = () => displayCustomDatePicker && setDisplayLogCounter(prev => prev + 1)

	const allProps = {
		accountProfile, allStageDetails, addDetails, handleIsStepComplete, tableDataBaseOverview, tableDataCompleteOverview, raceDetails, planRevision, buildError, setBuildError, handleBack, handlePlanBase,
		fetchingAIresults, setFetchingAIresults, buildStepAI, updatedBaseDetails, setUpdatedBaseDetails, updatedAddonDetails, setUpdatedAddonDetails, latestAIplan, setLatestAIplan, maintenanceDateEnd, manualPlanStartDate
	}

	const stepData = [{
		className: 'fa-solid fa-signal-bars-good',
		body: <ChooseAPlanType {...allProps} />,
		stepTitle: 'Choose a Plan Type',
		bgImg: '/img-new/race-events/Runner-Group-Mobile.jpg',
		bgImgDesktop: '/img-new/race-events/Runner-Group-Desktop.jpg'
	}, {
		className: 'fa-duotone fa-calendar-lines-pen',
		body: <EstablishBasePlan {...allProps} subStep={subStep} startDate={manualPlanStartDate} endDate={maintenanceDateEnd} />,
		stepTitle: 'Base Plan',
		bgImg: '/img-new/race-events/Runner-Group-Mobile.jpg',
		bgImgDesktop: '/img-new/race-events/Runner-Group-Desktop.jpg',
		hasSubStep: 2
	}, {
		className: 'fa-duotone fa-person-running-fast',
		body: <WeeklyRunningSchedule {...allProps} setMainLoading={setIsPageLoading} handleNext={handleNext} />,
		stageTitle: `Building Out Weekly Running Schedule`,
		stageDesc: 'In mollis nunc sed id semper risus in hendrerit gravida rutrum quisque.',
		stepTitle: 'Weekly Running Schedule',
		loader: Stage1Loader,
		bgImg: '/img-new/race-events/Runner-ShoeTie-Mobile.jpg',
		bgImgDesktop: '/img-new/race-events/Runner-ShoeTie-Desktop.jpg',
		setData: buildTablePlanOverviewV2
	}, {
		className: 'fa-duotone fa-dumbbell',
		body: <StrengthYoga {...allProps} />,
		stepTitle: 'Cross Training Schedule',
		hasSkip: 'crossTraining',
		bgImg: '/img-new/race-events/Runner-StrengthYoga-Mobile.jpg',
		bgImgDesktop: '/img-new/race-events/Runner-StrengthYoga-Desktop.jpg'
	}, {
		className: 'fa-duotone fa-person-falling',
		body: <StretchingPlan {...allProps} setStretchingMain={setStretching} />,
		stepTitle: 'Stretching Plan',
		iconSx: { transform: 'rotate(45deg)' },
		addData: addCrossFitTraining,
		bgImgDesktop: '/img-new/race-events/Runner-Areas-Desktop.jpg',
		imgDesktopBody: renderCircles(),
		hasSkip: 'stretchingPlan'
	}, {
		className: 'fa-duotone fa-timer',
		stepTitle: 'Plan Pacing',
		body: <PlanPacing {...allProps} setMainLoading={setIsPageLoading} />,
		bgImg: '/img-new/race-events/Runner-Goal-Mobile.jpg',
		bgImgDesktop: '/img-new/race-events/Runner-Goal-Desktop.jpg',
		loader: Stage1Loader,
	}, {
		className: 'fa-duotone fa-flag-checkered',
		stepTitle: 'Plan Overview',
		body: <PlanOverview {...allProps} planReady={planReady} setIsPLanReady={setIsPLanReady} />,
		bgImg: planReady ? (gender === 'Male' ? '/img-new/race-events/Runner-Ready-M-Mobile.jpg' : '/img-new/race-events/Runner-Ready-F-Mobile.jpg') : '/img-new/race-events/Runner-Goal-Mobile.jpg',
		bgImgDesktop: planReady ? (gender === 'Male' ? '/img-new/race-events/Runner-Ready-M-Desktop.jpg' : '/img-new/race-events/Runner-Ready-F-Desktop.jpg') : '/img-new/race-events/Runner-Goal-Desktop.jpg',
		loader: Stage1Loader,
	}]

	const { calculatePlanWeeks, calculateStartDate } = useGPTTrainingPlan()

	const renderInitBody = () => {

		return <Grid container xs={12} sx={{ px: 3, pt: 2 }} justifyContent='flex-start' alignItems='center' >
			<H1 size={5} sx={{ mb: '10px' }}>
				{`Let's build a ${raceTitle?.toLowerCase()} plan!`}
			</H1>
			<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', textAlign: 'left', lineHeight: '1.2em' }}>
				{`We'll collect`} <span style={{ fontSize: '16px', fontFamily: 'Poppins-Medium', textAlign: 'left', lineHeight: '1.2em' }} >information</span> {`to personalize a running plan that fits your goals, schedule, and preferences.`}
			</Box>

			{renderRaceDurationChoice()}

		</Grid>
	}

	const renderRaceDurationChoice = () => {
		const dateRangeDaysDiff = moment(raceDate).diff(moment(manualPlanStartDate).subtract(1, 'd'), 'days')
		const weeksCount = Math.floor(dateRangeDaysDiff / 7)
		const daysCount = dateRangeDaysDiff % 7 || 0
		return <Grid container xs={12}>
			<Grid container xs={12} sx={{ pt: 2 }}>
				<RSDateRange isWeekSelector endLabel={`${isMaintenancePlan ? 'End' : 'Race'} Date`} startDate={manualPlanStartDate} setStartDate={setManualPlanStartDate} startMinDate={suggestedStartDate} startMaxDate={moment(raceDate).subtract(isMaintenancePlan ? 1 : minimumWeeksPlan, 'week').format('MMMM D, YYYY')} startDay endDate={raceDate} setEndDate={isMaintenancePlan && !maintenanceEnd ? setRaceDate : undefined} endMinDate={moment(manualPlanStartDate).add(1, 'week')} endMaxDate={moment(manualPlanStartDate).add(defaultEndMaxDays, 'days')} startCalendarLabel='Choose Week to Start Training' endCalendarLabel='Choose Week to End Training' endDay />
			</Grid>
			<Grid container xs={12} sx={{ pt: 2 }}>
				<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', lineHeight: '1.3em' }}>Your plan is</Box>
				<Box sx={{ fontFamily: 'Poppins-Bold', fontSize: '16px', lineHeight: '1.3em', ml: '5px' }}>{`${weeksCount} week${weeksCount > 1 ? 's' : ''}`}</Box>
				<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', lineHeight: '1.3em', ml: '5px' }}>long</Box>
			</Grid>

		</Grid>
	}

	const thisSunday = () => {
		const today = moment()
		const day = today.day()
		return today.add(7 - day, 'day').format('MMM D, YYYY')
	}

	const { tabsUI, handleChangeActive, tab } = useRSTabs({ data: stepData, initialBody: renderInitBody(), initialTab: -1, bodySX: { pb: '100px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) calc(100% - 80px), transparent calc(100% - 40px))' }, animateDesktop: true })



	const [hideHeader, setHideHeader] = useState(false)
	const prevStepRef = useRef<any>()
	const isHome = tab === -1
	const isPrev = prevStepRef.current > tab
	const initTab = (Object.keys(lastStep).sort().find((key: string) => !lastStep?.[key]) || Object.keys(lastStep)?.sort()?.pop())?.charAt(0)
	const stepperTab = isHome || !initTab ? 0 : Number(initTab)
	const [flip, setFlip] = useState(0)
	const timer = useRef<any>();
	const time = useRef<any>({ current: 1 });
	const disableBack = (!enableBackNext && !isHome)
	const disableNext = ((!enableBackNext || stepData.length <= tab || !enableNext) && !isHome) || !(tab < stepData.length - 1) || isPageLoading
	const skipName = stepData?.[tab]?.hasSkip || ''
	const nextSkip = skipName && !allStageDetails?.[tab]?.[skipName]?.length ? 'Skip' : 'Next'
	const wWidth = isMobile ? window.innerWidth : 525
	const desktopAdd = isMobile ? 0 : 375
	const stepsWidth = 95

	// console.log('>>> allStageDetails: ', accountProfile)
	function renderCircles() {
		const parts = [{
			top: 51.5,
			left: 28.5,
		}, {
			top: 57.7,
			left: 26.3,
		}, {
			top: 64,
			left: 24.5,
		}, {
			top: 69,
			left: 26.5,
		}, {
			top: 81,
			left: 23,
		}]
		return parts?.map((p: any, pi: number) => {
			const isActive = stretching?.includes(activities[pi])
			return <Box key={pi + 'parts'} sx={{ height: 18, width: 18, borderRadius: '50%', backgroundColor: `${isActive ? '#FCC26A' : ' rgba(0,0,0,0.3)'}`, position: 'absolute', top: `${p.top}%`, left: `${p.left}%`, border: isActive ? '1px solid #FF9A00' : 'none' }} />
		})
	}

	function addDetails(stageNumber: string, details: any) {
		setAllStageDetails({
			...allStageDetails,
			[stageNumber]: details
		})


	}

	function handleIsStepComplete(stepComplete: boolean, stageNumber: string) {
		setEnableNext(stepComplete)
		const lastStepObj = {
			...lastStep,
			[stageNumber]: stepComplete
		}
		if (!stepComplete) {
			let isAfterStage = false
			Object.keys(lastStepObj)?.sort()?.forEach((key: string) => {
				if (isAfterStage)
					delete lastStepObj[key]
				if (key === stageNumber)
					isAfterStage = true
			})
		}
		setLastStep(lastStepObj)
	}

	const handlePlanAddons = async () => {

		// console.log('stepData', stepData)
		// console.log('plan revision', planRevision)

		const addStageData = stepData?.[4].addData
		if (addStageData && tableDataBaseOverview?.results?.length > 0) {

			let newUpdate = planRevision
			++newUpdate
			setPlanRevision(newUpdate)
			// console.log('>> adding crossfit training . . .')
			setTableDataCompleteOverview({ results: await addStageData(allStageDetails), revision: newUpdate })
			setAddonsPicked(false)
		}
	}

	const handleChangeStep = (newStep: number) => {
		setEnableBackNext(false)
		prevStepRef.current = tab
		handleChangeActive(newStep)
		setTimeout(() => {
			setEnableBackNext(true)
		}, 500)
	}

	// update plan if only addons was changed
	const updatePlanAddons = () => {
		if (tab == 5 && updatedAddonDetails && !updatedBaseDetails && tableDataBaseOverview.results && tableDataBaseOverview.results.length > 0) {
			// console.log('[updated plan addons only]')
			handlePlanAddons()
		}
	}


	const onScroll = () => {
		if (!isMobile) {
			const position = document.getElementsByClassName('overflow-y-auto')?.[0]?.scrollTop;
			const hide = 0 < position
			hideHeader !== hide && setHideHeader(hide)
		}
	}


	useEffect(() => {
		setUpdatedBaseDetails(true)
	}, [manualPlanStartDate])

	useEffect(() => {
		if (isMaintenancePlan && raceDate)
			setMaintenanceDateEnd(raceDate)
	}, [isMaintenancePlan, raceDate])


	useEffect(() => {
		isHome && setHideHeader(false)
	}, [isHome])

	// to merge updatedAddonDetails and updatedBaseDetails instead
	useEffect(() => {

		// console.log('>>> updatedAddonDetails: ', updatedAddonDetails)
		// console.log('tableDataBaseOverview', tableDataBaseOverview)
		// console.log('plan revision', planRevision)
		// setPlanRevisions((prev: { results: number }) => ({ ...prev, results: prev.results + 1 }))
		if (tableDataBaseOverview.results && tableDataBaseOverview.results.length > 0 && addonsPicked && tableDataBaseOverview.revision === planRevision) {
			// console.log('>> handling plan addons . . .')
			setHasHandledAddons(true)
			setUpdatedAddonDetails(false)
			handlePlanAddons()
		}
	}, [tableDataBaseOverview])

	useEffect(() => {
		tab == 5 && setAddonsPicked(true)
		// if (!hasHandledAddons )
		if (tab == 5 && tableDataBaseOverview.results && tableDataBaseOverview.results.length > 0 && tableDataBaseOverview.revision === planRevision && updatedAddonDetails) {
			handlePlanAddons()
		}

		if (tab == 6 && !planReady) setPreventBack(true)
		else setPreventBack(false)
	}, [tab])

	useEffect(() => {
		planReady && setPreventBack(false)
		if (tab == 6 && !planReady) setPreventBack(true)
	}, [planReady])

	useEffect(() => {
		setIsLoading(aiLoading)
	}, [aiLoading])

	useEffect(() => {
		if (!isHome) {
			if (!isLoading) {
				if (stepData?.[tab]?.setData)
					handleChangeStep(tab + 1)
			}
		}
	}, [isLoading])

	useEffect(() => {
		const tm = isHome ? 0 : 1
		const timeInterval = timer['current']
		timeInterval && clearInterval(timeInterval)
		timer['current'] = undefined
		setFlip(tm)
		time['current'] = tm + 1

		// update plan if only addons was changed
		updatePlanAddons()

		if (!isHome) {
			timer['current'] = setInterval(() => {
				const t = time['current']
				time['current'] = t + 1
				setFlip(t)
			}, 3000)
		}
		return () => {
			timeInterval && clearInterval(timeInterval)
		}
	}, [isHome, isMobile, tab])

	useEffect(() => {

		let initialStartDate = (isMaintenancePlan && raceDetails?.date ? moment(raceDetails.date) : moment()).format('MMM D, YYYY')
		let raceDate = (raceDetails?.datePerRaceType && raceDetails?.datePerRaceType.length > 0) ?
			raceDetails?.datePerRaceType?.find((rd: any) => raceDetails.selectedRaceDistance?.toUpperCase()?.includes(rd?.type?.toUpperCase()))?.date : raceDetails?.date || 'invalid date'

		clearDataGenerated()

		if (isMaintenancePlan) {
			const wdIndex = moment(initialStartDate).isoWeekday()
			initialStartDate = wdIndex === 7 ? initialStartDate : moment(initialStartDate).add(7 - wdIndex, 'days').format('MMM D, YYYY')
		} else
			initialStartDate = calculateStartDate(raceDate)

		if (isMaintenancePlan)
			raceDate = moment(initialStartDate).add(defaultMDays, 'days').format('MMM D, YYYY')

		setRaceDate(maintenanceEnd || raceDate)
		setSuggestedStartDate(initialStartDate)
		setManualPlanStartDate(initialStartDate)
	}, [isMaintenancePlan, maintenanceEnd])




	return <Grid className='race-build-cont' container xs={12} sx={{ minHeight: isMobile ? window.innerHeight : popUpHeight, position: 'relative', overflowX: 'hidden' }} justifyContent='center' alignItems='center'>
		{hideHeader && !isMobile && !isHome ? <></> : <Box className={`${!isMobile && isHome ? '' : 'transition-all'} progress-top up`} sx={{ position: 'absolute', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: (isMobile ? (isHome ? `${45 * stepData.length}px` : `${stepsWidth * stepData.length}px`) : wWidth), left: (isMobile ? (isHome ? (window.innerWidth - 315) / 2 : `${((wWidth / 2) - (stepsWidth / 2)) - ((stepsWidth) * tab)}px`) : 'unset'), right: isMobile ? 'unset' : 0 }}>
			{!isHome && !isMobile && <Box sx={{ width: '100%', position: 'absolute', top: 15, textAlign: 'center', fontSize: '20px', fontFamily: 'Poppins-Bold' }}>
				{stepData?.[tab]?.stepTitle}
			</Box>}
			<Stepper className='transition-all' connector={<ColorlibConnector />} alternativeLabel activeStep={stepperTab} sx={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 3 }}>
				{stepData.map((data: any, i: number) => {
					const isDone = i < stepperTab && !isHome
					const isCurrent = i === tab
					const iconSx = data.iconSx || {}
					const circeSize = 36
					const iconFontSize = 20
					const scale = isCurrent || isHome ? 1 : 0.7
					const icon = <Box className='transition-all' sx={{ position: 'relative', scale: `${scale}`, zIndex: 1 }}>
						{(isCurrent || (isHome && !isMobile)) && <Box sx={{ zIndex: 1, borderRadius: '50%', position: 'absolute', width: circeSize, height: circeSize, top: 0, right: 0, scale: isHome ? '1.35' : '1.6', backgroundColor: '#F8F8FA' }} />}
						{isCurrent && <Box sx={{ zIndex: 2, borderRadius: '50%', border: '1px solid #9fcd2b', position: 'absolute', width: circeSize, height: circeSize, top: 0, right: 0, scale: '1.35', backgroundColor: isMobile ? '#F8F8FA' : 'transparent' }} />}
						<Box className={`transition-all color-change ${isHome ? `jump-icon` : ''}`}
							sx={{ zIndex: 3, animationDelay: `${(isPrev ? stepData.length - i : i) * (1 / stepData.length)}s !important`, position: 'relative', fontSize: `${iconFontSize}px`, borderRadius: '50%', width: circeSize, height: circeSize, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							<Box className='transition-all' sx={{ transform: `rotateY(${flip % 2 && isCurrent ? 180 : 0}deg)`, position: 'relative', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', transformStyle: 'preserve-3d', borderRadius: '50%', zIndex: 3 }}>
								<Box sx={{ fontSize: iconFontSize, display: 'flex', position: 'absolute', backgroundColor: isDone ? '#9fcd2b !important' : `white${isCurrent || isHome ? ' !important' : '#cbcbcb !important'}`, height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', zIndex: 3, backfaceVisibility: 'hidden', border: `1px solid #9fcd2b${isCurrent || isDone || isHome ? ' !important' : ''}`, borderRadius: '50%' }}>
									<i className={isDone ? 'fa-regular fa-check c-w' : `${data.className} duo-tone`} style={{ paddingLeft: i > 1 || isDone ? '1px' : (i ? '3px' : '6px'), paddingBottom: i || isDone ? 0 : '4px', ...!isDone && iconSx }} />
								</Box>
								<Box sx={{ fontSize: '14px', display: 'flex', position: 'absolute', transform: 'rotateY(-180deg)', backgroundColor: 'white', borderRadius: '50%', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', backfaceVisibility: 'hidden' }}>
									<CircularProgress variant='determinate' value={((i + 1) / stepData.length) * 100} sx={{ position: 'absolute' }} thickness={2} />
									{i + 1}/{stepData.length}
								</Box>
							</Box>
							{isMobile && data.stepTitle && isCurrent && <Box className='body-height-animation-1' sx={{ position: 'absolute', width: '275px', top: '-42px', left: '50%', transform: 'translateX(-50%)', textAlign: isMobile ? 'center' : 'left', color: isDone || isCurrent ? '#010101' : 'rgba(0,0,0,0.5)' }}>
								<H1 size={3} sx={{ textAlign: 'center' }}>
									{data.stepTitle}
								</H1>
							</Box>}
						</Box>
					</Box>
					return <Step key={i + 'build-race-icon'} sx={{ pl: '4px !important', pr: '4px !important' }}>
						<StepLabel icon={icon}></StepLabel>
					</Step>
				})}
			</Stepper>
		</Box>}
		{!isLoading && <Box sx={{ minHeight: 'inherit', width: '100%' }}>
			<Box className={`body-height-animation ${!isPrev && tab === 0 ? 'delay-2' : ''}`} sx={{ display: 'flex', width: '100%', overflow: 'hidden', minHeight: 'inherit' }}>
				<MotionGrid
					key={stepData?.[isHome ? 0 : tab]?.bgImgDesktop + 'desktop-bg'}
					variants={isMobile ? undefined : variants}
					initial="enter"
					animate="center"
					exit='exit'
					transition={{ duration: 0.5 }}
					container className={'background-bottom'} xs={isMobile ? 12 : 5}
					sx={{
						borderRadius: '5px 0px 0px 5px', height: isMobile ? '100%' : popUpHeight, position: isMobile ? 'fixed' : 'static', backgroundColor: isMobile ? 'transparent' : '#c6dbd4', zIndex: 1,
						backgroundImage: isMobile ? (isHome || stepData?.[tab]?.bgImg ? `url(${stepData?.[isHome ? 1 : tab]?.bgImg}) !important` : 'none') : (isHome || stepData?.[tab]?.bgImgDesktop ? `url(${stepData?.[isHome ? 0 : tab]?.bgImgDesktop}) !important` : 'none')
					}}>
					{!isMobile && stepData?.[tab]?.imgDesktopBody}
				</MotionGrid>
				<Grid container xs={isMobile ? 12 : 7} onScroll={onScroll} className={`${isMobile ? '' : 'overflow-y-auto'}`} sx={{ boxShadow: isMobile ? 0 : '-2px 0px 6px rgba(70, 70, 70, 0.3)', height: isMobile ? '100%' : popUpHeight, width: '100%', pt: '116px', borderRadius: isMobile ? 0 : '0px 5px 5px 0px', minHeight: 'inherit', overflowX: 'hidden', zIndex: 2 }}>
					{tabsUI}
				</Grid>
			</Box>
		</Box>}
		{!isLoading && <Box sx={{ width: '100%', bottom: 0, position: 'fixed', display: 'flex', justifyContent: 'flex-end', zIndex: 2 }}>
			<Box className='transition-all' sx={{ height: '100%', width: wWidth, position: 'absolute', backdropFilter: `blur(${enableBackNext ? 3 : 0}px)`, mask: 'linear-gradient(transparent 0%, white 20%, white 80%, transparent 100%)' }} />
			{!preventBack && <Grid container className={isHome && !isMobile ? '' : 'transition-all'} xs={isMobile ? 12 : 7} sx={{ visibility: enableBackNext ? 'visible' : 'hidden', opacity: enableBackNext ? 1 : 0, pl: 3, py: 3, pr: isMobile ? 3 : 5 }} justifyContent='space-between' alignItems='center'>
				<RSButton1 sx={{ width: '130px' }} disabled={disableBack} secondary onClick={handleBack}>Back</RSButton1>
				<RSButton1 sx={{ width: '130px' }} disabled={disableNext} onClick={handleNext}>{tab === stepData.length - 1 ? 'Accept' : nextSkip}</RSButton1>
			</Grid>}
		</Box>}
		{/* <Loader active={ (fetchingAIresults && tab >= 7) } bg='white' /> */}
		{/* {(fetchingAIresults && tab >= 7) && renderLoader()} */}

	</Grid>
}

export default RaceBuild;
