/* eslint-disable @typescript-eslint/no-unused-vars */
import { CardElement } from '@recurly/react-recurly'
import { FC } from 'react'
import { Form, Row } from 'react-bootstrap'
import Grid from '@mui/material/Grid'
import { AuthPageProps } from '../../../modules/types'

interface PaymentDetailsProps {
    firstName: string,
    lastName: string,
    postalCode: string,
    setFirstName: (e: any) => any,
    setLastName: (e: any) => any,
    setPostalCode: (e: any) => any,
    onChangeCard: (e: any) => any
}
const PaymentDetails: FC<Partial<AuthPageProps> & PaymentDetailsProps> = ({ userInfo, firstName, lastName, postalCode, setFirstName, setLastName, setPostalCode, onChangeCard }) => {




    return (
        <div className='payment-details-form'>
            {/* <h4>Subscribe with Credit Card</h4> */}
            <Form id="billing-form">
            		<Grid container xs={12}>
									<Grid container xs={6} sx={{pr: '2px'}}>
											<Form.Control
													size="lg"
													value={firstName}
													required={true}
													placeholder={"First Name"}
													type={'input'}
													isInvalid={false}
													data-recurly={'first_name' || undefined}
													disabled={false}
													className='firstName'
													onChange={setFirstName}

											/>
									</Grid>
									<Grid container xs={6} sx={{pl: '2px'}}>
											<Form.Control
													size="lg"
													value={lastName}
													required={true}
													placeholder={"Last Name"}
													type={'input'}
													isInvalid={false}
													data-recurly={'last_name' || undefined}
													disabled={false}
													className='lastName'
													onChange={setLastName}
											/>
									</Grid>
								</Grid>


                <CardElement
                    id="recurly-cc"
                    className="form-control form-control-lg mt-2 py-2"
                    style={{ height: 'auto' } as any}
                    onChange={onChangeCard}
                />

                <Form.Control
                    size="lg"
                    value={postalCode}
                    required={true}
                    placeholder={"Billing Postal Code"}
                    type={'input'}
                    isInvalid={false}
                    data-recurly={'postal_code' || undefined}
                    disabled={false}
                    className='mt-2 postalCode'
                    onChange={setPostalCode}

                />
            </Form>
        </div>
    )
}

export default PaymentDetails
