/* eslint-disable @typescript-eslint/no-empty-function */

import { useState, useEffect, isValidElement } from 'react'
import { motion, AnimatePresence } from "framer-motion";

import Grid from '@mui/material/Grid';
import MidfootData from '../../midfoot-project/midfoot.db.json'
import AudioPlayerComponent from '../../midfoot-project/AudioPlayer'
import midfootActions from '../../midfoot-project/midfootActions'
import allNewExercises from '../../../modules/videos/allNewExercises.json'
import RSVideoCard from '../../../components/cards/RSVideoCard'

const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

const ProgramMidfoot = (props: any) => {

	const { userInfo, phase, initPhase, prevActivePhase, renderPhaseSelect, handlePlayVideo, activeMidfoot, loadMidfootData, setIsLoading, weekVideosWithDuration, setWeekVideosWithDuration, onboarding } = props
	const [weekVideos, setWeekVideos] = useState<any>({})
	const { updateMidfootData, createMidfootData } = midfootActions()


	const getVideoDuration = async (hasDuration?: boolean) => {
		const newWeekVideos: any = {}
		for (const nv of MidfootData) {
			const daily = []
			const times = []
			let nvDuration = undefined
			if (hasDuration) {
				const response = await fetch(
					`https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${nv.training_video}`
				);
				const data = await response.json();
				nvDuration = data?.duration ? data.duration : 0
			}
			const newNV = { ...nv, duration: nvDuration }

			for (const v of nv.exercises) {
				const ane = allNewExercises.find((ae: any) => ae.exercise_id == v.id && ae.video_type === 'intro')
				let duration = undefined
				if (hasDuration) {
					const response = await fetch(
						`https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${ane?.video_id}`
					);
					const data = await response.json();
					duration = data?.duration ? data.duration : 0
				}

				const vDetails = { ...ane, duration }
				v.is_daily ? daily.push(vDetails) : times.push(vDetails)
			}
			newWeekVideos[nv.week] = {
				daily, times, data: newNV
			}
		}
		hasDuration ? setWeekVideosWithDuration(newWeekVideos) : setWeekVideos(newWeekVideos)
		setIsLoading?.(false)
	}

	const handlePlay = async (id?: any, week?: any) => {
		if (activeMidfoot)
			await updateMidfootData(userInfo?.account_id, activeMidfoot.id, Number(week))
		else
			await createMidfootData(userInfo?.account_id)
		await loadMidfootData()
		id && handlePlayVideo(id, 'Week ' + week)
	}

	useEffect(() => {
		if (!Object.keys(weekVideosWithDuration).length) {
			getVideoDuration(true)
			getVideoDuration()
		}
	}, [])

	const renderVideos = (videoList: Array<any>, week: number) => {
		const list = videoList.map((vd: any) => {
			const { video_id: id, headingTitle, exercise_title, duration, program } = vd

			return isValidElement(vd) ? vd : {
				headingTitle: headingTitle || exercise_title,
				duration,
				id,
				src: `https://vumbnail.com/${id}.jpg`,
				onClick: () => handlePlay(id, week),
				program: program ? program : "midfoot"
			}
		})
		return <Grid container sx={{ px: 3 }}>
			<RSVideoCard videoList={list} sx={{ pt: 0 }} onboarding={onboarding} />
		</Grid>
	}

	const renderTraining = () => {
		const videos = weekVideosWithDuration?.[phase + 1] ? weekVideosWithDuration[phase + 1] : weekVideos?.[phase + 1]
		return videos ? <>
			{renderPhaseSelect?.()}
			<AnimatePresence mode='wait' initial={false}>
				<motion.div
					key={phase}
					custom={initPhase - prevActivePhase?.current}
					variants={variants}
					initial="enter"
					animate="center"
					exit='exit'
					transition={{ duration: 0.2 }}
					style={{ paddingBottom: '20px' }}
					className='midfoot-program-container'
				>
					{renderVideos([{
						headingTitle: `Week ${videos?.data?.week}`,
						video_id: videos?.data?.training_video,
						duration: videos?.data?.duration,
						program: "midfoot"
					}, <Grid container key={`${videos?.data?.week}-audio`} sx={{ pointerEvents: !onboarding ? 'auto' : 'none' }}>
						<AudioPlayerComponent
							file={videos?.data?.training_audio}
							title={`Week ${videos?.data?.week} Guided Run`}
							handlePlay={() => handlePlay(undefined, videos?.data?.week)}
						/>
					</Grid>],
						videos?.data?.week)
					}

					{videos?.daily?.length && <><Grid container sx={{ px: 3, pt: 2, fontFamily: 'Poppins-Medium', fontSize: '16px' }}>
						Daily Exercises
					</Grid>
						{renderVideos(videos.daily, videos?.data?.week)}
					</>}

					{videos?.times?.length && <><Grid container sx={{ px: 3, pt: 3, fontFamily: 'Poppins-Medium', fontSize: '16px' }}>
						Do 2-3x/week
					</Grid>
						{renderVideos(videos.times, videos?.data?.week)}
					</>}
				</motion.div>
			</AnimatePresence>
		</>
			:
			<></>
	}

	return renderTraining()

}

export default ProgramMidfoot;
